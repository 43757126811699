import { Button, Flex, Select, useDisclosure } from "@chakra-ui/react";
import { BiPlus } from "react-icons/bi";
import { useSchoolsContext } from "../../../../Contexts/schoolContext";
import SessionModal from "./SessionModal";
import {
  ApiDataResponse,
  ApiResponse,
  Session,
} from "../../../../services/Dtos";
import { ChangeEvent, useEffect, useState } from "react";
import Loader from "../../../ui/Loader";
import ErrorAlert from "../../../ui/ErrorAlert";
import { formatDate, getNextOrder } from "../../../../services/Utils";
import HeaderDivider from "../../../ui/HeaderDivider";
import useToaster from "../../../../hooks/useToaster";
import SessionCard from "./SessionCard";
import useAxios from "../../../../hooks/useAxios";

function SessionSettings() {
  const axiosClient = useAxios();
  const { school, setSchool, sessions, setSessions, isLoading, error } =
    useSchoolsContext();
  const [inSessionLoading, setInSessionLoading] = useState(false);
  const [sessionToEdit, setSessionToEdit] = useState<Session | null>(null);
  const { onOpen, isOpen, onClose } = useDisclosure();
  const [currentSession, setcurrentSession] = useState<string | undefined>(
    undefined
  );
  const { showSuccessToast, showErrorToast } = useToaster();

  const [errors, setErrors] = useState<string[]>([]);

  const [nextOrder, setNextOrder] = useState(0);

  useEffect(() => {
    setcurrentSession(school?.currentSessionId);
  }, [sessions]);

  const handleRefresh = () => {
    console.log("Handle Click Function not implemented.");
  };

  const handleAddSession = () => {
    setSessionToEdit(null);
    setNextOrder(getNextOrder(sessions));
    onOpen();
    console.log(nextOrder);
  };

  const handleEditStudent = (session: Session) => {
    console.log(session);
    setErrors([]);
    const modifiedSession = {
      ...session,
      sessionStartDate: formatDate(session.sessionStartDate),
      sessionEndDate: formatDate(session.sessionEndDate),
    };
    setSessionToEdit(modifiedSession);
    onOpen();
  };

  const addSession = async (session: Session): Promise<ApiResponse | null> => {
    setInSessionLoading(true);
    try {
      const response = await axiosClient.post<ApiDataResponse<Session>>(
        "sessions",
        session
      );
      if (response.data.succeeded) {
        setSessions((prev) =>
          [...prev, response.data.data]
            .sort(
              (a, b) =>
                new Date(a.sessionStartDate).getTime() -
                new Date(b.sessionStartDate).getTime()
            )
            .reverse()
        );
      }
      return response.data;
    } catch (error) {
    } finally {
      setInSessionLoading(false);
    }
    return null;
  };

  const editSession = async (
    sessionToEdit: Session,
    sessionId: string
  ): Promise<ApiResponse | null> => {
    setInSessionLoading(true);
    try {
      const response = await axiosClient.put<ApiResponse>(
        `sessions/${sessionId}`,
        sessionToEdit
      );
      if (response.data.succeeded) {
        setSessions((prev) =>
          prev.map((session) =>
            session.id === sessionId ? sessionToEdit : session
          )
        );
      }
      return response.data;
    } catch (error) {
      console.error("Error editing session:", error);
    } finally {
      setInSessionLoading(false);
    }
    return null;
  };

  const deleteSession = async (
    sessionId: string
  ): Promise<ApiResponse | null> => {
    setInSessionLoading(true);
    try {
      const response = await axiosClient.delete<ApiResponse>(
        `sessions/${sessionId}`
      );
      if (response.data.succeeded) {
        setSessions((prev) =>
          prev.filter((session) => session.id !== sessionId)
        );
      }
      return response.data;
    } catch (error) {
      console.error("Error deleting session:", error);
    } finally {
      setInSessionLoading(false);
    }
    return null;
  };

  const onCurrentSessionChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setcurrentSession(e.target.value);
  };

  const handleCurrentSessionSave = async () => {
    if (!currentSession) {
      return;
    }
    setInSessionLoading(true);
    try {
      const response = await axiosClient.put<ApiResponse>(`schools/`, {
        currentSessionId: currentSession,
      });
      if (response.data.succeeded) {
        setSchool((prev) => {
          if (!prev) return prev; // If `prev` is null, return it as is.
          return {
            ...prev,
            currentSessionId: currentSession,
          };
        });
        showSuccessToast();
      } else {
        showErrorToast();
      }
    } catch (error) {
      console.error("Error updating school with current session ID:", error);
      showErrorToast();
    } finally {
      setInSessionLoading(false);
    }
  };

  if (isLoading) {
    return <Loader />;
  }

  if (error) {
    return <ErrorAlert message={error} handleClick={handleRefresh} />;
  }

  return (
    <Flex flexDirection="column" p={5}>
      <Flex flexDir={"column"}>
        <SessionModal
          isOpen={isOpen}
          onClose={onClose}
          sessionToEdit={sessionToEdit}
          addSession={addSession}
          deleteSession={deleteSession}
          editSession={editSession}
          errors={errors}
          setErrors={setErrors}
          nextOrder={nextOrder}
        />

        <HeaderDivider
          heading={"Sessions"}
          hasButton={true}
          handleButtonClick={handleAddSession}
          icon={<BiPlus />}
          colorScheme={"green"}
        />

        <Flex gap={5} wrap={"wrap"} mb={20}>
          {sessions.map((s) => (
            <SessionCard
              key={s.id}
              session={s}
              handleClick={() => handleEditStudent(s)}
            />
          ))}
        </Flex>
      </Flex>
      {sessions.length > 0 && (
        <Flex flexDir={"column"}>
          <HeaderDivider heading={"Current Session"} />
          <Flex gap={5} mb={20}>
            <Select
              placeholder="Select Current Session"
              name="sessionStartDate"
              maxW={300}
              value={currentSession || undefined}
              onChange={onCurrentSessionChange}
            >
              {sessions.map((s) => (
                <option key={s.id} value={s.id}>
                  {s.sessionName}
                </option>
              ))}
            </Select>
            <Button
              colorScheme="brand"
              variant={"outline"}
              isLoading={inSessionLoading}
              onClick={handleCurrentSessionSave}
            >
              {"Save"}
            </Button>
          </Flex>
        </Flex>
      )}
    </Flex>
  );
}

export default SessionSettings;
