import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
} from "@chakra-ui/react";
import React from "react";

interface Props {
  handleDelete: () => void;
  onClose: () => void;
  isOpen: boolean;
  heading?: string;
  message?: string;
}

export default function DeleteAlertDialogModal({
  handleDelete,
  onClose,
  isOpen,
  heading,
  message
}: Props) {
  const cancelRef = React.useRef(null);

  const executeDelete = () => {
    onClose();
    handleDelete();
  };

  return (
    <>
      <AlertDialog
        motionPreset="slideInBottom"
        onClose={onClose}
        isOpen={isOpen}
        isCentered
        leastDestructiveRef={cancelRef}
        
      >
        <AlertDialogOverlay />

        <AlertDialogContent >
          <AlertDialogHeader>{heading || "Are you Sure? 🙄"}</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody bg={"red"} color={"white"}>
            {message ||
              "This operation cannot be reversed. Are you sure you want to proceed?"
            }
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose}>
              No
            </Button>
            <Button colorScheme="red" ml={3} onClick={executeDelete}>
              Yes
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
}
