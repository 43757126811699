import React, { createContext, useState, ReactNode, useContext } from "react";
import {
  ApiDataResponse,
  ApiResponse,
  Guardian,
  Student,
} from "../services/Dtos";
import { useStudentsContext } from "./StudentsContext";
import useAxios from "../hooks/useAxios";

interface GuardiansContextType {
  selectedGuardian: Guardian | null;
  setSelectedGuardian: React.Dispatch<React.SetStateAction<Guardian | null>>;
  addGuardian: (
    newGuardian: Guardian,
    studentId: string
  ) => Promise<Guardian | null>;
  editGuardian: (
    guardianToEdit: Guardian,
    guardianId: string
  ) => Promise<Guardian | null>;
  deleteGuardian: (guardianId: string) => Promise<ApiResponse | null>;
}

interface Props {
  children: ReactNode;
}

const GuardiansContext = createContext<GuardiansContextType | undefined>(
  undefined
);

export const GuardiansProvider = ({ children }: Props) => {
  const [, setIsLoading] = useState(true);
  const [selectedGuardian, setSelectedGuardian] = useState<Guardian | null>(
    null
  );
  const { setStudents, setSelectedStudent, selectedStudent } =
    useStudentsContext();
    const axiosClient = useAxios();

  // useEffect(() => {
  //   if (user && !userLoading) {
  //     const getGuardians = async () => {
  //       setIsLoading(true);
  //       try {
  //         const response = await axiosClient<ApiDataResponse<Guardian[]>>(
  //           "guardians"
  //         );

  //         if (response.data?.succeeded) {
  //           const guardians = response.data.data;
  //           setGuardians(guardians);
  //         } else {
  //           setError(`Error fetching guardians: ${response.data?.message}`);
  //         }
  //       } catch (error) {
  //         console.error("Failed to fetch guardians:", error);
  //         setError("Failed to fetch guardians");
  //       } finally {
  //         setIsLoading(false);
  //       }
  //     };

  //     getGuardians();
  //   }
  // }, [user, userLoading]);

  const addGuardian = async (
    newGuardian: Guardian,
    studentId: string
  ): Promise<Guardian | null> => {
    setIsLoading(true);
    console.log(newGuardian);
    console.log(studentId);
    try {
      const response = await axiosClient.post<ApiDataResponse<Guardian>>(
        `guardians/${studentId}`,
        newGuardian
      );

      console.log(response.statusText);

      if (response.data?.succeeded) {
        const savedGuardian = response.data.data;
        let updatedStudent = null;
        setStudents((prevStudents) => {
          return prevStudents.map((s) => {
            if (s.id === studentId) {
              updatedStudent = {
                ...s,
                guardians: [...s.guardians, savedGuardian],
              };
              return updatedStudent;
            }
            return s;
          });
        });

        setSelectedStudent(updatedStudent);

        return savedGuardian;
      } else {
        return null;
      }
    } catch (error) {
      console.error("Failed to add guardian:", error);
      return null;
    } finally {
      setIsLoading(false);
    }
  };

  //Edit or Update Guardian
  const editGuardian = async (
    guardianToEdit: Guardian
  ): Promise<Guardian | null> => {
    setIsLoading(true);
    let updatedGuardian: Guardian | null = null;

    try {
      const response = await axiosClient.put<ApiResponse>(
        `guardians/${guardianToEdit.guardianId}`,
        guardianToEdit
      );

      if (response.data?.succeeded) {
        // Manually update the guardian data
        updatedGuardian = { ...guardianToEdit };

        // Update students state
        setStudents((prevStudents) => {
          return prevStudents.map((student) => {
            if (student.id === selectedStudent?.id) {
              const updatedGuardians = student.guardians.map((guardian) => {
                if (guardian.guardianId === guardianToEdit.guardianId) {
                  return updatedGuardian as Guardian;
                }
                return guardian;
              });
              const updatedStudent: Student = {
                ...student,
                guardians: updatedGuardians,
              };
              setSelectedStudent(updatedStudent); // Update selected student state
              return updatedStudent;
            }
            return student;
          });
        });

        console.log("Updated Guardian:", updatedGuardian);
        setSelectedGuardian(updatedGuardian);

        return updatedGuardian;
      } else {
        return null;
      }
    } catch (error) {
      console.error("Failed to update guardian:", error);
      return null;
    } finally {
      setIsLoading(false);
    }
  };

  //DeleteGuardian
  const deleteGuardian = async (
    guardianId: string
  ): Promise<ApiResponse | null> => {
    console.log("entered guardian context", guardianId);
    setIsLoading(true);
    try {
      const response = await axiosClient.delete<ApiResponse>(
        `guardians/${guardianId}`
      );

      if (response.data?.succeeded) {
        // Update students state
        setStudents((prevStudents) => {
          return prevStudents.map((student) => {
            return {
              ...student,
              guardians: student.guardians.filter(
                (guardian) => guardian.guardianId !== guardianId
              ),
            };
          });
        });

        // Update selectedStudent state
        setSelectedStudent((prevSelectedStudent) => {
          if (prevSelectedStudent) {
            return {
              ...prevSelectedStudent,
              guardians: prevSelectedStudent.guardians.filter(
                (guardian) => guardian.guardianId !== guardianId
              ),
            };
          }
          return prevSelectedStudent;
        });

        // Optionally, update selectedGuardian state
        if (selectedGuardian?.guardianId === guardianId) {
          setSelectedGuardian(null);
        }

        return response.data;
      } else {
        return response.data;
      }
    } catch (error) {
      console.error("Failed to delete guardian:", error);
      return null;
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <GuardiansContext.Provider
      value={{
        addGuardian,
        editGuardian,
        selectedGuardian,
        setSelectedGuardian,
        deleteGuardian,
      }}
    >
      {children}
    </GuardiansContext.Provider>
  );
};

export const useGuardiansContext = () => {
  const context = useContext(GuardiansContext);
  if (!context) {
    throw new Error(
      "useGuardiansContext must be used within a GuardianProvider"
    );
  }
  return context;
};

// const addGuardian = async (
//   newGuardian: Guardian,
//   studentId: string
// ): Promise<Guardian | null> => {
//   setIsLoading(true);
//   console.log(newGuardian);
//   console.log(studentId);

//   try {
//     const response = await axiosClient.post<ApiDataResponse<Guardian>>(
//       `guardians/${studentId}`,
//       newGuardian
//     );

//     console.log(response.statusText);

//     if (response.data?.succeeded) {
//       const savedGuardian = response.data.data;
//       let updatedStudent = null;

//       setStudents((prevStudents) => {
//         return prevStudents.map((s) => {
//           if (s.id === studentId) {
//             updatedStudent = {
//               ...s,
//               guardians: [...s.guardians, savedGuardian],
//             };
//             return updatedStudent;
//           }
//           return s;
//         });
//       });

//       if (updatedStudent) {
//         setSelectedStudent(updatedStudent);
//       }

//       return savedGuardian;
//     } else {
//       return null;
//     }
//   } catch (error) {
//     console.error("Failed to add guardian:", error);
//     return null;
//   } finally {
//     setIsLoading(false);
//   }
// };
