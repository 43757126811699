import { Box, FormControl, FormLabel, Input, Select } from "@chakra-ui/react";
import { genders } from "../../../../services/constants";
import { AppUser } from "../../../../services/Dtos";
import { ChangeEvent } from "react";

interface Props {
  onUserChange: (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
  appUserFormState: AppUser;
  errors: { [key: string]: string };
}

export default function AppUserForm({ appUserFormState, onUserChange, errors }: Props) {
  return (
    <Box>
      <FormControl mb={4}>
        <FormLabel>First Name</FormLabel>
        <Input
          value={appUserFormState?.firstName}
          name="firstName"
          placeholder="First name"
          onChange={onUserChange}
          isInvalid={!!errors.firstName}
        />
      </FormControl>
      <FormControl mb={4}>
        <FormLabel>Last Name</FormLabel>
        <Input
          value={appUserFormState?.lastName}
          name="lastName"
          placeholder="Last name"
          onChange={onUserChange}
          isInvalid={!!errors.lastName}
        />
      </FormControl>
      <FormControl mb={4}>
        <FormLabel>Email</FormLabel>
        <Input
          value={appUserFormState?.email}
          type="email"
          name="email"
          placeholder="Email"
          onChange={onUserChange}
          isInvalid={!!errors.email}
        />
      </FormControl>
      <FormControl mb={4}>
        <FormLabel>Gender</FormLabel>
        <Select
          value={appUserFormState?.gender}
          name="gender"
          placeholder="Select gender"
          onChange={onUserChange}
          isInvalid={!!errors.gender}
        >
          {genders.map((g) => (
            <option key={g.value} value={g.value}>
              {g.name}
            </option>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}
