import {
  Table,
  Tbody,
  Tr,
  Td,
  HStack,
  Avatar,
  Text,
  Stack,
} from "@chakra-ui/react";
import { tableRowHoverColor } from "../../../services/constants";
import { Student } from "../../../services/Dtos";

interface Props {
  onClickStudent: (student: Student) => void;
  students: Student[];
  classMap: { [key: string]: string };
}

export default function RecentStudentTable({
  onClickStudent,
  students,
  classMap,
}: Props) {
  const reversedStudents = [...students].reverse();
  return (
    <Table>
      <Tbody>
        {reversedStudents.slice(0, 4).map((student) => (
          <Tr
            key={student.id}
            gap={20}
            _hover={{
              backgroundColor: tableRowHoverColor,
              cursor: "pointer",
            }}
            onClick={() => onClickStudent(student)}
          >
            <Td px={0}>
              <HStack alignItems={"flex-start"}>
                <Avatar colorScheme="red" src={student.avatarUrl} size={"sm"} />
                <Stack spacing={0}>
                  <Text>{`${student.firstName} ${student.middleName} ${student.lastName}`}</Text>
                  <Text fontSize={"sm"} opacity={"0.7"} textAlign={"left"} whiteSpace={"nowrap"}>
                    {classMap[student.currentClassId]}
                  </Text>
                </Stack>
              </HStack>
            </Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
}
