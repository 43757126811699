import { Flex, useColorModeValue, VStack } from "@chakra-ui/react";
import { Outlet } from "react-router-dom";
import SettingsTopMenu from "./SettingsTopMenu";

function SettingsLayout() {
  const scrollColor = useColorModeValue("gray.200", "#3B3B3B");

  return (
    <VStack flex={1} overflow="hidden" h={"100%"} p={4}>
      <SettingsTopMenu />
      <Flex
        w={"full"}
        flexDirection={"column"}
        flex={1}
        overflow={"auto"}
        m={2}
        sx={{
          "&::-webkit-scrollbar": {
            display: "block",
            width: "8px",
          },
          "&:hover": {
            "&::-webkit-scrollbar": {
              // display: "block",
              // width: "8px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: scrollColor,
              borderRadius: "4px",
            },
          },
        }}
      >
        <Outlet />
      </Flex>
    </VStack>
  );
}

export default SettingsLayout;
