import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Input,
  VStack,
  Center,
  Text,
  Select,
  Flex,
  useSteps,
  InputGroup,
  InputRightElement,
  InputLeftAddon,
} from "@chakra-ui/react";
import useAuth from "../../hooks/useAuth";
import { BiArrowBack, BiLogInCircle } from "react-icons/bi";
import RegistrationStepper from "../Pages/Authentication/RegistrationStepper";
import {
  validateEmail,
  validatePassword,
  validateRequired,
  validateGender,
  validateNigerianPhoneNumber,
} from "../../services/validation";
import { useNavigate } from "react-router-dom";
import { LoginRequestDto } from "../../services/Dtos";
import { useUserContext } from "../../Contexts/userContext";
import { genders } from "../../services/constants";
import useToaster from "../../hooks/useToaster";
import ErrorAlert from "../ui/ErrorAlert";
import useSound from "use-sound";

interface RegistrationData {
  firstName: string;
  lastName: string;
  gender: number;
  email: string;
  password: string;
  confirmPassword: string;
  schoolName: string;
  schoolAddress: string;
  schoolPhoneNumber: string;
  schoolEmail: string | undefined;
  schoolWebsite: string;
}

const MultiStepForm: React.FC = () => {
  const [play] = useSound("../../../../welcome-sound-MP3.mp3", {
    volume: 0.2,
  });
  const { showSuccessToast, showErrorToast } = useToaster();
  const { register, login, loading, error } = useAuth();
  const { setUser } = useUserContext();
  const [step, setStep] = useState<number>(1);
  const [registrationData, setRegistrationData] = useState<RegistrationData>({
    firstName: "",
    lastName: "",
    gender: 0,
    email: "",
    password: "",
    confirmPassword: "",
    schoolName: "",
    schoolAddress: "",
    schoolPhoneNumber: "",
    schoolEmail: undefined,
    schoolWebsite: "",
  });
  const [success, setSuccess] = useState<string | null>(null);
  const navigate = useNavigate();

  const [show, setShow] = useState(false);
  const handleShowClick = () => setShow(!show);

  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  const handleNextStep = () => {
    if (validateStep()) {
      setStep(step + 1);
      setActiveStep(activeStep + 1);
    }
  };

  const handlePreviousStep = () => {
    setStep(step - 1);
    setActiveStep(activeStep - 1);
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setRegistrationData((prevData) => ({
      ...prevData,
      [name]: name === "gender" ? parseInt(value, 10) : value,
    }));
    console.log(registrationData);
  };
  const [, setLoginData] = useState<LoginRequestDto>({
    username: "",
    password: "",
  });

  useEffect(() => {
    setLoginData({
      username: registrationData.email,
      password: registrationData.password,
    });
  }, [registrationData]);

  const currentErrors: { [key: string]: string } = {};

  const handleRegister = async () => {
    if (validateStep()) {
      const result = await register(registrationData);
      if (result?.succeeded) {
        showSuccessToast(result.message || "");
        const loginResult = await login({
          username: registrationData.email,
          password: registrationData.password,
        });
        if (loginResult?.succeeded) {
          localStorage.setItem("token", loginResult?.data.token);
          localStorage.setItem("user", JSON.stringify(loginResult?.data));

          setUser(loginResult.data);
          setSuccess("Registration and login successful!");
          showSuccessToast(loginResult.message || "");
          // Redirect to dashboard
          navigate("/");
          play();
        } else {
          navigate("/auth/login");
        }
      } else {
        currentErrors.registration = result?.message || "";
        setErrors(currentErrors || {});
        showErrorToast(result?.message || "");
      }
    }
  };

  const steps = [
    { title: "Your Details", description: "Name and Email" },
    { title: "Password", description: "Choose a Secure Password" },
    { title: "School", description: "Setup School Account" },
  ];

  const { activeStep, setActiveStep } = useSteps({
    index: 0,
    count: steps.length,
  });

  const validateStep = (): boolean => {
    if (step === 1) {
      if (!validateRequired(registrationData.firstName)) {
        currentErrors.firstName = "First Name is required";
      }
      if (!validateRequired(registrationData.lastName)) {
        currentErrors.lastName = "Last Name is required";
      }
      if (!validateGender(registrationData.gender)) {
        currentErrors.gender = "gender is required";
      }
      if (
        !validateRequired(registrationData.email) ||
        !validateEmail(registrationData.email)
      ) {
        currentErrors.email = "Valid Email is required";
      }
    } else if (step === 2) {
      if (
        !validateRequired(registrationData.password) ||
        !validatePassword(registrationData.password)
      ) {
        currentErrors.password =
          "Password must be at least 8 characters, must contain lower and upper case and a special character";
      }
      if (registrationData.password !== registrationData.confirmPassword) {
        currentErrors.confirmPassword = "Passwords do not match";
      }
    } else if (step === 3) {
      if (!validateRequired(registrationData.schoolName)) {
        currentErrors.schoolName = "School Name is required";
      }
      if (!validateRequired(registrationData.schoolAddress)) {
        currentErrors.schoolAddress = "School Address is required";
      }

      if (!validateNigerianPhoneNumber(registrationData.schoolPhoneNumber)) {
        currentErrors.schoolPhoneNumber =
          "School Phone Number not in Correct Format";
      }
      // School Email, and School Website are optional
    }
    setErrors(currentErrors);
    return Object.keys(currentErrors).length === 0;
  };

  return (
    <Flex
      h={"full"}
      flexDir={"column"}
      alignItems={"space-between"}
      w={"full"}
    >
      <Center flex={1} width={"full"}>
        <Box
          p={5}
          borderWidth={[0, null, 1]}
          borderRadius="lg"
          width="100%"
          maxWidth="500px"
          flex={"1"}
        >
          <VStack spacing={4}>
            {step === 1 && (
              <>
                <Text mb={5} fontSize="2xl">
                  ✨Let’s Get Started!
                </Text>

                <Input
                  placeholder="First Name *"
                  name="firstName"
                  value={registrationData.firstName}
                  onChange={handleChange}
                  isInvalid={!!errors.firstName}
                />
                {errors.firstName && (
                  <Box
                    w={"full"}
                    textAlign={"left"}
                    color="red.500"
                    fontSize={"sm"}
                  >
                    {errors.firstName}
                  </Box>
                )}

                <Input
                  placeholder="Last Name *"
                  name="lastName"
                  value={registrationData.lastName}
                  onChange={handleChange}
                  isInvalid={!!errors.lastName}
                />
                {errors.lastName && (
                  <Box
                    w={"full"}
                    textAlign={"left"}
                    color="red.500"
                    fontSize={"sm"}
                  >
                    {errors.lastName}
                  </Box>
                )}

                <Select
                  name="gender"
                  value={registrationData.gender}
                  onChange={handleChange}
                  placeholder="Select Gender"
                  isInvalid={!!errors.gender}
                >
                  {genders.map((g) => (
                    <option key={g.value} value={g.value}>
                      {g.name}
                    </option>
                  ))}
                </Select>
                {errors.gender && (
                  <Box
                    w={"full"}
                    textAlign={"left"}
                    color="red.500"
                    fontSize={"sm"}
                  >
                    {errors.gender}
                  </Box>
                )}

                <Input
                  placeholder="Email *"
                  name="email"
                  value={registrationData.email}
                  onChange={handleChange}
                  isInvalid={!!errors.email}
                />
                {errors.email && (
                  <Box
                    w={"full"}
                    textAlign={"left"}
                    color="red.500"
                    fontSize={"sm"}
                  >
                    {errors.email}
                  </Box>
                )}

                <Button
                  w={"full"}
                  onClick={handleNextStep}
                  isDisabled={loading}
                  colorScheme="brand"
                >
                  Next
                </Button>
                <Button
                  size={"sm"}
                  leftIcon={<BiLogInCircle />}
                  isDisabled={loading}
                  variant={"link"}
                  onClick={() => navigate("/auth/login")}
                >
                  Already Have an Account?
                </Button>
              </>
            )}
            {step === 2 && (
              <>
                <Text mb={5} fontSize="2xl">
                  🔒 Secure Your Account
                </Text>

                <InputGroup>
                  <Input
                    type={show ? "text" : "password"}
                    placeholder="Password *"
                    name="password"
                    value={registrationData.password}
                    onChange={handleChange}
                    isInvalid={!!errors.password}
                  />
                  <InputRightElement width="4.5rem">
                    <Button h="1.75rem" size="sm" onClick={handleShowClick}>
                      {show ? "Hide" : "Show"}
                    </Button>
                  </InputRightElement>
                </InputGroup>
                {errors.password && (
                  <Box
                    w={"full"}
                    textAlign={"left"}
                    color="red.500"
                    fontSize={"sm"}
                  >
                    {errors.password}
                  </Box>
                )}
                <InputGroup>
                  <Input
                    type={show ? "text" : "password"}
                    placeholder="Confirm Password *"
                    name="confirmPassword"
                    value={registrationData.confirmPassword}
                    onChange={handleChange}
                    isInvalid={!!errors.confirmPassword}
                  />
                  <InputRightElement width="4.5rem">
                    <Button h="1.75rem" size="sm" onClick={handleShowClick}>
                      {show ? "Hide" : "Show"}
                    </Button>
                  </InputRightElement>
                </InputGroup>
                {errors.confirmPassword && (
                  <Box
                    w={"full"}
                    textAlign={"left"}
                    color="red.500"
                    fontSize={"sm"}
                  >
                    {errors.confirmPassword}
                  </Box>
                )}

                <Button
                  w={"full"}
                  onClick={handleNextStep}
                  isDisabled={loading}
                  colorScheme="brand"
                >
                  Next
                </Button>
                <Button
                  size={"sm"}
                  leftIcon={<BiArrowBack />}
                  onClick={handlePreviousStep}
                  isDisabled={loading}
                  variant={"link"}
                >
                  Go Back
                </Button>
              </>
            )}
            {step === 3 && (
              <>
                <Text mb={5} fontSize="2xl">
                  ⏳ You’re Almost There - setup your school's account
                </Text>
                {errors.registration && (
                  <ErrorAlert message={errors.registration} status="info" />
                  // <Box
                  //   w={"full"}
                  //   textAlign={"left"}
                  //   color="red.500"
                  //   fontSize={"sm"}
                  // >
                  //   {errors.registration}
                  // </Box>
                )}

                <Input
                  placeholder="School Name *"
                  name="schoolName"
                  value={registrationData.schoolName}
                  onChange={handleChange}
                  isInvalid={!!errors.schoolName}
                />
                {errors.schoolName && (
                  <Box
                    w={"full"}
                    textAlign={"left"}
                    color="red.500"
                    fontSize={"sm"}
                  >
                    {errors.schoolName}
                  </Box>
                )}

                <Input
                  placeholder="School Address *"
                  name="schoolAddress"
                  value={registrationData.schoolAddress}
                  onChange={handleChange}
                  isInvalid={!!errors.schoolAddress}
                />
                {errors.schoolAddress && (
                  <Box
                    w={"full"}
                    textAlign={"left"}
                    color="red.500"
                    fontSize={"sm"}
                  >
                    {errors.schoolAddress}
                  </Box>
                )}

                <InputGroup>
                  <InputLeftAddon>+234</InputLeftAddon>
                  <Input
                    type="tel"
                    placeholder="School Phone Number *"
                    name="schoolPhoneNumber"
                    value={registrationData.schoolPhoneNumber}
                    onChange={handleChange}
                    isInvalid={!!errors.schoolPhoneNumber}
                  />
                </InputGroup>
                {errors.schoolPhoneNumber && (
                  <Box
                    w={"full"}
                    textAlign={"left"}
                    color="red.500"
                    fontSize={"sm"}
                  >
                    {errors.schoolPhoneNumber}
                  </Box>
                )}

                <Input
                  placeholder="School Email"
                  name="schoolEmail"
                  value={registrationData.schoolEmail}
                  onChange={handleChange}
                />

                <InputGroup>
                  <InputLeftAddon>https://</InputLeftAddon>
                  <Input
                    placeholder="www.myschoolsite.com"
                    name="schoolWebsite"
                    value={registrationData.schoolWebsite}
                    onChange={handleChange}
                  />
                </InputGroup>

                <Button
                  w={"full"}
                  onClick={handleRegister}
                  isLoading={loading}
                  colorScheme="brand"
                >
                  Register
                </Button>
                <Button
                  size={"sm"}
                  leftIcon={<BiArrowBack />}
                  onClick={handlePreviousStep}
                  isDisabled={loading}
                  variant={"link"}
                >
                  Go Back
                </Button>
              </>
            )}
            {error && <Box color="red.500">{error}</Box>}
            {success && <Box color="green.500">{success}</Box>}
          </VStack>
        </Box>
      </Center>
      <Center as="footer" w={"full"} py={5} px={5} h={100}>
        <RegistrationStepper activeStep={activeStep} steps={steps} />
      </Center>
    </Flex>
  );
};

export default MultiStepForm;
