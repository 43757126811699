import React, { useState, ChangeEvent, useEffect } from "react";
import {
  ApiDataResponse,
  ApiResponse,
  GradeCriteria,
  Level,
} from "../../../../services/Dtos";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  Flex,
  useDisclosure,
  CircularProgress,
  VStack,
  Select,
  Text,
  Stack,
  Center,
} from "@chakra-ui/react";
import { BiTrash } from "react-icons/bi";
import {
  validateGreaterThanZero,
  validateRequired,
} from "../../../../services/validation";
import DeleteAlertDialogModal from "../../../ui/DeleteAlertDialogModal";
import useToaster from "../../../../hooks/useToaster";
import ErrorAlert from "../../../ui/ErrorAlert";
import { colors } from "../../../../services/constants";
import { useSchoolsContext } from "../../../../Contexts/schoolContext";

interface AddGuardianModalProps {
  isOpen: boolean;
  onClose: () => void;
  gradeCriteriaToEdit: GradeCriteria | null;
  addGradeCriteria: (
    gradeCriteria: GradeCriteria
  ) => Promise<ApiDataResponse<GradeCriteria> | null>;
  deleteGradeCriteria: (gradeCriteriaId: string) => Promise<ApiResponse | null>;
  editGradeCriteria: (
    gradeCriteria: GradeCriteria,
    gradeCriteriaId: string
  ) => Promise<ApiResponse | null>;
  gradeLevel: Level | null;
}

const AddGuardianModal: React.FC<AddGuardianModalProps> = ({
  isOpen,
  onClose,
  gradeCriteriaToEdit,
  addGradeCriteria,
  deleteGradeCriteria,
  editGradeCriteria,
  gradeLevel,
}) => {
  const { levels } = useSchoolsContext();
  const [selectedColor, setSelectedColor] = useState("blue");
  const initialGradeCriteriaFormState: GradeCriteria = {
    id: "",
    minScore: 0,
    maxScore: 0,
    grade: "",
    description: "",
    schoolId: "",
    color: selectedColor,
    order: 0,
    levelId: gradeLevel?.id || "",
  };

  const { showSuccessToast, showErrorToast } = useToaster();

  const [inGradeModalLoading, setInGradeModalLoading] = useState(false);
  const [inGradeModalError, setInGradeModalError] = useState<
    string | undefined
  >(undefined);

  const {
    isOpen: deleteIsOpen,
    onOpen,
    onClose: deleteOnClose,
  } = useDisclosure();

  const [gradeCriteriaFormState, setGradeCriteriaFormState] =
    useState<GradeCriteria>(initialGradeCriteriaFormState);

  useEffect(() => {
    if (gradeCriteriaToEdit) {
      setSelectedColor(gradeCriteriaToEdit.color);
      setGradeCriteriaFormState(gradeCriteriaToEdit);
    } else {
      setSelectedColor("blue");
      setGradeCriteriaFormState(initialGradeCriteriaFormState);
    }
  }, [gradeCriteriaToEdit, gradeLevel]);

  useEffect(() => {
    setGradeCriteriaFormState((prev) => ({ ...prev, color: selectedColor }));
  }, [selectedColor]);

  const [gradeCriteriaFormErrors, setGradeCriteriaFormErrors] = useState<{
    [key: string]: string;
  }>({});

  const validateGradeCriteriaForm = (): boolean => {
    const currentGradeCriteriaFormErrors: { [key: string]: string } = {};

    if (!validateRequired(gradeCriteriaFormState.grade)) {
      currentGradeCriteriaFormErrors.grade = "Grade is required";
    }

    if (!validateRequired(gradeCriteriaFormState.levelId)) {
      currentGradeCriteriaFormErrors.levelId = "Level is required";
    }
    if (!validateGreaterThanZero(gradeCriteriaFormState.maxScore)) {
      currentGradeCriteriaFormErrors.maxScore =
        "Maximum Score Should be More Than Zero";
    }

    if (!validateGreaterThanZero(gradeCriteriaFormState.order)) {
      currentGradeCriteriaFormErrors.order = "Order Should be More Than Zero";
    }

    if (!validateRequired(gradeCriteriaFormState.description)) {
      currentGradeCriteriaFormErrors.description = "Description is required";
    }

    setGradeCriteriaFormErrors(currentGradeCriteriaFormErrors);
    return Object.keys(currentGradeCriteriaFormErrors).length === 0;
  };

  const handleAddGradeCriteria = async () => {
    if (!validateGradeCriteriaForm()) {
      return;
    }

    setInGradeModalLoading(true);

    try {
      if (gradeCriteriaToEdit) {
        const response = await editGradeCriteria(
          gradeCriteriaFormState,
          gradeCriteriaToEdit.id
        );
        if (response?.succeeded) {
          showSuccessToast(response.message || "");
          setInGradeModalError(undefined);
          onClose();
        } else {
          showErrorToast(response?.message || "");
          console.info("Error updating gradeCriteria");
        }
      } else {
        const response = await addGradeCriteria(gradeCriteriaFormState);
        if (response?.succeeded) {
          showSuccessToast(response.message || "");
          setGradeCriteriaFormState(initialGradeCriteriaFormState);
          setInGradeModalError(undefined);
          onClose();
        } else {
          setInGradeModalError(response?.message);
          showErrorToast(response?.message || "");
          console.info("Error adding gradeCriteria");
        }
      }
    } catch (error) {
      showErrorToast();
      console.error("Unexpected error:", error);
    } finally {
      setInGradeModalLoading(false);
    }
  };

  const handleDeleteGradeCriteria = async () => {
    if (gradeCriteriaToEdit) {
      setInGradeModalLoading(true);
      const response = await deleteGradeCriteria(gradeCriteriaToEdit?.id);
      if (response) {
        onClose();
        showSuccessToast();
      } else {
        showErrorToast();
      }
    }
    setInGradeModalLoading(false);
  };

  const handleGradeCriteriaChange = (
    event: ChangeEvent<HTMLSelectElement | HTMLInputElement>
  ) => {
    const { name, value, type } = event.target;

    if (type === "checkbox") {
      const { checked } = event.target as HTMLInputElement;
      setGradeCriteriaFormState((prevState) => ({
        ...prevState,
        [name]: checked,
      }));
    } else {
      setGradeCriteriaFormState((prevState) => ({
        ...prevState,
        [name]: name === "order" ? parseInt(value, 10) : value,
      }));
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick={false}
      motionPreset="slideInBottom"
      scrollBehavior={"inside"}
      size={"xl"}
    >
      <DeleteAlertDialogModal
        handleDelete={handleDeleteGradeCriteria}
        onClose={deleteOnClose}
        isOpen={deleteIsOpen}
      />
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {gradeCriteriaToEdit ? "Edit GradeCriteria" : "Add New GradeCriteria"}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack gap={0} alignItems={"center"}>
            {inGradeModalError && <ErrorAlert message={inGradeModalError} />}
            <FormControl mb={4} isRequired>
              <FormLabel>Grade</FormLabel>
              <Input
                name="grade"
                value={gradeCriteriaFormState.grade}
                placeholder="e.g (A)"
                onChange={handleGradeCriteriaChange}
                isInvalid={!!gradeCriteriaFormErrors.grade}
              />
            </FormControl>
            <Flex gap={5} w={"full"}>
              <FormControl mb={4} isRequired>
                <FormLabel>Minimum Score</FormLabel>
                <Input
                  type="number"
                  name="minScore"
                  value={gradeCriteriaFormState.minScore}
                  onChange={handleGradeCriteriaChange}
                  isInvalid={!!gradeCriteriaFormErrors.minScore}
                />
              </FormControl>
              <FormControl mb={4} isRequired>
                <FormLabel>Maximum Score</FormLabel>
                <Input
                  type="number"
                  name="maxScore"
                  value={gradeCriteriaFormState.maxScore}
                  onChange={handleGradeCriteriaChange}
                  isInvalid={!!gradeCriteriaFormErrors.maxScore}
                />
              </FormControl>
            </Flex>
            <FormControl mb={4} isRequired>
              <FormLabel>Description</FormLabel>
              <Input
                name="description"
                value={gradeCriteriaFormState.description}
                placeholder="e.g (Excellent)"
                onChange={handleGradeCriteriaChange}
                isInvalid={!!gradeCriteriaFormErrors.description}
              />
            </FormControl>

            <Flex gap={5} w={"full"}>
              {gradeCriteriaToEdit && (
                <FormControl mb={4} isRequired>
                  <FormLabel>Level</FormLabel>
                  <Select
                    name="levelId"
                    value={gradeCriteriaFormState.levelId}
                    placeholder="Select Level"
                    onChange={handleGradeCriteriaChange}
                    isInvalid={!!gradeCriteriaFormErrors.levelId}
                  >
                    {levels.map((l) => (
                      <option key={l.id} value={l.id}>
                        {l.levelName}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              )}

              <FormControl mb={4} isRequired>
                <FormLabel>Order</FormLabel>
                <Input
                  type="number"
                  name="order"
                  value={gradeCriteriaFormState.order}
                  placeholder="Order"
                  onChange={handleGradeCriteriaChange}
                  isInvalid={!!gradeCriteriaFormErrors.order}
                />
              </FormControl>
            </Flex>
            <Stack my={1} w={"100%"}>
              <Text as={"label"}>Choose Grade Color</Text>
              <Flex
                mt={0.5}
                gap={2}
                borderWidth={1}
                p={3}
                w={"100%"}
                borderRadius={"md"}
              >
                {colors.map((c) => (
                  <Center
                    borderWidth={c.color === selectedColor ? 2 : ""}
                    borderColor={"whiteAlpha.700"}
                    _hover={{
                      cursor: "pointer",
                      bg: c.color + ".300",
                    }}
                    transition={"0.4s"}
                    borderRadius={"md"}
                    key={c.name}
                    h={"35px"}
                    flex={1}
                    //w={c.color === selectedColor ? "" : "35px"}
                    px={2}
                    bg={`${c.color}.500`}
                    onClick={() => setSelectedColor(c.color)}
                  >
                    {c.color === selectedColor && (
                      <Text fontWeight={"bold"} color={"whiteAlpha.700"}>
                        {c.name}
                      </Text>
                    )}
                  </Center>
                ))}
              </Flex>
            </Stack>
          </VStack>
        </ModalBody>
        <ModalFooter alignSelf={"center"}>
          {inGradeModalLoading ? (
            <CircularProgress isIndeterminate color={"brand.300"} />
          ) : (
            <Flex gap={5} justifyContent={"center"}>
              <Button
                colorScheme="brand"
                onClick={handleAddGradeCriteria}
                isLoading={inGradeModalLoading}
              >
                Done
              </Button>

              {gradeCriteriaToEdit && (
                <Button
                  isLoading={inGradeModalLoading}
                  variant={"ghost"}
                  colorScheme="red"
                  leftIcon={<BiTrash />}
                  onClick={onOpen}
                >
                  Delete GradeCriteria
                </Button>
              )}
            </Flex>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AddGuardianModal;
