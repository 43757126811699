import React, {
  createContext,
  useState,
  useEffect,
  ReactNode,
  useContext,
} from "react";
import { ApiDataResponse, Dashboard } from "../services/Dtos";
import { useUserContext } from "./userContext";
import useAxios from "../hooks/useAxios";

interface DashboardsContextType {
  dashboard: Dashboard | null;
  setDashboard: React.Dispatch<React.SetStateAction<Dashboard | null>>;
  error: string | null;
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

interface Props {
  children: ReactNode;
}

const DashboardContext = createContext<DashboardsContextType | undefined>(
  undefined
);

export const DashboardsProvider = ({ children }: Props) => {
  const [dashboard, setDashboard] = useState<Dashboard | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const { user, isLoading: userLoading } = useUserContext();
  const axiosClient = useAxios();

  useEffect(() => {
    if (user && !userLoading) {
      const fetchData = async () => {
        setIsLoading(true);
        try {
          // Fetch dashboards
          const dashboardsResponse = await axiosClient.get<
            ApiDataResponse<Dashboard>
          >("dashboarddata", { headers: { SchoolId: user.schoolId } });
          if (dashboardsResponse.data?.succeeded) {
            setDashboard(dashboardsResponse.data.data);
          } else {
            setError(dashboardsResponse.data?.message);
          }
        } catch (error) {
          console.error("Failed to fetch dashboard data:", error);
          setError("Failed to fetch dashboard data");
        } finally {
          setIsLoading(false);
        }
      };

      fetchData();
    }
  }, [user, userLoading]);

  return (
    <DashboardContext.Provider
      value={{
        dashboard,
        setDashboard,
        error,
        isLoading,
        setIsLoading,
      }}
    >
      {children}
    </DashboardContext.Provider>
  );
};

export const useDashboardContext = () => {
  const context = useContext(DashboardContext);
  if (!context) {
    throw new Error(
      "useDashboardContext must be used within a DashboardProvider"
    );
  }
  return context;
};
