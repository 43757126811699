import {
  Avatar,
  Flex,
  HStack,
  IconButton,
  Image,
  Text,
  VStack,
  useColorModeValue,
  useDisclosure,
  useMediaQuery,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import ColorModeSwitch from "./ColorModeSwitch";
import Lightlogo from "../../assets/breign-logo-light.png";
import Darklogo from "../../assets/breign-logo-dark.png";
import FavIcon from "../../assets/breign-favicon.png";
import { useUserContext } from "../../Contexts/userContext";
import { mobileQuery } from "../../services/constants";
import { HamburgerIcon } from "@chakra-ui/icons";
import MobileMenu from "./MobileMenu";

interface prop {
  pageName: string;
  isCollapsed: boolean;
}

function TopBar({ isCollapsed }: prop) {
  const [greeting, setGreeting] = useState("");
 
  const { isOpen, onOpen, onClose } = useDisclosure(); 
  const topBarColor = useColorModeValue("gray.100", "gray.800");
  const logo = useColorModeValue(Lightlogo, Darklogo);
  const [isMobile] = useMediaQuery(mobileQuery);
  const {user} = useUserContext();



  useEffect(() => {
    const updateGreeting = () => {
      const currentHour = new Date().getHours();
      if (currentHour < 12) {
        setGreeting("Good Morning");
      } else if (currentHour < 18) {
        setGreeting("Good Afternoon");
      } else {
        setGreeting("Good Evening");
      }
    };

    updateGreeting(); // Initial call to set the greeting

    const interval = setInterval(updateGreeting, 1800000); // Update every 30 minutes

    return () => clearInterval(interval); // Clean up the interval on component unmount
  }, []);

  return (
    <Flex
      as={"header"}
      alignItems={"center"}
      justifyContent={"space-between"}
      h="70px"
      px={5}
      //position={"fixed"}
      w={"full"}
      bg={topBarColor}
      boxShadow="sm"
      zIndex={3}
      //wrap={"wrap"}
    >
     {isMobile && <MobileMenu isOpen={isOpen} onClose={onClose}/>}
      <Flex gap={20} alignItems={"center"}>
        <Image
          //transition="transform 0.5s, width 0.5s"
          src={isCollapsed ? FavIcon : logo}
          alt="Breign Logo"
          w={isCollapsed ? "7" : "120px"}
          my="5"
        />
      </Flex>

      <HStack gap={5}>
        {isMobile ? <IconButton fontSize={"xl"} aria-label="menu" icon={<HamburgerIcon/>} onClick={onOpen}/> : <ColorModeSwitch />}
        
        <Flex gap={3} display={{ base: "none", md: "flex" }}>
          <Avatar
            name={user?.firstName + " " + user?.lastName}
            src={user?.avatarUrl || ""}
          ></Avatar>
          <VStack align={"left"} gap={0}>
            <Text fontSize={"md"}>{`${greeting}, ${user?.firstName} 👋`}</Text>
            <Text fontSize={"xs"}>{user?.email}</Text>
          </VStack>
        </Flex>
      </HStack>
    </Flex>
  );
}

export default TopBar;
