import { Text, Box, Divider, Flex, useColorModeValue, Icon } from "@chakra-ui/react";
import { IconType } from "react-icons";

interface Props {
  bg?: string;
  maxW?: string;
  w?: string;
  icon?: IconType;
  header: string;
  body?: string;
}

export default function Blox({ bg, maxW, w, icon, header, body}: Props) {
  const altColor = useColorModeValue("gray.100", "gray.700");

  return (
    <Box
      bg={bg || altColor}
      p={2}
      borderRadius={"lg"}
      borderWidth={1}
      maxW={maxW || ""}
      w={w || ""}
      transition={"300ms"}
    >
      <Flex opacity={0.5} gap={2} alignItems={"center"} >
        <Icon as={icon}/>
        <Text fontSize={"xs"}>{header}</Text>
      </Flex>
      <Divider my={1} />
      <Text opacity={0.5} fontSize={"sm"}>{body}</Text>
    </Box>
  );
}
