import { Center } from "@chakra-ui/react";
import LogoLoader from "./LogoLoader";

export default function Loader() {
  return (
    <Center height="100%">
      <LogoLoader />
      {/* <Spinner size="xl" /> */}
    </Center>
  );
}
