import {
  Badge,
  Box,
  Table,
  Tbody,
  Td,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import { brandTransparentColor } from "../../../../services/constants";
import { Level } from "../../../../services/Dtos";
import { useSchoolsContext } from "../../../../Contexts/schoolContext";

interface Props {
  level: Level;
  handleClick: () => void;
}

export default function LevelCard({ level, handleClick }: Props) {
  const altColor = useColorModeValue("gray.100", "gray.700");
  const { users } = useSchoolsContext();
  let user = users.find((u) => u.id === level.adminId);
  return (
    <Box
      bg={altColor}
      p={3}
      borderRadius={"lg"}
      borderWidth={1}
      minW={"270px"}
      transition={"300ms"}
      _hover={{
        backgroundColor: brandTransparentColor,

        cursor: "pointer",
        transform: "scale(1.09)",
      }}
      onClick={handleClick}
      position={"relative"}
    >
      <Table variant={"simple"} size={"xs"} fontSize={"sm"}>
        <Tbody>
          <Tr>
            <Td>Level Name</Td>
            <Td textAlign={"right"} fontWeight={"bold"} pl={5}>
              <Badge variant={"outline"} colorScheme={"brand"}>
                {level.levelName}
              </Badge>
            </Td>
          </Tr>
          <Tr>
            <Td>Promotion Score</Td>
            <Td textAlign={"right"} fontWeight={"bold"} pl={5}>
              {level.promotionCriteriaScore}
            </Td>
          </Tr>
          <Tr>
            <Td>Next Term Fees</Td>
            <Td textAlign={"right"} fontWeight={"bold"} pl={5}>
              {level.nextTermFee || 0}
            </Td>
          </Tr>
          <Tr>
            <Td>Level Admin</Td>
            <Td textAlign={"right"} fontWeight={"bold"} pl={5}>
              {user ? `${user?.firstName} ${user?.lastName}` : "NOT SET"}
            </Td>
          </Tr>
          <Tr>
            <Td>Order</Td>
            <Td textAlign={"right"} fontWeight={"bold"} pl={5}>
              {level.order}
            </Td>
          </Tr>
        </Tbody>
      </Table>
    </Box>
  );
}
