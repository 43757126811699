import { Text, Box, Divider, Flex, useColorModeValue } from "@chakra-ui/react";
import { ReactNode } from "react";
import { BiGrid } from "react-icons/bi";

interface Props {
  bg?: string;
  maxW?: string;
  w?: string;
  children?: ReactNode;
  icon?: ReactNode;
  header: string;
}

export default function Clave({ bg, icon, header, children,  }: Props) {
  const altColor = useColorModeValue("gray.100", "gray.700");

  return (
    <Box
   
      bg={bg || altColor}
      p={3}
      borderRadius={"lg"}
      borderWidth={1}
      minW={"360px"}
      transition={"300ms"}
    >
      <Flex gap={2} alignItems={"center"} mb={2}>
        {icon || <BiGrid />}
        <Text fontSize={"sm"}>{header}</Text>
      </Flex>
      <Divider />
      {children}
    </Box>
  );
}
