import { Stack, Icon, Box, Text } from "@chakra-ui/react";
import { IconType } from "react-icons";

interface Props {
  heading: string;
  Subtitle: string;
  backgroundColor?: string;
  iconColor?: string;
  icon: IconType;
}
export default function PeriodCard({
  heading,
  Subtitle,
  iconColor,
  icon,
  backgroundColor,
}: Props) {
  return (
    <Stack
      justifyContent={"center"}
      gap={6}
      bg={backgroundColor || ""}
      aspectRatio={[0, 0, 0, 1, 1]}
      w={["40%", "45%", "45%", "150px", "150px"]}
      h={["100px", "100px", "80px", "150px", null]}
      borderRadius={"lg"}
      p={5}
      flexDir={["row", "row", "row", "column", "column"]}
      zIndex={999}
    >
      <Icon as={icon} fontSize={"30px"} color={iconColor || ""} opacity={1}/>
      <Box>
        <Text whiteSpace={"nowrap"} fontWeight={"bold"} >
          {heading || "NOT SET"}
        </Text>
        <Text fontSize={"sm"} >
          {Subtitle}
        </Text>
      </Box>
    </Stack>
  );
}
