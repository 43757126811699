import { Icon, Box, Text, Flex, Center } from "@chakra-ui/react";
import { IconType } from "react-icons";
import { tableRowHoverColor } from "../../../services/constants";
import { useNavigate } from "react-router-dom";

interface Props {
  heading: string;
  Subtitle: string;
  backgroundColor: string;
  iconColor: string;
  icon: IconType;
  link: string;
}
export default function DashboardSetup({
  heading,
  Subtitle,
  backgroundColor,
  iconColor,
  icon,
  link,
}: Props) {

  const navigate = useNavigate();
  return (
    <Flex
      justifyContent={"left"}
      gap={6}
      p={2}
     cursor={"pointer"}
      borderRadius={"lg"}
      _hover={{ bg: tableRowHoverColor, transition: "500ms" }}
      onClick={() => navigate(link || "")}
    >
      <Center p={5} boxSize={5} borderRadius={"lg"}
      bg={backgroundColor}><Icon as={icon} fontSize={"20px"} color={iconColor} /></Center>
      
      <Box>
        <Text fontWeight={"bold"}>
          {heading}
        </Text>
        <Text fontSize={"sm"}>
          {Subtitle}
        </Text>
      </Box>
    </Flex>
  );
}
