import { Box, Step, StepIcon, StepIndicator, StepNumber, StepSeparator, StepStatus, StepTitle, Stepper, useMediaQuery } from '@chakra-ui/react'
import { mobileQuery } from '../../../services/constants';

interface Props{
    activeStep: number;
    steps:{title:string; description:string}[];
}

function RegistrationStepper({activeStep, steps}:Props) {

  const [isMobile] = useMediaQuery(mobileQuery);
  
    return (
      <Stepper index={activeStep} size={isMobile ? "xs" : "sm"} w={"400px"} colorScheme='brand'>
        {steps.map((step, index) => (
          <Step key={index}>
            <StepIndicator>
              <StepStatus
                complete={<StepIcon />}
                incomplete={<StepNumber />}
                active={<StepNumber />}
              />
            </StepIndicator>
  
            <Box flexShrink='0'>
              <StepTitle>{step.title}</StepTitle>
              
            </Box>
  
            <StepSeparator />
          </Step>
        ))}
      </Stepper>
    )
  }

export default RegistrationStepper