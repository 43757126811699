import { useToast } from "@chakra-ui/react";

const useToaster = () => {
  const toast = useToast();

  const showSuccessToast = (description?: string) => {
    toast({
      title: "Success!",
      description: description || "Operation completed successfully.",
      status: "success",
      duration: 5000,
      isClosable: true,
      position: "bottom-right",
    });
  };

  const showErrorToast = (description?: string) => {
    toast({
      title: "Error!",
      description: description || "An error occurred. Please try again.",
      status: "error",
      duration: 5000,
      isClosable: true,
      position: "bottom-right",
    });
  };

  const showWarningToast = (description?: string) => {
    toast({
      title: "Feedback!",
      description: description || "Operation Handled.",
      status: "warning",
      duration: 5000,
      isClosable: true,
      position: "bottom-right",
    });
  };

  return { showSuccessToast, showErrorToast, showWarningToast };
};

export default useToaster;