import {
  Badge,
  Box,
  Table,
  Tbody,
  Td,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import { brandTransparentColor } from "../../../../services/constants";
import { GradeCriteria } from "../../../../services/Dtos";

interface Props {
  gradeCriteria: GradeCriteria;
  handleClick: () => void;
}

export default function GradeCriteriaCard({
  gradeCriteria,
  handleClick,
}: Props) {
  const altColor = useColorModeValue("gray.100", "gray.700");

  return (
    <Box
    w={"350px"}
      bg={altColor}
      p={3}
      borderRadius={"lg"}
      borderWidth={1}
      minW={"270px"}
      transition={"300ms"}
      _hover={{
        backgroundColor: brandTransparentColor,

        cursor: "pointer",
        transform: "scale(1.09)",
      }}
      onClick={handleClick}
      position={"relative"}
    >
      <Table variant={"simple"} size={"xs"} fontSize={"sm"}>
        <Tbody>
          <Tr>
            <Td>Grade</Td>
            <Td textAlign={"right"} fontWeight={"bold"} pl={5}>
              <Badge variant={"outline"} colorScheme={gradeCriteria.color || "blue"}>
                {gradeCriteria.grade}
              </Badge>
            </Td>
          </Tr>
          <Tr>
            <Td>Min Score</Td>
            <Td textAlign={"right"} fontWeight={"bold"} pl={5}>
              {gradeCriteria.minScore}
            </Td>
          </Tr>
          <Tr>
            <Td>Max Score</Td>
            <Td textAlign={"right"} fontWeight={"bold"} pl={5}>
              {gradeCriteria.maxScore}
            </Td>
          </Tr>
          <Tr>
            <Td>Description</Td>
            <Td textAlign={"right"} fontWeight={"bold"} pl={5}>
              {gradeCriteria.description}
            </Td>
          </Tr>
          <Tr>
            <Td>Order</Td>
            <Td textAlign={"right"} fontWeight={"bold"} pl={5}>
              {gradeCriteria.order}
            </Td>
          </Tr>
        </Tbody>
      </Table>
    </Box>
  );
}
