import {
  AddStudentDto,
  Class,
  GradeCriteria,
  HasId,
  Session,
  Student,
} from "./Dtos";


export const getTodayDate = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, "0"); // Add leading zero
  const day = String(today.getDate()).padStart(2, "0"); // Add leading zero
  return `${year}-${month}-${day}`;
};

export const mapClass = (classes: Class[]): { [key: string]: string } => {
  const map: { [key: string]: string } = {};

  classes.forEach((cls) => {
    map[cls.id] = cls.className;
  });

  return map;
};

export const mapSession = (sessions: Session[]): { [key: string]: string } => {
  const map: { [key: string]: string } = {};

  sessions.forEach((s) => {
    map[s.id] = s.sessionName;
  });

  return map;
};

export function calculateAge(birthDateString: string) {
  // Parse the birth date string into a Date object
  const birthDate = new Date(birthDateString);

  // Get today's date
  const today = new Date();

  // Calculate the difference in years
  let age = today.getFullYear() - birthDate.getFullYear();

  // Adjust the age if the current date is before the birthday in the current year
  const monthDifference = today.getMonth() - birthDate.getMonth();
  const dayDifference = today.getDate() - birthDate.getDate();

  if (monthDifference < 0 || (monthDifference === 0 && dayDifference < 0)) {
    age--;
  }

  return age;
}

export const getRandomColor = (seed: string) => {
  const styles = [
    "red",
    "orange",
    "yellow",
    "blue",
    "cyan",
    "purple",
    "pink",
    "brand",
    "gray",
  ];
  const randomStyle = styles[Math.floor(Math.random() * styles.length)];
  return randomStyle + "." + seed;
};

export const findById = <T extends HasId>(
  items: T[],
  id: string
): T | undefined => {
  return items.find((item) => item.id === id);
};

export const convertStudentToDto = (student: Student): AddStudentDto => {
  return {
    firstName: student.firstName,
    middleName: student.middleName,
    lastName: student.lastName,
    gender: student.gender,
    currentClassId: student.currentClassId,
    status: student.status,
    dateOfBirth: formatDate(student.dateOfBirth),
    dateOfRegistration: formatDate(student.dateOfRegistration),
  };
};

export const formatDate = (date: string | Date): string => {
  const d = new Date(date);
  const year = d.getFullYear();
  const month = `0${d.getMonth() + 1}`.slice(-2);
  const day = `0${d.getDate()}`.slice(-2);
  return `${year}-${month}-${day}`;
};

export function capitalizeFirstLetter(string: string) {
  if (!string) return "";
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const getStudentClassNumberMessage = (count: number): string => {
  switch (count) {
    case 0:
      return "Only student in class";
    case 1:
      return "1 more student from the same class";
    default:
      return `${count} more students from the same class`;
  }
};

export const getColorForGrade = (
  gradeCriterias: GradeCriteria[],
  grade: string
): string => {
  const criteria = gradeCriterias.find(
    (c) => c.grade.toUpperCase() === grade.toUpperCase()
  );
  return criteria ? criteria.color : "gray";
};

function isObject(obj: any) {
  return obj !== null && typeof obj === "object";
}

export function deepEqual(obj1: any, obj2: any): boolean {
  if (obj1 === obj2) {
    return true;
  }

  if (!isObject(obj1) || !isObject(obj2)) {
    return false;
  }

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
      return false;
    }
  }

  return true;
}

export function getNextOrder<T extends { order: number }>(
  collection: T[]
): number {
  if (collection.length === 0) return 1;

  const maxOrder = collection.reduce(
    (max, item) => (item.order > max ? item.order : max),
    collection[0].order
  );

  return maxOrder + 1;
}
export function isNullOrEmpty(value: string | null | undefined): boolean {
  return !value || value.trim() === "";
}

export function getInitials(name: string): string {
  const words = name
    .trim()
    .split(" ")
    .filter((word) => word.length > 0);
  let initials = "";

  for (let i = 0; i < words.length && i < 3; i++) {
    initials += words[i][0];
  }

  return initials.toUpperCase();
}

export function calculatePercentage(value: number, total: number) {
  if (total === 0) {
    return 0; // Prevent division by zero
  }
  const percentage = (value / total) * 100;
  return Math.round(percentage); // Round to the nearest whole number
}

export function getColorBasedOnPercentage(percentage: number | string) {
  if ((percentage as number) < 60) {
    return "red"; // For percentages below 70
  } else if (percentage === 100) {
    return "green"; // For percentages equal to 100
  } else {
    return "blue"; // For all other percentages
  }
}

export function getShortName(name: string): string {
  // Split the class name into words
  const words = name.split(" ").filter((word) => word.length > 0);

  // Take the first 2 words and get the first 3 letters of each
  const modifiedName = words
    .slice(0, 2)
    .map((word) => (word.length >= 3 ? word.substring(0, 3) : word))
    .join(" ");

  return modifiedName;
}
