export const localBaseURL = "http://localhost:5130/api/";
export const baseURL = "https://breign.azurewebsites.net/api";
export const loginEndpoint = "authentication/login";
export const registerEndpoint = "authentication/register";
export const refreshTokenEndpoint = "authentication/refreshtoken";
export const forgotPasswordEndpoint = "authentication/forgotpassword";

export const dashBoardRoute = "/";
export const studentsRoute = "/students";
export const resultsRoute = "/results";
export const settingsRoute = "/settings";
export const loginRoute = "/auth/login";
export const registerRoute = "/auth/register";
export const mobileQuery = "(max-width: 600px)";

export const titles: string[] = [
  "Mr.",
  "Mrs.",
  "Chief",
  "Dr.",
  "Barr.",
  "Alh.",
  "Engr.",
  "Madam",
  "Sir.",
  "Elder",
  "Pst.",
  "Rev.",
];

export const genders: { name: string; value: number }[] = [
  { name: "Male", value: 1 },
  { name: "Female", value: 2 },
];

export const roles: { name: string; value: number }[] = [
  { name: "Viewer", value: 0 },
  { name: "Teacher", value: 1 },
  { name: "Editor", value: 2 },
  { name: "Admin", value: 3 },
];

export const adminPolicy: number[] = [3];
export const editorPolicy: number[] = [1, 2, 3];
export const viewerPolicy: number[] = [0];

export const genderMap: { [key: number]: string } = {
  1: "Male",
  2: "Female",
  3: "Others",
};

export const roleMap: { [key: number]: { name: string; color: string } } = {
  0: { name: "Viewer", color: "blue" },
  1: { name: "Teacher", color: "cyan" },
  2: { name: "Editor", color: "green" },
  3: { name: "Admin", color: "brand" },
  4: { name: "", color: "orange" },
};

//Active, Inactive, Suspended, Expelled, Graduated
export const studentStatus: string[] = [
  "Active",
  "Inactive",
  "Suspended",
  "Expelled",
  "Graduated",
];

export const studentStatusMap: {
  [key: number]: { name: string; color: string };
} = {
  0: { name: "Active", color: "green" },
  1: { name: "Inactive", color: "yellow" },
  2: { name: "Suspended", color: "gray" },
  3: { name: "Expelled", color: "red" },
  4: { name: "Graduated", color: "brand" },
};

export const colors: { name: string; color: string }[] = [
  { name: "Breign", color: "brand" },
  { name: "Pink", color: "pink" },
  { name: "Purple", color: "purple" },
  { name: "Cyan", color: "cyan" },
  { name: "Blue", color: "blue" },
  { name: "Teal", color: "teal" },
  { name: "Green", color: "green" },
  { name: "Yellow", color: "yellow" },
  { name: "Orange", color: "orange" },
  { name: "Red", color: "red" },
];

export const tableSelectedRowColor = "rgba(150, 68, 250, 0.14)";
export const tableRowHoverColor = "rgba(150, 68, 250, 0.05)";
export const brandTransparentColor = "rgba(150, 68, 250, 0.05)";
export const greenTransparentColor = "rgba(111, 207, 151, 0.3)";
export const greenTransparentColorHover = "rgba(111, 207, 151, 0.5)";
