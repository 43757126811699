import { Box, Input, VStack, Text, Button, Flex } from "@chakra-ui/react";
import React, { useState } from "react";
import { validateEmail, validateRequired } from "../../services/validation";
import useAuth from "../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import { RiAccountCircleLine, RiLoginBoxLine } from "react-icons/ri";
import useToaster from "../../hooks/useToaster";
import ErrorAlert from "../ui/ErrorAlert";

export default function ForgotPasswordForm() {
  const [forgotPasswordData, setForgotPasswordData] = useState("");

  const navigate = useNavigate();

  const currentErrors: { [key: string]: string } = {};

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { value } = e.target;
    setForgotPasswordData(value);
    console.log(forgotPasswordData);
  };
  const { showSuccessToast, showErrorToast } = useToaster();
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const validForm = (): boolean => {
    

    if (
      !validateRequired(forgotPasswordData) ||
      !validateEmail(forgotPasswordData)
    ) {
      currentErrors.email = "Valid Email is required";
    }

    setErrors(currentErrors);
    return Object.keys(currentErrors).length === 0;
  };

  const [success, setSuccess] = useState(false);

  const { forgotPassword, loading } = useAuth();

  const handleSubmit = async () => {
    if (validForm()) {
      const result = await forgotPassword({ email: forgotPasswordData });
      if (result?.succeeded) {
        setSuccess(true);
        showSuccessToast();
      } else {
        currentErrors.noAccount = "We couldn't find that account";
        setErrors(currentErrors);
        showErrorToast(errors.noAccount);
      }
    }
  };

  return (
    <Box
      h={"full"}
      display={"flex"}
      alignItems={"center"}
      justifyContent={"center"}
      
    >
      <Box
        p={5}
        borderWidth={[0, null, 1]}
        borderRadius="lg"
        width="100%"
        maxWidth="500px"
      >
        {success ? (
          <>
            <Text fontWeight={"bold"} mb={2}>
              🎉 Exciting News!
            </Text>
            <Text>
              To get back into your account, simply check your email for the
              password reset link. If you don’t see it shortly, be sure to check
              your spam or junk folder, just in case!
            </Text>
          </>
        ) : (
          <VStack spacing={4}>
            <Text fontSize="2xl">Forgot Password?</Text>
            {errors.noAccount && (
              <ErrorAlert message={errors.noAccount} />
            )}
            <Text textAlign={"center"}>
              Tell us your email and we will send a link to reset your password
            </Text>

            <Input
              placeholder="Email *"
              name="email"
              value={forgotPasswordData}
              onChange={handleChange}
              isInvalid={!!errors.email}
            />
            {errors.email && (
              <Box
                w={"full"}
                textAlign={"left"}
                color="red.500"
                fontSize={"sm"}
              >
                {errors.email}
              </Box>
            )}

            <Button
              isLoading={loading}
              w={"full"}
              onClick={handleSubmit}
              isDisabled={loading}
              colorScheme="brand"
            >
              Reset Password
            </Button>
            <Flex mt={5} w={"full"} justifyContent={"space-between"} gap={5}>
              <Button
                size={"sm"}
                leftIcon={<RiLoginBoxLine />}
                isDisabled={loading}
                variant={"link"}
                onClick={() => navigate("/auth/login")}
              >
                Log In
              </Button>
              <Button
                size={"sm"}
                leftIcon={<RiAccountCircleLine />}
                isDisabled={loading}
                variant={"link"}
                onClick={() => navigate("/auth/register")}
              >
                Create Account
              </Button>
            </Flex>
          </VStack>
        )}
      </Box>
    </Box>
  );
}
