import { Box, Heading, Badge, Text } from "@chakra-ui/react";
import { roleMap, genderMap } from "../../../../services/constants";
import { useUserContext } from "../../../../Contexts/userContext";

export default function UserProfile() {
  const { user } = useUserContext();

  return (
    <Box>
      <Heading whiteSpace={"nowrap"}>
        {user?.firstName + " " + user?.lastName}
      </Heading>
      <Text>{`${user?.email} • ${genderMap[user?.gender ?? 3]}`}</Text>
      <Badge variant={"subtle"} colorScheme={roleMap[user?.role ?? 3].color}>
        {roleMap[user?.role ?? 3].name}
      </Badge>
    </Box>
  );
}
