import MultiStepForm from "../../forms/MultiStepForm";

function Regiser() {
  return (
    
      <MultiStepForm />
    
  );
}

export default Regiser;
