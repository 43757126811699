import { Box, Button, Flex } from "@chakra-ui/react";

import { useSchoolsContext } from "../../../../Contexts/schoolContext";

import {
  ApiDataResponse,
  ApiResponse,
  AppUser,
  User,
} from "../../../../services/Dtos";
import { ChangeEvent, useEffect, useState } from "react";
import Loader from "../../../ui/Loader";
import ErrorAlert from "../../../ui/ErrorAlert";
import UserModal from "./UserModal";
import { BiEdit, BiPlus, BiUndo } from "react-icons/bi";
import HeaderDivider from "../../../ui/HeaderDivider";
import { useUserContext } from "../../../../Contexts/userContext";
import AppUserForm from "./AppUserForm";
import UserProfile from "./UserProfile";
import UserImage from "./UserImage";
import UsersTable from "./UsersTable";
import useToaster from "../../../../hooks/useToaster";
import {
  validateEmail,
  validateGender,
  validateRequired,
} from "../../../../services/validation";
import ChangePasswordModal from "./ChangePasswordModal";
import useAxios from "../../../../hooks/useAxios";

function UserSettings() {
  const axiosClient =  useAxios();
  const [userToEdit, setUserToEdit] = useState<User | null>(null);
  const { setUsers, isLoading, error } = useSchoolsContext();
  const { user, setUser } = useUserContext();
  const [editingAppUser, setEditingAppUser] = useState(false);
  const [appUserImage, setAppUserImage] = useState<string | null>(null);
  const [inUserLoading, setInUserLoading] = useState(false);
  const [openChangePasswordModal, setOpenChangePasswordModal] = useState(false);
  const [openUserModal, setOpenUserModal] = useState(false);
  const { showErrorToast, showSuccessToast } = useToaster();

  const initialAppUserFormState: AppUser = {
    id: "",
    firstName: "",
    lastName: "",
    email: "",
    role: 0,
    token: "",
    schoolId: "",
    gender: 0,
    isActive: false,
    avatarUrl: "",
    createdAt: "",
    updatedAt: "",
  };

  const [appUserFormState, setAppUserFormState] = useState<AppUser>(
    initialAppUserFormState
  );

  useEffect(() => {
    if (user) {
      setAppUserFormState(user);
    } else {
      setAppUserFormState(initialAppUserFormState);
    }
  }, [user]);

  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  const validateForm = (): boolean => {
    const currentErrors: { [key: string]: string } = {};

    if (!validateRequired(appUserFormState.firstName)) {
      currentErrors.firstName = "First Name is required";
    }
    if (!validateRequired(appUserFormState.lastName)) {
      currentErrors.lastName = "Last Name is required";
    }
    if (!validateGender(appUserFormState.gender)) {
      currentErrors.gender = "gender is required";
    }
    if (!validateEmail(appUserFormState.email)) {
      currentErrors.email = "Valid email is required";
    }

    setErrors(currentErrors);
    return Object.keys(currentErrors).length === 0;
  };

  const handleAppUserChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setAppUserFormState((prevState) => ({
      ...prevState,
      [name]: name === "gender" ? parseInt(value, 10) : value,
    }));
    console.log(appUserFormState);
  };

  const handleRefresh = () => {
    console.log("Handle Click Function not implemented.");
  };

  const handleAddUser = () => {
    setUserToEdit(null);
    setOpenUserModal(true);
    console.log(userToEdit);
  };

  const handleEditUser = (user: User) => {
    console.log(user);
    setUserToEdit(user);
    setOpenUserModal(true);
  };

  const addUser = async (user: User): Promise<ApiDataResponse<User> | null> => {
    try {
      const response = await axiosClient.post<ApiDataResponse<User>>(
        "users",
        user
      );
      if (response.data.succeeded) {
        setUsers((prev) => [...prev, response.data.data]);
      }
      return response.data;
    } catch (error) {}
    return null;
  };

  const editUser = async (
    userToEdit: User,
    userId: string
  ): Promise<ApiResponse | null> => {
    try {
      const response = await axiosClient.put<ApiResponse>(
        `users/${userId}`,
        userToEdit
      );
      if (response.data.succeeded) {
        setUsers((prev) =>
          prev.map((user) => (user.id === userId ? userToEdit : user))
        );
      }
      return response.data;
    } catch (error) {
      console.error("Error editing user:", error);
    }
    return null;
  };

  const updateUserRole = async (
    role: number,
    userId: string
  ): Promise<ApiResponse | null> => {
    try {
      const response = await axiosClient.put<ApiResponse>(
        `users/updateuserrole/${userId}?role=${role}`
      );

      if (response.data.succeeded) {
        setUsers((prev) =>
          prev.map((user) =>
            user.id === userId ? { ...user, role: role } : user
          )
        );
      }
      return response.data;
    } catch (error) {
      console.error("Error updating user role:", error);
      return null;
    }
  };

  const deleteUser = async (userId: string): Promise<ApiResponse | null> => {
    try {
      const response = await axiosClient.delete<ApiResponse>(`users/${userId}`);
      if (response.data.succeeded) {
        setUsers((prev) => prev.filter((user) => user.id !== userId));
      }
      return response.data;
    } catch (error) {
      console.error("Error deleting user:", error);
    }
    return null;
  };

  if (isLoading) {
    return <Loader />;
  }

  if (error) {
    return <ErrorAlert message={error} handleClick={handleRefresh} />;
  }

  const handleOnEditAppUser = () => {
    setEditingAppUser((prev) => !prev);
  };

  const handleAppUserImageUpload = async (
    image: string | null
  ): Promise<boolean> => {
    if (!image) return false;

    const blob = await fetch(image).then((res) => res.blob());
    const formData = new FormData();
    formData.append("file", blob);
    try {
      const response = await axiosClient.post<ApiDataResponse<string>>(
        `users/uploaduserimage/${user?.id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data.succeeded) {
        const newAvatarUrl = response.data.data;
        setUser((prev) => {
          if (prev) {
            const updatedUser = { ...prev, avatarUrl: newAvatarUrl };
            // Update local storage here
            localStorage.setItem("user", JSON.stringify(updatedUser));
            return updatedUser;
          }
          return prev;
        });
        return true;
      }
    } catch (error) {
      console.error("Upload failed:", error);
    }
    return false;
  };

  const handleAppUserUpdate = async (): Promise<boolean> => {
    try {
      const response = await axiosClient.put<ApiResponse>(
        `users/${user?.id}`,
        appUserFormState
      );

      if (response.data.succeeded) {
        setUser(appUserFormState);
        localStorage.setItem("user", JSON.stringify(appUserFormState));
        return true;
      }
    } catch (error) {
      console.error("Update failed:", error);
    }
    return false;
  };

  const handleAppUserUpdateSave = async () => {
    if (!validateForm()) {
      return;
    }
    let imageUploadResponse: boolean = false;
    let updateResponse: boolean = false;
    if (appUserImage) {
      setInUserLoading(true);
      imageUploadResponse = await handleAppUserImageUpload(appUserImage);
    }
    if (appUserImage && !imageUploadResponse) {
      showErrorToast();
      return;
    }
    if (user !== appUserFormState) {
      updateResponse = await handleAppUserUpdate();
      if (updateResponse) {
        showSuccessToast();
        setEditingAppUser(false);
      } else {
        showErrorToast();
      }
    } else {
      showSuccessToast();
      setEditingAppUser(false);
    }
    setInUserLoading(false);
  };

  return (
    <Flex flexDirection={"column"} p={5}>
      <UserModal
        isOpen={openUserModal}
        onClose={() => setOpenUserModal(false)}
        userToEdit={userToEdit}
        addUser={addUser}
        deleteUser={deleteUser}
        editUser={editUser}
        updateUserRole={updateUserRole}
      />
      <ChangePasswordModal
        isOpen={openChangePasswordModal}
        onClose={() => setOpenChangePasswordModal(false)}
      />
      <HeaderDivider
        heading={"Me"}
        hasButton
        icon={editingAppUser ? <BiUndo /> : <BiEdit />}
        colorScheme="blue"
        handleButtonClick={handleOnEditAppUser}
      />
      <Flex gap={5} mb={20}>
        <UserImage
          media={appUserImage}
          setMedia={setAppUserImage}
          isEditing={editingAppUser}
          handleUpload={() => handleAppUserImageUpload(appUserImage)}
        />
        <Box w={"400px"}>
          {editingAppUser ? (
            <Box>
              <AppUserForm
                onUserChange={handleAppUserChange}
                appUserFormState={appUserFormState}
                errors={errors}
              />

              <Button
                isLoading={inUserLoading}
                onClick={handleAppUserUpdateSave}
                variant={"outline"}
                colorScheme="brand"
              >
                Save
              </Button>

              <Button
                mt={5}
                display={"block"}
                onClick={() => setOpenChangePasswordModal(true)}
              >
                Change Password
              </Button>
            </Box>
          ) : (
            <UserProfile />
          )}
        </Box>
      </Flex>
      {user?.role === 3 && (
        <Flex flexDirection={"column"}>
          <HeaderDivider
            heading={"Users"}
            hasButton={true}
            handleButtonClick={handleAddUser}
            icon={<BiPlus />}
            colorScheme={"green"}
          />
          <UsersTable editUser={handleEditUser} />
        </Flex>
      )}
    </Flex>
  );
}

export default UserSettings;
