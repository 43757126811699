import React, { useState, ChangeEvent, useEffect } from "react";
import { AddStudentDto, ApiResponse } from "../../../services/Dtos";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  Select,
  Flex,
  CircularProgress,
} from "@chakra-ui/react";
import { useClassesContext } from "../../../Contexts/ClassesContext";
import { BiHeart, BiPlus, BiTrash } from "react-icons/bi";
import { getTodayDate } from "../../../services/Utils";
import {
  validateGender,
  validateRequired,
  validateStatus,
} from "../../../services/validation";
import { studentStatus, genders } from "../../../services/constants";
import { useStudentsContext } from "../../../Contexts/StudentsContext";
import DeleteAlertDialogModal from "../../ui/DeleteAlertDialogModal";
import useToaster from "../../../hooks/useToaster";

interface AddStudentModalProps {
  isOpen: boolean;
  onClose: () => void;
  onAddStudent: (
    student: AddStudentDto,
    studentId: string
  ) => Promise<ApiResponse | null>;
  openGuardianForm: () => void;
  studentToEdit: AddStudentDto | null;
  selectedStudentId: string;
}

const AddStudentModal: React.FC<AddStudentModalProps> = ({
  isOpen,
  onClose,
  onAddStudent,
  openGuardianForm,
  studentToEdit,
  selectedStudentId,
}) => {
  const initialStudentFormState: AddStudentDto = {
    firstName: "",
    middleName: "",
    lastName: "",
    gender: "",
    currentClassId: "",
    dateOfBirth: getTodayDate(),
    dateOfRegistration: getTodayDate(),
    status: 0,
  };
  const {showSuccessToast, showErrorToast} = useToaster();
  const { deleteStudent } = useStudentsContext();
  const [isLoading, setIsLoading] = useState(false);

  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const [studentFormState, setStudentFormState] = useState<AddStudentDto>(
    initialStudentFormState
  );

  useEffect(() => {
    if (studentToEdit) {
      setStudentFormState(studentToEdit);
    } else {
      setStudentFormState(initialStudentFormState);
    }
  }, [studentToEdit]);

  const { classes } = useClassesContext();

  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  const validateForm = (): boolean => {
    const currentErrors: { [key: string]: string } = {};

    if (!validateRequired(studentFormState.firstName)) {
      currentErrors.firstName = "First Name is required";
    }
    if (!validateRequired(studentFormState.lastName)) {
      currentErrors.lastName = "Last Name is required";
    }
    if (!validateGender(studentFormState.gender)) {
      currentErrors.gender = "gender is required";
    }
    if (!validateRequired(studentFormState.currentClassId)) {
      currentErrors.currentClassId = "Class Name is required";
    }

    if (!validateStatus(studentFormState.status)) {
      currentErrors.status = "Valid Status is required";
    }

    setErrors(currentErrors);
    return Object.keys(currentErrors).length === 0;
  };

  const handleAddStudent = async () => {
    if (validateForm()) {
      setIsLoading(true);
      const response = await onAddStudent(studentFormState, selectedStudentId);
      if (response?.succeeded) {
        setStudentFormState(initialStudentFormState);
        setIsLoading(false);
        onClose();
      }
    }
  };

  const handleDeleteStudent = async () => {
    setIsLoading(true);
    const response = await deleteStudent(selectedStudentId);

    if (response?.succeeded) {
      onClose();
      showSuccessToast();
    } else {
      showErrorToast();
    }
    setIsLoading(false);
  };

  const handleStudentChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setStudentFormState((prevState) => ({
      ...prevState,
      [name]:
        name === "gender" || name === "status" ? parseInt(value, 10) : value,
    }));
    console.log(studentFormState);
  };

  const handleAddAnother = async () => {
    if (validateForm()) {
      setIsLoading(true);
      const response = await onAddStudent(studentFormState, selectedStudentId);
      if (response) {
        setStudentFormState(initialStudentFormState);
      }
    }
    setIsLoading(false);
  };

  const handleAddGuardian = async () => {
    if (validateForm()) {
      setIsLoading(true);
      const newStudent = await onAddStudent(
        studentFormState,
        selectedStudentId
      );
      onClose();
      setStudentFormState(initialStudentFormState);
      if (newStudent) {
        //setSelectedStudent(newStudent);
        console.log(
          "open guardian form method - selected new student",
          newStudent
        );
        openGuardianForm(); // Pass the student's id to the guardian form
      }
    }
    setIsLoading(false);
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick={false}
      motionPreset="slideInBottom"
      scrollBehavior={"inside"}
      size={"xl"}
    >
      <DeleteAlertDialogModal
        handleDelete={handleDeleteStudent}
        onClose={() => setOpenDeleteModal(false)}
        isOpen={openDeleteModal}
        heading="WARNING! ⚠️"
        message="All related students records and result entries will be lost permanently"
      />
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {studentToEdit ? "Edit Student" : "Add New Student"}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl mb={4} isRequired>
            <FormLabel>First Name</FormLabel>
            <Input
              name="firstName"
              value={studentFormState.firstName}
              onChange={handleStudentChange}
              isInvalid={!!errors.firstName}
            />
          </FormControl>
          <FormControl mb={4}>
            <FormLabel>Middle Name</FormLabel>
            <Input
              name="middleName"
              value={studentFormState.middleName}
              onChange={handleStudentChange}
            />
          </FormControl>
          <FormControl mb={4} isRequired>
            <FormLabel>Last Name</FormLabel>
            <Input
              name="lastName"
              value={studentFormState.lastName}
              onChange={handleStudentChange}
              isInvalid={!!errors.lastName}
            />
          </FormControl>

          <Flex gap={5}>
            <FormControl mb={4} isRequired>
              <FormLabel>Gender</FormLabel>
              <Select
                name="gender"
                value={studentFormState.gender}
                onChange={handleStudentChange}
                placeholder="Select Gender"
                isInvalid={!!errors.gender}
              >
               {genders.map((g) => (
                  <option value={g.value} key={g.value}>
                    {g.name}
                  </option>
                ))}
              </Select>
            </FormControl>
            <FormControl mb={4} isRequired>
              <FormLabel>Class</FormLabel>
              <Select
                name="currentClassId"
                value={studentFormState.currentClassId}
                onChange={handleStudentChange}
                placeholder="Select Class"
                isInvalid={!!errors.currentClassId}
              >
                {classes.map((cls) => (
                  <option key={cls.id} value={cls.id}>
                    {cls.className}
                  </option>
                ))}
              </Select>
            </FormControl>
            <FormControl mb={4}>
              <FormLabel>Status</FormLabel>
              <Select
                name="status"
                value={studentFormState.status}
                onChange={handleStudentChange}
                placeholder="Select Status"
                isInvalid={!!errors.status}
              >
                {studentStatus.map((status, index) => (
                  <option key={index} value={index}>
                    {status}
                  </option>
                ))}
              </Select>
            </FormControl>
          </Flex>

          <Flex gap={5}>
            <FormControl mb={4}>
              <FormLabel>Date of Birth</FormLabel>
              <Input
                type="date"
                name="dateOfBirth"
                value={studentFormState.dateOfBirth}
                onChange={handleStudentChange}
              />
            </FormControl>
            <FormControl mb={4}>
              <FormLabel>Date of Registration</FormLabel>
              <Input
                type="date"
                name="dateOfRegistration"
                value={studentFormState.dateOfRegistration}
                onChange={handleStudentChange}
              />
            </FormControl>
          </Flex>
        </ModalBody>
        <ModalFooter alignSelf={"center"}>
          {isLoading ? (
            <CircularProgress isIndeterminate color={"brand.300"} />
          ) : (
            <Flex gap={5} justifyContent={"center"}>
              <Button colorScheme="brand" onClick={handleAddStudent}>
                Done
              </Button>
              {!studentToEdit && (
                <Button
                  leftIcon={<BiPlus />}
                  variant={"outline"}
                  onClick={handleAddAnother}
                >
                  Add Another
                </Button>
              )}
              {!studentToEdit && (
                <Button
                  variant={"link"}
                  leftIcon={<BiHeart />}
                  onClick={handleAddGuardian}
                >
                  Add Guardian
                </Button>
              )}
              {studentToEdit && (
                <Button
                  variant={"ghost"}
                  colorScheme="red"
                  leftIcon={<BiTrash />}
                  onClick={() => setOpenDeleteModal(true)}
                >
                  Delete Student
                </Button>
              )}
            </Flex>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AddStudentModal;
