import { Flex, Table, Tbody, Tr, Td, IconButton } from "@chakra-ui/react";
import { BiEdit } from "react-icons/bi";
import { useSchoolsContext } from "../../../../Contexts/schoolContext";
import { Level, BehavioralSubject } from "../../../../services/Dtos";

interface Props {
  handleEditBehavioralSubject: (behavioralSubject: BehavioralSubject) => void;
  level: Level;
}

export default function BehavioralSubjectTable({ handleEditBehavioralSubject, level }: Props) {
  const { behavioralSubjects } = useSchoolsContext();
  return (
    <Flex gap={5} wrap={"wrap"} maxW={"400px"} >
      <Table >
        <Tbody>
          {behavioralSubjects
            .filter((sub) => sub.levelId === level.id)
            .map((s) => (
              <Tr key={s.id}>
                <Td>{s.name}</Td>
                <Td textAlign={"right"}>
                  <IconButton
                    size={"sm"}
                    icon={<BiEdit />}
                    onClick={() => handleEditBehavioralSubject(s)}
                    aria-label={"edit behavioralSubject"}
                  />
                </Td>
              </Tr>
            ))}
        </Tbody>
      </Table>
    </Flex>
  );
}
