import {
  Badge,
  Input,
  Skeleton,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Text,
  Box,
  Select,
  Heading,
  Stack,
  Flex,
  Textarea,
} from "@chakra-ui/react";
import { ChangeEvent, Dispatch, useEffect } from "react";
import {
  Student,
  Subject,
  BehavioralSubject,
  GradeCriteria,
  CognitiveResultEntry,
  BehavioralResultEntry,
  SummaryResultEntry,
  AssessmentType,
} from "../../../services/Dtos";
import { useSchoolsContext } from "../../../Contexts/schoolContext";
import Empty from "../../ui/Empty";
import { useNavigate } from "react-router-dom";
import { getColorForGrade } from "../../../services/Utils";
import { useClassesContext } from "../../../Contexts/ClassesContext";
import { editorPolicy } from "../../../services/constants";
import { useUserContext } from "../../../Contexts/userContext";

interface Props {
  chosenStudent: Student | null;
  selectedClassId: string | null;
  setUnsavedChanges: Dispatch<React.SetStateAction<boolean>>;
  cognitiveResultEntries: CognitiveResultEntry[];
  behavioralResultEntries: BehavioralResultEntry[];
  summaryResultEntry: SummaryResultEntry;
  editedCognitiveEntries: CognitiveResultEntry[];
  editedBehavioralEntries: BehavioralResultEntry[];
  editedSummaryEntry: SummaryResultEntry;
  setEditedCognitiveEntries: Dispatch<
    React.SetStateAction<CognitiveResultEntry[]>
  >;
  setEditedBehavioralEntries: Dispatch<
    React.SetStateAction<BehavioralResultEntry[]>
  >;
  setEditedSummaryEntry: Dispatch<React.SetStateAction<SummaryResultEntry>>;
  setSummaryResultEntry: Dispatch<React.SetStateAction<SummaryResultEntry>>;
  currentClassSubjects: Subject[];
  currentBehavioralSubjects: BehavioralSubject[];
  editingSubjectIds: string[];
  setEditingSubjectIds: Dispatch<React.SetStateAction<string[]>>;
  fetchingStudentResult: boolean;
  currentLevel: string;
  filteredAssessmentTypes: AssessmentType[];
  filteredGradeCriterias: GradeCriteria[];
}

export default function ResultEntryTable({
  chosenStudent,
  selectedClassId,
  setUnsavedChanges,
  cognitiveResultEntries,
  behavioralResultEntries,
  summaryResultEntry,
  editedCognitiveEntries,
  editedBehavioralEntries,
  setEditedCognitiveEntries,
  setEditedBehavioralEntries,
  currentClassSubjects,
  currentBehavioralSubjects,
  editingSubjectIds,
  setEditingSubjectIds,
  editedSummaryEntry,
  setEditedSummaryEntry,
  fetchingStudentResult,
  filteredAssessmentTypes,
  filteredGradeCriterias,
}: Props) {
  const filledColor = useColorModeValue("blackAlpha.200", "whiteAlpha.100");
  const { school, assessmentTypes, levels } = useSchoolsContext();
  const { classes } = useClassesContext();
  const { users } = useSchoolsContext();
  const altColor = useColorModeValue("gray.100", "gray.700");
  const { user } = useUserContext();

  const navigate = useNavigate();

  const initialResultEntryState: CognitiveResultEntry = {
    id: "",
    studentId: chosenStudent?.id || "",
    subjectId: "",
    termId: school?.currentTermId || "",
    sessionId: school?.currentSessionId || "",
    classId: selectedClassId || "",
    assessmentValues: {},
    totalScore: null,
    grade: "",
    classAverage: null,
    classPosition: null,
  };

  const initialBehavioralResultEntryState: BehavioralResultEntry = {
    id: "",
    studentId: chosenStudent?.id || "",
    behavioralSubjectId: "",
    termId: school?.currentTermId || "",
    sessionId: school?.currentSessionId || "",
    classId: selectedClassId || "",
    grade: "",
  };

  const initialSummaryResultEntryState: SummaryResultEntry = {
    id: "",
    publicResultId: "",
    studentId: chosenStudent?.id || "",
    subjectId: "",
    termId: school?.currentTermId || "",
    sessionId: school?.currentSessionId || "",
    classId: selectedClassId || "",
    daysPresent: 0,
    daysPunctual: 0,
    averageScore: 0,
    maxMarks: 0,
    positionInClass: 0,
    headRemark: "",
    formTeacherRemark: "",
  };

  useEffect(() => {
    if (summaryResultEntry) {
      setEditedSummaryEntry(summaryResultEntry);
    } else {
      setEditedSummaryEntry(initialSummaryResultEntryState);
    }
  }, [summaryResultEntry, chosenStudent, selectedClassId]);

  const getMaxScore = (typeName: string): number => {
    const assessmentType = assessmentTypes.find(
      (type) => type.name === typeName
    );
    return assessmentType ? assessmentType.maxScore : Infinity;
  };

  const handleCognitiveInputChange = (
    subjectId: string,
    field: string,
    value: string | number
  ) => {
    setUnsavedChanges(true);
    const maxScore = getMaxScore(field);
    if ((value as number) > maxScore) {
      alert(`The value for ${field} cannot exceed ${maxScore}.`);
      return;
    }

    setEditedCognitiveEntries((prev) => {
      const existingEntry = prev.find((entry) => entry.subjectId === subjectId);
      if (existingEntry) {
        const assessments = existingEntry.assessmentValues
          ? existingEntry.assessmentValues
          : {};
        assessments[field] = typeof value === "number" ? value : Number(value);
        return prev.map((entry) =>
          entry.subjectId === subjectId
            ? { ...entry, assessmentValues: assessments }
            : entry
        );
      } else {
        const originalEntry =
          cognitiveResultEntries.find(
            (entry) => entry.subjectId === subjectId
          ) || initialResultEntryState;
        const assessments = originalEntry.assessmentValues
          ? originalEntry.assessmentValues
          : {};
        assessments[field] = typeof value === "number" ? value : Number(value);
        return [
          ...prev,
          {
            ...originalEntry,
            subjectId,
            assessmentValues: assessments,
          },
        ];
      }
    });

    if (!editingSubjectIds.includes(subjectId)) {
      setEditingSubjectIds((prev) => [...prev, subjectId]);
    }
  };

  const handleBehavioralGradeChange = (
    behavioralSubjectId: string,
    grade: string
  ) => {
    setUnsavedChanges(true);

    setEditedBehavioralEntries((prev) => {
      const existingEntry = prev.find(
        (entry) => entry.behavioralSubjectId === behavioralSubjectId
      );
      if (existingEntry) {
        return prev.map((entry) =>
          entry.behavioralSubjectId === behavioralSubjectId
            ? { ...entry, grade }
            : entry
        );
      } else {
        const originalEntry =
          behavioralResultEntries.find(
            (entry) => entry.behavioralSubjectId === behavioralSubjectId
          ) || initialBehavioralResultEntryState;
        return [
          ...prev,
          {
            ...originalEntry,
            behavioralSubjectId,
            grade,
          },
        ];
      }
    });

    if (!editingSubjectIds.includes(behavioralSubjectId)) {
      setEditingSubjectIds((prev) => [...prev, behavioralSubjectId]);
    }
  };

  const handleSummaryInputChange = (
    event: ChangeEvent<
      HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value } = event.target;
    setUnsavedChanges(true);
    setEditedSummaryEntry((prev) => ({
      ...prev,
      [name]:
        name === "daysPresent" || name === "daysPunctual"
          ? parseInt(value, 10)
          : value,
    }));

    console.log("edited Summary", {
      ...editedSummaryEntry,
      [name]: value,
    });
  };

  const mergeCognitiveEntries = (subjectId: string): CognitiveResultEntry => {
    const entryFromResults =
      cognitiveResultEntries.find((e) => e.subjectId === subjectId) ||
      initialResultEntryState;
    const entryFromEdited =
      editedCognitiveEntries.find((e) => e.subjectId === subjectId) ||
      ({} as CognitiveResultEntry);

    const mergedEntry: CognitiveResultEntry = {
      ...entryFromResults,
      ...entryFromEdited,
      subjectId,
      assessmentValues: {
        ...entryFromResults.assessmentValues,
        ...entryFromEdited.assessmentValues,
      },
    };

    return mergedEntry;
  };

  const mergeBehavioralEntries = (
    behavioralSubjectId: string
  ): BehavioralResultEntry => {
    const entryFromResults =
      behavioralResultEntries.find(
        (e) => e.behavioralSubjectId === behavioralSubjectId
      ) || initialBehavioralResultEntryState;
    const entryFromEdited =
      editedBehavioralEntries.find(
        (e) => e.behavioralSubjectId === behavioralSubjectId
      ) || ({} as BehavioralResultEntry);

    const mergedEntry: BehavioralResultEntry = {
      ...entryFromResults,
      ...entryFromEdited,
      behavioralSubjectId,
      grade: entryFromEdited.grade || entryFromResults.grade,
    };

    return mergedEntry;
  };

  const getFormTeacherName = () => {
    const teacherId = classes.find(
      (c) => c.id === selectedClassId
    )?.formTeacherId;
    const teacher = users.find((u) => u.id === teacherId);
    return `${teacher?.firstName || ""} ${teacher?.lastName || ""}`;
  };

  const getHeadTeacherName = () => {
    const cls = classes.find((c) => c.id === selectedClassId);
    const teacherId = levels.find((l) => l.id === cls?.levelId)?.adminId;
    const teacher = users.find((u) => u.id === teacherId);
    return `${teacher?.firstName || ""} ${teacher?.lastName || ""}`;
  };

  return (
    <>
      {!school?.currentSessionId || !school.currentTermId ? (
        <Empty
          message="Current Session or Term not set"
          hasButton
          handleClick={() => navigate("/settings/term")}
          hidePicture
          buttonText="Go to Settings"
        />
      ) : (
        <>
          <Stack>
            <Box w={"100%"} borderWidth={1} borderX={"none"} p={3} my={2}>
              <Heading fontSize={"md"}>Cognitive Analysis of Results</Heading>
            </Box>

            {filteredAssessmentTypes.length ? (
              <Table borderRadius={5} variant={"striped"}>
                <Thead
                  position={"sticky"}
                  top={"-5"}
                  zIndex={2}
                  bg="blackAlpha.300"
                  backdropFilter="blur(10px) hue-rotate(90deg)"
                >
                  <Tr>
                    <Th whiteSpace={"nowrap"}>Cognitive Subject</Th>
                    {filteredAssessmentTypes.map((type) => (
                      <Th w={"70px"} textAlign={"center"} key={type.id}>
                        {`${type.shortName} (${type.maxScore})`}
                      </Th>
                    ))}
                    <Th textAlign={"center"}>TOT</Th>
                    <Th textAlign={"center"}>AVG</Th>
                    <Th textAlign={"center"}>POS</Th>
                    <Th textAlign={"center"}>GRD</Th>
                  </Tr>
                </Thead>

                <Tbody>
                  {currentClassSubjects.map((subject) => {
                    const entry = mergeCognitiveEntries(subject.id);
                    const assessments = entry.assessmentValues || {};
                    return (
                      <Tr key={subject.id}>
                        <Td p={2} whiteSpace={"wrap"}>
                          {subject.subjectName}
                        </Td>
                        {filteredAssessmentTypes.map((type) =>
                          chosenStudent ? (
                            fetchingStudentResult ? (
                              <Td key={type.id}>
                                <Skeleton m={"auto"} height="20px" w={"30px"} />
                              </Td>
                            ) : (
                              <Td key={type.id} py={0} px={2} w={"70px"}>
                                <Input
                                  textAlign={"center"}
                                  variant={"filled"}
                                  bg={filledColor}
                                  type="number"
                                  value={assessments[type.name]}
                                  onChange={(e) =>
                                    handleCognitiveInputChange(
                                      subject.id,
                                      type.name,
                                      Number(e.target.value)
                                    )
                                  }
                                  readOnly={
                                    !editorPolicy.includes(user?.role || 0)
                                  }
                                />
                              </Td>
                            )
                          ) : (
                            <Td key={type.id}></Td>
                          )
                        )}
                        <Td textAlign={"center"}>
                          {editingSubjectIds.includes(subject.id) ||
                          fetchingStudentResult ? (
                            <Skeleton
                              m={"auto"}
                              speed={1.5}
                              height="20px"
                              w={"30px"}
                            />
                          ) : (
                            entry.totalScore
                          )}
                        </Td>
                        <Td textAlign={"center"}>
                          {editingSubjectIds.includes(subject.id) ||
                          fetchingStudentResult ? (
                            <Skeleton
                              m={"auto"}
                              speed={1.5}
                              height="20px"
                              w={"30px"}
                            />
                          ) : (
                            entry.classAverage
                          )}
                        </Td>
                        <Td textAlign={"center"}>
                          {editingSubjectIds.includes(subject.id) ||
                          fetchingStudentResult ? (
                            <Skeleton
                              m={"auto"}
                              speed={1.5}
                              height="20px"
                              w={"30px"}
                            />
                          ) : (
                            entry.classPosition
                          )}
                        </Td>
                        <Td textAlign={"center"}>
                          {editingSubjectIds.includes(subject.id) ||
                          fetchingStudentResult ? (
                            <Skeleton m={"auto"} height="20px" w={"30px"} />
                          ) : (
                            <Badge
                              variant={"outline"}
                              fontSize={"md"}
                              colorScheme={getColorForGrade(
                                filteredGradeCriterias,
                                entry.grade
                              )}
                            >
                              {entry.grade}
                            </Badge>
                          )}
                        </Td>
                      </Tr>
                    );
                  })}
                </Tbody>
              </Table>
            ) : (
              <Empty
                message="Assessment Types or Subjects not set"
                hidePicture
                hasButton
                buttonText="Go to Settings"
                handleClick={() => navigate("/settings/assessment")}
              />
            )}
          </Stack>
          <Stack>
            <Box
              w={"100%"}
              borderWidth={1}
              borderX={"none"}
              p={3}
              my={2}
              mt={20}
            >
              <Heading fontSize={"md"}>Behavioral Analysis of Results</Heading>
            </Box>

            {currentBehavioralSubjects.length ? (
              <Flex gap={10} wrap={"wrap"}>
                {currentBehavioralSubjects.map((subject) => {
                  const entry = mergeBehavioralEntries(subject.id);
                  const grades = entry.grade || "";
                  return (
                    <Box
                      bg={altColor}
                      w={"250px"}
                      p={2}
                      borderWidth={1}
                      borderRadius="lg"
                      key={subject.id}
                    >
                      <Flex
                        gap={5}
                        alignItems={"center"}
                        justifyContent={"space-between"}
                      >
                        <Flex gap={4} alignItems={"center"}>
                          <Box
                            opacity={"0.8"}
                            borderRadius="lg"
                            w={"10px"}
                            h={"30px"}
                            bg={
                              getColorForGrade(
                                filteredGradeCriterias,
                                entry.grade
                              ) + ".200"
                            }
                          ></Box>
                          <Text>{subject.name}</Text>
                        </Flex>
                        {chosenStudent ? (
                          fetchingStudentResult ? (
                            <Skeleton m={"auto"} height="30px" w={"full"} />
                          ) : (
                            <Select
                              variant={"filled"}
                              bg={filledColor}
                              value={grades}
                              onChange={(e) =>
                                handleBehavioralGradeChange(
                                  subject.id,
                                  e.target.value
                                )
                              }
                              style={{
                                pointerEvents: editorPolicy.includes(
                                  user?.role || 0
                                )
                                  ? "auto"
                                  : "none",
                              }}
                            >
                              <option value="">Grade</option>
                              {filteredGradeCriterias.map((grade) => (
                                <option key={grade.id} value={grade.grade}>
                                  {grade.grade}
                                </option>
                              ))}
                            </Select>
                          )
                        ) : (
                          <Text></Text>
                        )}
                      </Flex>
                    </Box>
                  );
                })}
              </Flex>
            ) : (
              <Empty
                message="Add Behavioral Subjects in settings"
                hidePicture
                hasButton
                buttonText="Go to Settings"
                handleClick={() => navigate("/settings/subject")}
              />
            )}
          </Stack>
          <Stack>
            <Box
              w={"100%"}
              borderWidth={1}
              borderX={"none"}
              p={3}
              my={2}
              mt={20}
            >
              <Heading fontSize={"md"}>Summary of Results</Heading>
            </Box>
            <Flex gap={5}>
              <Stack
                bg={altColor}
                w={"150px"}
                p={2}
                borderWidth={1}
                borderRadius="lg"
                alignItems={"center"}
                gap={5}
                justifyContent={"space-between"}
              >
                <Text>Days Present</Text>
                {chosenStudent ? (
                  fetchingStudentResult ? (
                    <Skeleton m={"auto"} height="30px" w={"full"} />
                  ) : (
                    <Input
                      name="daysPresent"
                      textAlign={"center"}
                      variant={"filled"}
                      bg={filledColor}
                      type="number"
                      value={editedSummaryEntry.daysPresent}
                      onChange={handleSummaryInputChange}
                      readOnly = {!editorPolicy.includes(user?.role || 0)}
                    />
                  )
                ) : (
                  <Text></Text>
                )}
              </Stack>
              <Stack
                bg={altColor}
                w={"150px"}
                p={3}
                borderWidth={1}
                borderRadius="lg"
                alignItems={"center"}
                gap={5}
                justifyContent={"space-between"}
              >
                <Text>Days Punctual</Text>
                {chosenStudent ? (
                  fetchingStudentResult ? (
                    <Skeleton m={"auto"} height="30px" w={"full"} />
                  ) : (
                    <Input
                      name="daysPunctual"
                      textAlign={"center"}
                      variant={"filled"}
                      bg={filledColor}
                      type="number"
                      value={editedSummaryEntry.daysPunctual || 0}
                      onChange={handleSummaryInputChange}
                      readOnly = {!editorPolicy.includes(user?.role || 0)}
                    />
                  )
                ) : (
                  <Text></Text>
                )}
              </Stack>
            </Flex>

            <Stack
              mt={5}
              bg={altColor}
              w={"full"}
              p={2}
              borderWidth={1}
              borderRadius="lg"
              justifyContent={"space-between"}
            >
              <Text>Form Teacher's Remark</Text>
              <Text fontSize={"sm"} opacity={0.5}>
                {getFormTeacherName()}
              </Text>
              {chosenStudent ? (
                fetchingStudentResult ? (
                  <Skeleton m={"auto"} height="30px" w={"full"} />
                ) : (
                  <Textarea
                    name="formTeacherRemark"
                    variant={"filled"}
                    bg={filledColor}
                    value={editedSummaryEntry.formTeacherRemark || ""}
                    onChange={handleSummaryInputChange}
                    readOnly = {!editorPolicy.includes(user?.role || 0)}
                  />
                )
              ) : (
                <Text></Text>
              )}
            </Stack>
            <Stack
              mt={5}
              bg={altColor}
              w={"full"}
              p={3}
              borderWidth={1}
              borderRadius="lg"
              justifyContent={"space-between"}
            >
              <Text>Head or Principal's Remark</Text>
              <Text fontSize={"sm"} opacity={0.5}>
                {getHeadTeacherName()}
              </Text>
              {chosenStudent ? (
                fetchingStudentResult ? (
                  <Skeleton m={"auto"} height="30px" w={"full"} />
                ) : (
                  <Textarea
                    name="headRemark"
                    variant={"filled"}
                    bg={filledColor}
                    value={editedSummaryEntry.headRemark || ""}
                    onChange={handleSummaryInputChange}
                    readOnly = {!editorPolicy.includes(user?.role || 0)}
                  />
                )
              ) : (
                <Text></Text>
              )}
            </Stack>
          </Stack>{" "}
        </>
      )}
      {selectedClassId && !currentClassSubjects.length && (
        <Empty
          hidePicture
          height="35%"
          hasButton
          buttonText="Go to Class Settings"
          handleClick={() => navigate("/settings/class")}
          message="Start by assigning subjects to this class"
        />
      )}
    </>
  );
}
