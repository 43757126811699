import { IconButton } from "@chakra-ui/react";
import { BiRefresh } from "react-icons/bi";

interface Props {
  handleRefresh: () => void;
  isLoading: boolean;
}
export default function ChartRefreshButton({ handleRefresh, isLoading }: Props) {
  return (
    <IconButton
    isLoading = {isLoading}
      aria-label="refresh chart"
      icon={<BiRefresh />}
      onClick={handleRefresh}
    />
  );
}
