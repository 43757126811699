import {
  Box,
  Button,
  Flex,
  Text,
  useColorModeValue,
  useMediaQuery,
  VStack,
} from "@chakra-ui/react";
import { IconType } from "react-icons";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { BiHome, BiBookOpen, BiGroup, BiCog, BiLogOut } from "react-icons/bi";
import { Dispatch, useEffect } from "react";
import { useUserContext } from "../../Contexts/userContext";
import useToaster from "../../hooks/useToaster";
import { SlArrowLeft, SlArrowRight } from "react-icons/sl";
import useAxios from "../../hooks/useAxios";
interface Props {
  isCollapsed: boolean;
  setIsCollapsed: Dispatch<React.SetStateAction<boolean>>;
}
function NavMenu({ isCollapsed, setIsCollapsed }: Props) {
  interface NavLinks {
    Name: string;
    Link: string;
    Icon: IconType;
  }

  const topNavLinks: NavLinks[] = [
    { Name: "Home", Link: "/", Icon: BiHome },
    { Name: "Students", Link: "/students", Icon: BiGroup },
    { Name: "Results", Link: "/results", Icon: BiBookOpen },
  ];

  const axiosClient = useAxios();

  const location = useLocation();

  const topBarColor = useColorModeValue("gray.100", "gray.800");

  const [isTablet] = useMediaQuery("(max-width: 1000px)");
  
  

  useEffect(() => {
    if (isTablet) {
      setIsCollapsed(true); // Automatically collapse on mobile
    } else {
      setIsCollapsed(false);
    }
  }, [isTablet]);

  const { setUser } = useUserContext();
  const navigate = useNavigate();
  const { showSuccessToast, showErrorToast } = useToaster();

  const handleLogout = async () => {
    try {
      await axiosClient.post("authentication/logout");
      setUser(null);
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      navigate("/auth/login");
      showSuccessToast("You are Logged out");
    } catch (error) {
      console.error("Error logging out:", error);
      showErrorToast();
    }
  };

  const toggleSidebar = () => {
    setIsCollapsed(!isCollapsed);
  };

  const isActiveLink = (link: string): boolean => {
    if (link === "/settings") {
      return location.pathname.startsWith("/settings");
    }
    return location.pathname === link;
  };

  return (
    <Box
      w={isCollapsed ? "70px" : "200px"}
      bg={topBarColor}
      transition="width 0.5s"
    >
      
      <Flex
        flexDirection={"column"}
        justifyContent={"space-between"}
        h={"100%"}
        position={"relative"}
      >
        <VStack align={isCollapsed ? "center" : "left"} px={2} pt={5}>
          <Button
            fontSize={"xs"}
            variant={"solid"}
            //colorScheme={!isCollapsed ? "brand" : "gray"}
            position={"absolute"}
            right={-1}
            bottom={"109px"}
            justifyContent={"left"}
            onClick={toggleSidebar}
            size={"xs"}
            opacity={isCollapsed ? "0.5" : "1"}
          >
            {isCollapsed ? <SlArrowRight /> : <SlArrowLeft />}
          </Button>
          {topNavLinks.map((nav) => (
            <Button
              fontSize={isCollapsed ? "xl" : ""}
              fontWeight={"normal"}
              isActive={isActiveLink(nav.Link)}
              justifyContent={isCollapsed ? "center" : "left"}
              variant={"ghost"}
              as={Link}
              key={nav.Name}
              to={nav.Link}
              colorScheme={isActiveLink(nav.Link) ? "brand" : undefined}
              w={isCollapsed ? "40px" : "full"}
              h={"40px"}
              opacity={!isActiveLink(nav.Link) ? "0.5" : "1"}
            >
              <Flex gap={2} alignItems={"center"}>
                <nav.Icon /> {!isCollapsed && <Text>{nav.Name}</Text>}
              </Flex>
            </Button>
          ))}
        </VStack>
        <VStack
          mb={5}
          px={2}
          align={isCollapsed ? "center" : "left"}
          gap={2}
          borderTopWidth={1}
          transitionDelay={"0.3s"}
        >
          <Button
            mt={3}
            fontWeight={"normal"}
            justifyContent={isCollapsed ? "center" : "left"}
            variant={"ghost"}
            as={NavLink}
            to={"/settings"}
            isActive={isActiveLink("/settings")}
            colorScheme={isActiveLink("/settings") ? "brand" : undefined}
            w={isCollapsed ? "" : "full"}
            h={"40px"}
            opacity={!isActiveLink("/settings") ? "0.5" : "1"}
          >
            <Flex gap={2} alignItems={"center"}>
              <BiCog /> {!isCollapsed && <Text>Settings</Text>}
            </Flex>
          </Button>

          <Button
            fontWeight={"normal"}
            justifyContent={isCollapsed ? "center" : "left"}
            variant={"ghost"}
            as={NavLink}
            onClick={handleLogout}
            w={isCollapsed ? "40px" : "full"}
            h={"40px"}
            opacity={"0.5"}
          >
            <Flex gap={2} alignItems={"center"}>
              <BiLogOut /> {!isCollapsed && <Text>Log out</Text>}
            </Flex>
          </Button>
        </VStack>
      </Flex>
    </Box>
  );
}

export default NavMenu;
