import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App.tsx";
import "./index.css";
import { ChakraProvider, ColorModeScript } from "@chakra-ui/react";
import theme from "./services/theme.ts";
import { UserProvider } from "./Contexts/userContext.tsx";
import { StudentsProvider } from "./Contexts/StudentsContext.tsx";
import { ClassesProvider } from "./Contexts/ClassesContext.tsx";
import { GuardiansProvider } from "./Contexts/GuardiansContext.tsx";
import { SchoolsProvider } from "./Contexts/schoolContext.tsx";
import { DashboardsProvider } from "./Contexts/DashboardContext.tsx";
import { CombinedLoadingsProvider } from "./Contexts/CombinedLoadingContext.tsx";

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <UserProvider>
        <SchoolsProvider>
          <StudentsProvider>
            <ClassesProvider>
              <GuardiansProvider>
                <DashboardsProvider>
                  <CombinedLoadingsProvider>
                    <ColorModeScript
                      initialColorMode={theme.config.initialColorMode}
                    />
                    <App />
                  </CombinedLoadingsProvider>
                </DashboardsProvider>
              </GuardiansProvider>
            </ClassesProvider>
          </StudentsProvider>
        </SchoolsProvider>
      </UserProvider>
    </ChakraProvider>
  </React.StrictMode>
);
