import React from "react";
import { Box, Text, useColorModeValue } from "@chakra-ui/react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { ClassPopulation } from "../../../services/Dtos";
import { getShortName } from "../../../services/Utils";

interface ClassPopulationChartProps {
  classData: ClassPopulation[] | null;
}

const ClassPopulationChart: React.FC<ClassPopulationChartProps> = ({
  classData,
}) => {
  const bg = useColorModeValue("gray.100", "gray.700");
  const color = useColorModeValue("gray.800", "white");
  // Custom Tooltip Component
  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      return (
        <Box
          bg={bg}
          color={color}
          p={4}
          borderRadius="md"
          boxShadow="md"
          borderWidth={1}
          fontSize={"sm"}
        >
          <Text fontWeight="bold">{label}</Text>
          <Text>{`Population: ${payload[0].value}`}</Text>
        </Box>
      );
    }

    return null;
  };

  return (
    <Box width="100%" height="100%">
      <ResponsiveContainer width="100%" minHeight={300}>
        <BarChart
          data={classData || []}
          margin={{ top: 10, right: 0, left: -30, bottom: 0 }}
        >
          {/* Define gradient */}
          <defs>
            <linearGradient id="colorPopulation" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#00EFB5" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#00A3FF" stopOpacity={0.8} />
            </linearGradient>
          </defs>

          <XAxis
            dataKey="className"
            fontSize={"12px"}
            fontWeight={"bold"}
            interval={0}
            tickFormatter={(value) => getShortName(value)}
          />
          <YAxis
            fontSize={"12px"}
            domain={[0, "dataMax"]}
            fontWeight={"bold"}
            tickFormatter={(value) => value.toFixed(1)}
          />
          <Tooltip content={<CustomTooltip />} />
          <Bar
            dataKey="population"
            fill="url(#colorPopulation)"
            barSize={20}
            radius={[5, 5, 0, 0]}
          />
        </BarChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default ClassPopulationChart;
