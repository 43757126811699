import {
  Badge,
  Box,
  Table,
  Tbody,
  Td,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import { brandTransparentColor } from "../../../../services/constants";
import { useSchoolsContext } from "../../../../Contexts/schoolContext";
import { Term } from "../../../../services/Dtos";
import { mapSession } from "../../../../services/Utils";
import { useEffect, useState } from "react";

interface Props {
  term: Term;
  handleClick: () => void;
}

export default function TermCard({ term, handleClick }: Props) {
  const altColor = useColorModeValue("gray.100", "gray.700");
  const { school, sessions } = useSchoolsContext();

  const [, setSessionMap] = useState<{ [key: string]: string }>({});

  useEffect(() => {
    if (sessions) {
      const map = mapSession(sessions);
      setSessionMap(map);
    }
  }, [sessions]);

  return (
    <Box
      bg={altColor}
      p={3}
      borderRadius={"lg"}
      borderWidth={1}
      minW={"270px"}
      transition={"300ms"}
      _hover={{
        backgroundColor: brandTransparentColor,

        cursor: "pointer",
        transform: "scale(1.09)",
      }}
      onClick={handleClick}
      position={"relative"}
    >
      {school?.currentTermId === term.id && (
        <Badge
          fontSize={"2xs"}
          top={0}
          left={0}
          position={"absolute"}
          colorScheme="green"
        >
          Current
        </Badge>
      )}
      <Table variant={"simple"} size={"xs"} fontSize={"sm"}>
        <Tbody>
          <Tr>
            <Td>Term Name</Td>
            <Td textAlign={"right"} fontWeight={"bold"} pl={5}>
              <Badge variant={"outline"} colorScheme={"brand"}>
                {term.termName}
              </Badge>
            </Td>
          </Tr>
          <Tr>
            <Td>Order</Td>
            <Td textAlign={"right"} fontWeight={"bold"} pl={5}>
              {term.order}
            </Td>
          </Tr>
        </Tbody>
      </Table>
    </Box>
  );
}
