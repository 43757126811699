import {
  Button,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  Select,
} from "@chakra-ui/react";
import React, { ChangeEventHandler } from "react";
import { BiSearch } from "react-icons/bi";
import { useClassesContext } from "../../../Contexts/ClassesContext";
import { ArrowDownIcon } from "@chakra-ui/icons";
import { editorPolicy } from "../../../services/constants";
import { useUserContext } from "../../../Contexts/userContext";

interface Props {
  searchQuery: string;
  handleSearchChange: ChangeEventHandler<HTMLInputElement>;
  handleClassChange: ChangeEventHandler<HTMLSelectElement>;
  selectedClassId: string;
  selectedStudentName: string | undefined;
  handleSave: () => void;
  unsavedChanges: boolean;
  savingStudentResultEntries: boolean;
  handlePreview: () => void;
  validResult: boolean;
}

const ResultTopMenu: React.FC<Props> = ({
  searchQuery,
  handleSearchChange,
  handleClassChange,
  selectedClassId,
  selectedStudentName,
  handleSave,
  unsavedChanges,
  savingStudentResultEntries,
  handlePreview,
  validResult,
}: Props) => {
  const { classes } = useClassesContext();
  const {user} = useUserContext();

  return (
    <Flex
      alignItems={"center"}
      w={"full"}
      justifyContent={"space-between"}
      p={5}
      borderWidth={1}
      borderRadius="lg"
      width="100%"
      wrap={"wrap"}
      gap={3}
    >
      <Flex alignItems={"end"} gap={2} wrap={"wrap"}>
        {/* <Select
          placeholder="Select Term"
          value={selectedTermId}
          onChange={handleTermChange}
          w={"4xs"}
        >
          {terms
            ?.filter((t) => t.sessionId == selectedSessionId)
            .map((term) => (
              <option key={term.id} value={term.id}>
                {term.termName}
              </option>
            ))}
        </Select> */}
        <Select
          w={"4xs"}
          placeholder="All Classes"
          value={selectedClassId}
          onChange={handleClassChange}
        >
          {classes?.map((cls) => (
            <option key={cls.id} value={cls.id}>
              {cls.className}
            </option>
          ))}
        </Select>
        <InputGroup w={"fit-content"}>
          <InputLeftElement children={<BiSearch />} />
          <Input
            w={"4xs"}
            placeholder="Search by name"
            value={searchQuery}
            onChange={handleSearchChange}
          />
        </InputGroup>
      </Flex>
      {editorPolicy.includes(user?.role || 0) && (
        <Flex gap={5}>
        <Button
          isDisabled={!selectedStudentName || !unsavedChanges}
          onClick={handleSave}
          colorScheme="green"
          isLoading={savingStudentResultEntries}
        >
          <ArrowDownIcon />
          Save
        </Button>
        <Button onClick={handlePreview} size={"sm"} variant={"link"} isDisabled={!selectedStudentName || !validResult} >
          Preview
        </Button>
      </Flex>
      )}
    </Flex>
  );
};

export default ResultTopMenu;
