import {
  Box,
  Flex,
  useColorModeValue,
  Text,
  IconButton,
} from "@chakra-ui/react";
import { brandTransparentColor } from "../../../../services/constants";
import { Class } from "../../../../services/Dtos";
import { BiEditAlt } from "react-icons/bi";

interface Props {
  classRoom: Class;
  handleEdit: () => void;
  handleClick: () => void;
  selected: boolean;
}

export default function ClassCard({
  classRoom,
  handleEdit,
  handleClick,
  selected,
}: Props) {
  const altColor = useColorModeValue("gray.100", "gray.700");

  return (
    <Box
    my={3}
    bg={selected ? "brand.400" : altColor}
      p={3}
      borderRadius={"lg"}
      borderWidth={1}
      minW={"270px"}
      transition={"300ms"}
      _hover={{
        backgroundColor: selected ? "brand.300" : brandTransparentColor,
        cursor: "pointer",
        transform: "scale(1.02)",
      }}
      onClick={handleClick}
      position={"relative"}
    >
      <Flex justifyContent={"space-between"} alignItems={"center"}>
        <Text fontWeight={"bold"}>{classRoom.className}</Text>
        {selected && <IconButton
          size={"sm"}
          icon={<BiEditAlt />}
          aria-label={"edit class"}
          onClick={handleEdit}
        />}
      </Flex>
    </Box>
  );
}
