import "./App.css";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Layout from "./Components/Pages/Layout.tsx";
import Students from "./Components/Pages/Students/Students.tsx";
import Results from "./Components/Pages/Result/Results.tsx";
import Regiser from "./Components/Pages/Authentication/Regiser.tsx";

import ForgotPassword from "./Components/Pages/Authentication/ForgotPassword.tsx";
import NoAuthLayout from "./Components/Pages/Authentication/NoAuthLayout.tsx";
import ResetPassword from "./Components/Pages/Authentication/ResetPassword.tsx";

import PrivateRoute from "./services/PrivateRoute.tsx";
import SettingsLayout from "./Components/Pages/Settings/SettingsLayout.tsx";
import SchoolSettings from "./Components/Pages/Settings/SchoolSettings.tsx";
import SessionSettings from "./Components/Pages/Settings/SessionSettings/SessionSettings.tsx";
import TermSettings from "./Components/Pages/Settings/TermSettings/TermSettings.tsx";
import Login from "./Components/Pages/Authentication/Login.tsx";
import GradeCriteriaSettings from "./Components/Pages/Settings/GradeCriteriaSettings/GradeCriteriaSettings.tsx";
import LevelSettings from "./Components/Pages/Settings/LevelSettings/LevelSettings.tsx";
import UserSettings from "./Components/Pages/Settings/UserSettings/UserSettings.tsx";
import ClassSettings from "./Components/Pages/Settings/ClassSettings/ClassSettings.tsx";
import SubjectSettings from "./Components/Pages/Settings/SubjectSettings/SubjectSettings.tsx";
import AssessmentTypeSettings from "./Components/Pages/Settings/AssessmentTypeSettings/AssessmentTypeSettings.tsx";
import DashboardGrid from "./Components/Pages/Dashboard/DashboardGrid.tsx";
import { useUserContext } from "./Contexts/userContext.tsx";
import { adminPolicy } from "./services/constants.ts";
//import ResultPreview from "./Components/Pages/Result/ResultPreview.tsx";

function App() {
  const { user } = useUserContext();
  return (
    <>
      <Router>
        <Routes>
          {/* <Route path="resultpreview/:studentId" element={<ResultPreview />} /> */}
          <Route path="auth" element={<NoAuthLayout />}>
            <Route path="login" element={<Login />} />
            <Route path="register" element={<Regiser />} />
            <Route path="forgotpassword" element={<ForgotPassword />} />
            <Route path="resetpassword" element={<ResetPassword />} />
          </Route>
          <Route path="/" element={<PrivateRoute />}>
            <Route path="/" element={<Layout />}>
              <Route index element={<DashboardGrid />} />
              <Route path="students" element={<Students />} />
              <Route path="results" element={<Results />} />

              <Route path="settings" element={<SettingsLayout />}>
                <Route
                  index
                  element={
                    adminPolicy.includes(user?.role || 0) ? (
                      <SchoolSettings />
                    ) : (
                      <UserSettings />
                    )
                  }
                />
                <Route path="session" element={<SessionSettings />} />
                <Route path="term" element={<TermSettings />} />
                <Route path="class" element={<ClassSettings />} />
                <Route path="level" element={<LevelSettings />} />
                <Route path="subject" element={<SubjectSettings />} />
                <Route path="grade" element={<GradeCriteriaSettings />} />
                <Route path="assessment" element={<AssessmentTypeSettings />} />
                <Route path="user" element={<UserSettings />} />
              </Route>
            </Route>
          </Route>
        </Routes>
      </Router>
    </>
  );
}

export default App;
