import { ThemeConfig, extendTheme } from "@chakra-ui/react";

const config: ThemeConfig = {
    initialColorMode: 'dark',
    useSystemColorMode: false,
};

const theme = extendTheme({config,
    colors: {
      "brand": {
      "50": "#F1E6FE",
      "100": "#D8B9FD",
      "200": "#BF8DFC",
      "300": "#A660FB",
      "400": "#8D33FA",
      "500": "#7407F8",
      "600": "#5C05C7",
      "700": "#450495",
      "800": "#2E0363",
      "900": "#170132"
    },
    "gray": {
      50: '#f9f9f9',
        100: '#ededed',
        200: '#d3d3d3',
        300: '#b3b3b3',
        400: '#a0a0a0',
        500: '#898989',
        600: '#6c6c6c',
        700: '#202020',
        800: '#171717',
        900: '#111',
    }
    },
  })

  export default theme;