import {
  createContext,
  ReactNode,
  useContext,
} from "react";

import { useUserContext } from "./userContext";
import { useClassesContext } from "./ClassesContext";
import { useDashboardContext } from "./DashboardContext";
import { useSchoolsContext } from "./schoolContext";
import { useStudentsContext } from "./StudentsContext";

interface CombinedLoadingsContextType {
  combinedLoading: boolean;
}

interface Props {
  children: ReactNode;
}

const CombinedLoadingContext = createContext<
  CombinedLoadingsContextType | undefined
>(undefined);

export const CombinedLoadingsProvider = ({ children }: Props) => {
  const { isLoading: userLoading } = useUserContext();
  const { isLoading: classesLoading } = useClassesContext();
  const { isLoading: dashboardLoading } = useDashboardContext();
  const { isLoading: schoolsLoading } = useSchoolsContext();
  const { isLoading: studentsLoading } = useStudentsContext();

  // Check if any context is loading
  const combinedLoading =
    userLoading ||
    classesLoading ||
    dashboardLoading ||
    schoolsLoading ||
    studentsLoading;

  return (
    <CombinedLoadingContext.Provider
      value={{
        combinedLoading,
      }}
    >
      {children}
    </CombinedLoadingContext.Provider>
  );
};

export const useCombinedLoadingContext = () => {
  const context = useContext(CombinedLoadingContext);
  if (!context) {
    throw new Error(
      "useCombinedLoadingContext must be used within a CombinedLoadingProvider"
    );
  }
  return context;
};
