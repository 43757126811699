import React, { useState, ChangeEvent, useEffect } from "react";
import { ApiDataResponse, ApiResponse, Term } from "../../../../services/Dtos";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  Flex,
  useDisclosure,
  CircularProgress,
  VStack,
} from "@chakra-ui/react";
import { BiTrash } from "react-icons/bi";
import { validateGreaterThanZero, validateRequired } from "../../../../services/validation";
import DeleteAlertDialogModal from "../../../ui/DeleteAlertDialogModal";
import useToaster from "../../../../hooks/useToaster";

interface AddGuardianModalProps {
  isOpen: boolean;
  onClose: () => void;
  termToEdit: Term | null;
  addTerm: (term: Term) => Promise<ApiDataResponse<Term> | null>;
  deleteTerm: (termId: string) => Promise<ApiResponse | null>;
  editTerm: (term: Term, termId: string) => Promise<ApiResponse | null>;
}

const AddGuardianModal: React.FC<AddGuardianModalProps> = ({
  isOpen,
  onClose,
  termToEdit,
  addTerm,
  deleteTerm,
  editTerm,
}) => {
  const initialTermFormState: Term = {
    id: "",
    termName: "",
    isLeapTerm: false,
    schoolId: "",
    order: 0,
  };

  const { showSuccessToast, showErrorToast } = useToaster();

  const [isLoading, setIsLoading] = useState(false);

  const {
    isOpen: deleteIsOpen,
    onOpen,
    onClose: deleteOnClose,
  } = useDisclosure();

  const [termFormState, setTermFormState] =
    useState<Term>(initialTermFormState);

  useEffect(() => {
    if (termToEdit) {
      setTermFormState(termToEdit);
    } else {
      setTermFormState(initialTermFormState);
    }
  }, [termToEdit]);

  const [termFormErrors, setTermFormErrors] = useState<{
    [key: string]: string;
  }>({});

  const validateTermForm = (): boolean => {
    const currentTermFormErrors: { [key: string]: string } = {};

    if (!validateRequired(termFormState.termName)) {
      currentTermFormErrors.termName = "Term Name is required";
    }

    if (!validateGreaterThanZero(termFormState.order)) {
      currentTermFormErrors.order =
        "Term order is required";
    }

    setTermFormErrors(currentTermFormErrors);
    return Object.keys(currentTermFormErrors).length === 0;
  };

  const handleAddTerm = async () => {
    if (!validateTermForm()) {
      console.log(termFormErrors);
      return;
    }

    setIsLoading(true);

    try {
      if (termToEdit) {
        const response = await editTerm(termFormState, termToEdit.id);
        if (response?.succeeded) {
          showSuccessToast(response.message || "");
          onClose();
        } else {
          showErrorToast(response?.message || "");
          console.info("Error updating term");
        }
      } else {
        const response = await addTerm(termFormState);
        if (response?.succeeded) {
          showSuccessToast(response.message);
          setTermFormState(initialTermFormState);
          onClose();
        } else {
          showErrorToast(response?.message);
          console.info("Error adding term");
        }
      }
    } catch (error) {
      showErrorToast();
      console.error("Unexpected error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteTerm = async () => {
    if (termToEdit) {
      setIsLoading(true);
      const response = await deleteTerm(termToEdit?.id);
      if (response?.succeeded) {
        onClose();
        showSuccessToast(response?.message || "");
      } else {
        showErrorToast(response?.message || "");
      }
    }
    setIsLoading(false);
  };

  const handleTermChange = (
    event: ChangeEvent<HTMLSelectElement | HTMLInputElement>
  ) => {
    const { name, value, type } = event.target;

    if (type === "checkbox") {
      const { checked } = event.target as HTMLInputElement;
      setTermFormState((prevState) => ({
        ...prevState,
        [name]: checked,
      }));
    } else {
      setTermFormState((prevState) => ({
        ...prevState,
        [name]: name === "order" ? parseInt(value, 10) : value,
      }));
    }
  };
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick={false}
      motionPreset="slideInBottom"
      scrollBehavior={"inside"}
      size={"xl"}
    >
      <DeleteAlertDialogModal
        handleDelete={handleDeleteTerm}
        onClose={deleteOnClose}
        isOpen={deleteIsOpen}
      />
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{termToEdit ? "Edit Term" : "Add New Term"}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack gap={0} alignItems={"center"}>
            <FormControl mb={4} isRequired>
              <FormLabel>Term</FormLabel>
              <Input
                name="termName"
                value={termFormState.termName}
                placeholder="e.g (1st Term - 2020/2021)"
                onChange={handleTermChange}
                isInvalid={!!termFormErrors.termName}
              />
            </FormControl>
            <FormControl mb={4} isRequired>
              <FormLabel>Order</FormLabel>
              <Input
                type="number"
                name="order"
                value={termFormState.order}
                placeholder="Position of this assessment type"
                onChange={handleTermChange}
                isInvalid={!!termFormErrors.order}
              />
            </FormControl>
            {/* <FormControl mb={4}>
              <FormLabel>Leap Term?</FormLabel>
              <Checkbox
                isChecked={termFormState.isLeapTerm}
                name="isLeapTerm"
                id="isLeapTerm"
                onChange={handleTermChange}
              ></Checkbox>
            </FormControl> */}
          </VStack>
        </ModalBody>
        <ModalFooter alignSelf={"center"}>
          {isLoading ? (
            <CircularProgress isIndeterminate color={"brand.300"} />
          ) : (
            <Flex gap={5} justifyContent={"center"}>
              <Button
                colorScheme="brand"
                onClick={handleAddTerm}
                isLoading={isLoading}
              >
                Done
              </Button>

              {termToEdit && (
                <Button
                  isLoading={isLoading}
                  variant={"ghost"}
                  colorScheme="red"
                  leftIcon={<BiTrash />}
                  onClick={onOpen}
                >
                  Delete Term
                </Button>
              )}
            </Flex>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AddGuardianModal;
