import {
  Button,
  ButtonGroup,
  Flex,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Select,
} from "@chakra-ui/react";
import React, { ChangeEventHandler, MouseEventHandler } from "react";
import { BiPlus, BiSearch, BiSortDown, BiSortUp } from "react-icons/bi";
import { Class } from "../../../services/Dtos";
import { editorPolicy, greenTransparentColor } from "../../../services/constants";
import { useUserContext } from "../../../Contexts/userContext";

interface Props {
  searchQuery: string;
  selectedClass: string | null;
  handleSearchChange: ChangeEventHandler<HTMLInputElement>;
  handleClassChange: ChangeEventHandler<HTMLSelectElement>;
  classes: Class[] | null;
  onOpen: MouseEventHandler<HTMLButtonElement>;
  sortDate: boolean;
  handleSortDate: () => void;
}

const StudentTopMenu: React.FC<Props> = ({
  searchQuery,
  selectedClass,
  handleSearchChange,
  handleClassChange,
  classes,
  onOpen,
  sortDate,
  handleSortDate,
}: Props) => {
  const {user} = useUserContext();
  return (
    <Flex
      alignItems={"center"}
      w={"full"}
      justifyContent={"space-between"}
      p={5}
      borderWidth={1}
      borderRadius="lg"
      width="100%"
      wrap={"wrap"}
      gap={3}
    >
      <Flex alignItems={"center"} gap={2} wrap={"wrap"}>
        <InputGroup w={"fit-content"}>
          <InputLeftElement children={<BiSearch />} />
          <Input
            w={"2xs"}
            placeholder="Search by name"
            value={searchQuery}
            onChange={handleSearchChange}
          />
        </InputGroup>
        <Select
          placeholder="All Classes"
          value={selectedClass ?? ""}
          onChange={handleClassChange}
          w={"3xs"}
        >
          {classes?.map((cls) => (
            <option key={cls.id} value={cls.id}>
              {cls.className}
            </option>
          ))}
        </Select>
        <ButtonGroup isAttached onClick={handleSortDate}>
          <Button fontWeight={"sm"}>
            {sortDate ? "Last Added" : "First Added"}
          </Button>
          <IconButton
            variant={"outline"}
            isActive={sortDate}
            aria-label={"Sort By Date And Time Added"}
            icon={sortDate ? <BiSortDown /> : <BiSortUp />}
          />
        </ButtonGroup>
      </Flex>
      {editorPolicy.includes(user?.role || 0) && (
        <Flex gap={5}>
        <Button size={"sm"} variant={"link"}>
          Export CSV
        </Button>
        <Button
          onClick={onOpen}
          h={12}
          gap={2}
          leftIcon={
            <IconButton
              as={"p"}
              _hover={{
                backgroundColor: { greenTransparentColor },
              }}
              size={"sm"}
              bg={"rgba(111, 207, 151, 0.3)"}
              variant={"outline"}
              aria-label={"Add Student"}
              icon={<BiPlus />}
            />
          }
        >
          Add Student
        </Button>
      </Flex>
      )}
    </Flex>
  );
};

export default StudentTopMenu;
