import {
  Flex,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  useColorModeValue,
} from "@chakra-ui/react";
import { BiPlus } from "react-icons/bi";
import { useSchoolsContext } from "../../../../Contexts/schoolContext";
import {
  ApiDataResponse,
  ApiResponse,
  BehavioralSubject,
  Level,
  Subject,
} from "../../../../services/Dtos";
import { useState } from "react";
import Loader from "../../../ui/Loader";
import ErrorAlert from "../../../ui/ErrorAlert";
import SubjectModal from "./SubjectModal";
import HeaderDivider from "../../../ui/HeaderDivider";
import SubjectTable from "./SubjectTable";
import Empty from "../../../ui/Empty";
import { useNavigate } from "react-router-dom";
import BehavioralSubjectTable from "../BehavioralSubjectSettings/BehavioralSubjectTable";
import BehavioralSubjectModal from "../BehavioralSubjectSettings/BehavioralSubjectModal";
import useAxios from "../../../../hooks/useAxios";

function SubjectSettings() {
  const { levels, setSubjects, isLoading, error, setBehavioralSubjects } =
    useSchoolsContext();

    const axiosClient = useAxios();

  const altColor = useColorModeValue("gray.50", "gray.800");

  const [subjectToEdit, setSubjectToEdit] = useState<Subject | null>(null);
  const [subjectLevel, setSubjectLevel] = useState<Level | null>(null);

  const [behavioralSubjectToEdit, setBehavioralSubjectToEdit] =
    useState<BehavioralSubject | null>(null);
  const [behavioralSubjectLevel, setBehavioralSubjectLevel] =
    useState<Level | null>(null);

  const [openSubjectModal, setOpenSubjectModal] = useState(false);
  const [openBehavioralSubjectModal, setOpenBehavioralSubjectModal] =
    useState(false);

  const handleRefresh = () => {
    console.log("Handle Click Function not implemented.");
  };

  const navigate = useNavigate();

  const handleAddSubject = (level: Level) => {
    setSubjectToEdit(null);
    setSubjectLevel(level);
    setOpenSubjectModal(true);
    console.log(subjectToEdit);
    console.log(level);
  };

  const handleAddBehavioralSubject = (level: Level) => {
    setBehavioralSubjectToEdit(null);
    setBehavioralSubjectLevel(level);
    setOpenBehavioralSubjectModal(true);
    console.log(behavioralSubjectToEdit);
    console.log(level);
  };

  const handleEditSubject = (subject: Subject) => {
    console.log(subject);

    setSubjectToEdit(subject);
    setOpenSubjectModal(true);
  };

  const handleEditBehavioralSubject = (
    behavioralSubject: BehavioralSubject
  ) => {
    console.log(behavioralSubject);

    setBehavioralSubjectToEdit(behavioralSubject);
    setOpenBehavioralSubjectModal(true);
  };

  const addSubject = async (
    subject: Subject
  ): Promise<ApiDataResponse<Subject> | null> => {
    try {
      const response = await axiosClient.post<ApiDataResponse<Subject>>(
        "subjects",
        subject
      );
      if (response.data.succeeded) {
        setSubjects((prev) => [...prev, response.data.data]);
      }
      return response.data;
    } catch (error) {}
    return null;
  };

  const addBehavioralSubject = async (
    behavioralSubject: BehavioralSubject
  ): Promise<ApiDataResponse<BehavioralSubject> | null> => {
    try {
      const response = await axiosClient.post<
        ApiDataResponse<BehavioralSubject>
      >("behavioralSubjects", behavioralSubject);
      if (response.data.succeeded) {
        setBehavioralSubjects((prev) => [...prev, response.data.data]);
      }
      return response.data;
    } catch (error) {}
    return null;
  };

  const editSubject = async (
    subjectToEdit: Subject,
    subjectId: string
  ): Promise<ApiResponse | null> => {
    try {
      const response = await axiosClient.put<ApiResponse>(
        `subjects/${subjectId}`,
        subjectToEdit
      );
      if (response.data.succeeded) {
        setSubjects((prev) =>
          prev.map((subject) =>
            subject.id === subjectId ? subjectToEdit : subject
          )
        );
      }
      return response.data;
    } catch (error) {
      console.error("Error editing subject:", error);
    }
    return null;
  };

  const editBehavioralSubject = async (
    behavioralSubjectToEdit: BehavioralSubject,
    behavioralSubjectId: string
  ): Promise<ApiResponse | null> => {
    try {
      const response = await axiosClient.put<ApiResponse>(
        `behavioralSubjects/${behavioralSubjectId}`,
        behavioralSubjectToEdit
      );
      if (response.data.succeeded) {
        setBehavioralSubjects((prev) =>
          prev.map((behavioralSubject) =>
            behavioralSubject.id === behavioralSubjectId
              ? behavioralSubjectToEdit
              : behavioralSubject
          )
        );
      }
      return response.data;
    } catch (error) {
      console.error("Error editing behavioralSubject:", error);
    }
    return null;
  };

  const deleteSubject = async (
    subjectId: string
  ): Promise<ApiResponse | null> => {
    try {
      const response = await axiosClient.delete<ApiResponse>(
        `subjects/${subjectId}`
      );
      if (response.data.succeeded) {
        setSubjects((prev) =>
          prev.filter((subject) => subject.id !== subjectId)
        );
      }
      return response.data;
    } catch (error) {
      console.error("Error deleting subject:", error);
    }
    return null;
  };

  const deleteBehavioralSubject = async (
    behavioralSubjectId: string
  ): Promise<ApiResponse | null> => {
    try {
      const response = await axiosClient.delete<ApiResponse>(
        `behavioralSubjects/${behavioralSubjectId}`
      );
      if (response.data.succeeded) {
        setBehavioralSubjects((prev) =>
          prev.filter(
            (behavioralSubject) => behavioralSubject.id !== behavioralSubjectId
          )
        );
      }
      return response.data;
    } catch (error) {
      console.error("Error deleting behavioralSubject:", error);
    }
    return null;
  };

  if (isLoading) {
    return <Loader />;
  }

  if (error) {
    return <ErrorAlert message={error} handleClick={handleRefresh} />;
  }

  return (
    <Flex flexDirection={"column"} h={"100%"} p={5}>
      {levels.length > 0 ? (
        <Flex flexDir={"column"} w={"full"}>
          <SubjectModal
            isOpen={openSubjectModal}
            onClose={() => setOpenSubjectModal(false)}
            subjectToEdit={subjectToEdit}
            subjectLevel={subjectLevel}
            addSubject={addSubject}
            deleteSubject={deleteSubject}
            editSubject={editSubject}
          />
          <BehavioralSubjectModal
            isOpen={openBehavioralSubjectModal}
            onClose={() => setOpenBehavioralSubjectModal(false)}
            behavioralSubjectToEdit={behavioralSubjectToEdit}
            behavioralSubjectLevel={behavioralSubjectLevel}
            addBehavioralSubject={addBehavioralSubject}
            deleteBehavioralSubject={deleteBehavioralSubject}
            editBehavioralSubject={editBehavioralSubject}
          />
          <Tabs variant="enclosed" colorScheme="green">
            <TabList>
              {levels.map((level) => (
                <Tab key={level.id}>{level.levelName}</Tab>
              ))}
            </TabList>
            <TabPanels>
              {levels.map((level) => (
                <TabPanel key={level.id}>
                  <Flex
                    wrap={"wrap"}
                    borderWidth={1}
                    mb={10}
                    p={10}
                    borderRadius={"lg"}
                    gap={40}
                    flex={1}
                    bg={altColor}
                    justifyContent={"flex-start"}
                    alignItems={"start"}
                  >
                    <Flex w={"full"} flexDir={"column"}>
                      <HeaderDivider
                        heading={`${level.levelName} Cognitive Subjects`}
                        hasButton={true}
                        handleButtonClick={() => handleAddSubject(level)}
                        icon={<BiPlus />}
                        colorScheme={"green"}
                      />
                      <SubjectTable
                        handleEditSubject={handleEditSubject}
                        level={level}
                      />
                    </Flex>

                    <Flex key={level.id} w={"full"} flexDir={"column"}>
                      <HeaderDivider
                        heading={`${level.levelName} Behavioral Subjects`}
                        hasButton={true}
                        handleButtonClick={() =>
                          handleAddBehavioralSubject(level)
                        }
                        icon={<BiPlus />}
                        colorScheme={"green"}
                      />
                      <BehavioralSubjectTable
                        handleEditBehavioralSubject={
                          handleEditBehavioralSubject
                        }
                        level={level}
                      />
                    </Flex>
                  </Flex>
                </TabPanel>
              ))}
            </TabPanels>
          </Tabs>
        </Flex>
      ) : (
        <Empty
          hasButton
          message="Something will show up here when you set up School Levels"
          buttonText="Go to Levels Settings"
          handleClick={() => navigate("/settings/level")}
        />
      )}
    </Flex>
  );
}

export default SubjectSettings;
