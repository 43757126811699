import {
  Flex,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";

import { useSchoolsContext } from "../../../../Contexts/schoolContext";

import {
  ApiDataResponse,
  ApiResponse,
  AssessmentType,
  Level,
} from "../../../../services/Dtos";
import { useState } from "react";
import Loader from "../../../ui/Loader";
import ErrorAlert from "../../../ui/ErrorAlert";
import AssessmentTypeModal from "./AssessmentTypeModal";
import { BiPlus } from "react-icons/bi";
import HeaderDivider from "../../../ui/HeaderDivider";
import AssessmentTypeCard from "./AssessmentTypeCard";
import Empty from "../../../ui/Empty";
import { useNavigate } from "react-router-dom";
import useAxios from "../../../../hooks/useAxios";

function AssessmentTypeSettings() {
  const axiosClient = useAxios();
  const { assessmentTypes, setAssessmentTypes, levels, isLoading, error } =
    useSchoolsContext();

  const [assessmentTypeToEdit, setAssessmentTypeToEdit] =
    useState<AssessmentType | null>(null);

  const { onOpen, isOpen, onClose } = useDisclosure();

  const [assessmentLevel, setAssessmentLevel] = useState<Level | null>(null);

  const altColor = useColorModeValue("gray.50", "gray.800");

  const navigate = useNavigate();

  const handleRefresh = () => {
    console.log("Handle Click Function not implemented.");
  };

  const handleAddAssessmentType = (level: Level) => {
    setAssessmentTypeToEdit(null);
    setAssessmentLevel(level);
    onOpen();
    console.log(assessmentTypeToEdit);
  };

  const handleEditAssessmentType = (assessmentType: AssessmentType) => {
    console.log(assessmentType);

    setAssessmentTypeToEdit(assessmentType);
    onOpen();
  };

  const addAssessmentType = async (
    assessmentType: AssessmentType
  ): Promise<ApiDataResponse<AssessmentType> | null> => {
    try {
      const response = await axiosClient.post<ApiDataResponse<AssessmentType>>(
        "assessmentTypes",
        assessmentType
      );
      if (response.data.succeeded) {
        setAssessmentTypes((prev) => [...prev, response.data.data]);
      }
      return response.data;
    } catch (error) {}
    return null;
  };

  const editAssessmentType = async (
    assessmentTypeToEdit: AssessmentType,
    assessmentTypeId: string
  ): Promise<ApiResponse | null> => {
    try {
      const response = await axiosClient.put<ApiResponse>(
        `assessmentTypes/${assessmentTypeId}`,
        assessmentTypeToEdit
      );
      if (response.data.succeeded) {
        setAssessmentTypes((prev) =>
          prev.map((assessmentType) =>
            assessmentType.id === assessmentTypeId
              ? assessmentTypeToEdit
              : assessmentType
          )
        );
      }
      return response.data;
    } catch (error) {
      console.error("Error editing assessmentType:", error);
    }
    return null;
  };

  const deleteAssessmentType = async (
    assessmentTypeId: string
  ): Promise<ApiResponse | null> => {
    try {
      const response = await axiosClient.delete<ApiResponse>(
        `assessmentTypes/${assessmentTypeId}`
      );
      if (response.data.succeeded) {
        setAssessmentTypes((prev) =>
          prev.filter(
            (assessmentType) => assessmentType.id !== assessmentTypeId
          )
        );
      }
      return response.data;
    } catch (error) {
      console.error("Error deleting assessmentType:", error);
    }
    return null;
  };

  if (isLoading) {
    return <Loader />;
  }

  if (error) {
    return <ErrorAlert message={error} handleClick={handleRefresh} />;
  }

  return (
    <Flex flexDirection={"column"} p={5} h={"full"}>
      {levels.length > 0 ? (
        <Flex flexDir={"column"} w={"full"}>
          <AssessmentTypeModal
            assessmentLevel={assessmentLevel}
            isOpen={isOpen}
            onClose={onClose}
            assessmentTypeToEdit={assessmentTypeToEdit}
            addAssessmentType={addAssessmentType}
            deleteAssessmentType={deleteAssessmentType}
            editAssessmentType={editAssessmentType}
          />
          <Tabs variant="enclosed" colorScheme="green">
            <TabList>
              {levels.map((level) => (
                <Tab key={level.id}>
                  <Text>{level.levelName}</Text>{" "}
                  {/*  <IconButton size={"sm"} colorScheme="green" onClick={handleAddAssessmentType} as={BiPlus} aria-label={"add assessment type"} /> */}
                </Tab>
              ))}
            </TabList>
            <TabPanels>
              {levels.map((level) => (
                <TabPanel key={level.id}>
                  <Flex
                    borderWidth={1}
                    mb={10}
                    p={10}
                    borderRadius={"lg"}
                    gap={40}
                    flex={1}
                    bg={altColor}
                    justifyContent={"flex-start"}
                    alignItems={"start"}
                  >
                    <Flex w={"full"} flexDir={"column"}>
                      <HeaderDivider
                        heading={`${level.levelName} Assessment Types`}
                        hasButton={true}
                        handleButtonClick={() => handleAddAssessmentType(level)}
                        icon={<BiPlus />}
                        colorScheme={"green"}
                      />

                      <Stack gap={5}>
                        {assessmentTypes
                          .filter((a) => a.levelId === level.id)
                          .map((s) => (
                            <AssessmentTypeCard
                              key={s.id}
                              assessmentType={s}
                              handleClick={() => handleEditAssessmentType(s)}
                            />
                          ))}
                      </Stack>
                    </Flex>
                  </Flex>
                </TabPanel>
              ))}
            </TabPanels>
          </Tabs>
        </Flex>
      ) : (
        <Empty
          hasButton
          message="Something will show up here when you set up School Levels"
          buttonText="Go to Levels Settings"
          handleClick={() => navigate("/settings/level")}
        />
      )}
      {/* <AssessmentTypeModal
        isOpen={isOpen}
        onClose={onClose}
        assessmentTypeToEdit={assessmentTypeToEdit}
        addAssessmentType={addAssessmentType}
        deleteAssessmentType={deleteAssessmentType}
        editAssessmentType={editAssessmentType}
      />
      <HeaderDivider
        heading={"School Assessment Types"}
        hasButton={true}
        handleButtonClick={handleAddAssessmentType}
        icon={<BiPlus />}
        colorScheme={"green"}
      />
      <Flex gap={5} wrap={"wrap"} mb={20}>
        {assessmentTypes.map((s) => (
          <AssessmentTypeCard
          
            key={s.id}
            assessmentType={s}
            handleClick={() => handleEditAssessmentType(s)}
          />
        ))}
      </Flex> */}
    </Flex>
  );
}

export default AssessmentTypeSettings;
