import {
  Box,
  Button,
  Flex,
  Heading,
  Input,
  useColorModeValue,
  Text,
  IconButton,
} from "@chakra-ui/react";
import { useState } from "react";
import useToaster from "../../../hooks/useToaster";
import { DeleteIcon } from "@chakra-ui/icons";
import { BiReset } from "react-icons/bi";
import { ApiResponse } from "../../../services/Dtos";

interface Props {
  color: string;
  handleSave: (media: string) => Promise<ApiResponse | null>;
  handleReset: () => Promise<ApiResponse | null>;
  successMessage: string;
  errorMessage: string;
  heading: string;
  body: string;
  buttonText: string;
}

export default function ColorSetting({
  color,
  handleSave,
  handleReset,
  heading,
  body,
  buttonText,
}: Props) {
  const [tempColor, setTempColor] = useState<string>(color || "");
  const altColor = useColorModeValue("gray.100", "gray.700");
  const [inColorLoading, setInColorLoading] = useState<boolean>(false);
  const { showSuccessToast, showErrorToast } = useToaster();

  const onColorChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    console.log(e.target.value);
    console.log("color of school", color);
    setTempColor(e.target.value);
  };

  const onSave = async () => {
    setInColorLoading(true);
    const response = await handleSave(tempColor);

    if (response?.succeeded) {
      showSuccessToast(response.message || "");
    } else {
      showErrorToast(response?.message || "");
    }
    setInColorLoading(false);
  };

  const onReset = async () => {
    setInColorLoading(true);
    const response = await handleReset();

    if (response?.succeeded) {
      setTempColor("");
      showSuccessToast(response.message || "");
    } else {
      showErrorToast(response?.message || "");
    }
    setInColorLoading(false);
  };

  return (
    <Box
      bg={altColor}
      p={3}
      borderRadius={"lg"}
      borderWidth={1}
      maxW={"600px"}
      minW={"300px"}
      my={5}
    >
      <Flex justifyContent={"space-between"} gap={5}>
        <Flex
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={"center"}
          gap={3}
        >
          <Input
            w={"140px"}
            type="color"
            value={tempColor || color}
            onChange={onColorChange}
          />
          <Flex gap={3}>
            <IconButton
              aria-label={heading || "edit Image"}
              icon={<BiReset />}
              size={"sm"}
              onClick={() => setTempColor("")}
              borderWidth={1}
              bg={altColor}
              sx={{
                opacity: 0.7,
                _hover: {
                  opacity: 0.9, // Change background color on hover
                  color: "white", // Change text color on hover
                },
              }}
            />

            <IconButton
              aria-label="Delete image"
              icon={<DeleteIcon />}
              size={"sm"}
              isLoading={inColorLoading}
              onClick={onReset}
              borderWidth={1}
              bg={altColor}
              sx={{
                opacity: 0.7,
                _hover: {
                  opacity: 0.9, // Change background color on hover
                  color: "white", // Change text color on hover
                },
              }}
            />
          </Flex>
          
        </Flex>

        <Flex
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={"start"}
          flex={1}
          gap={3}
        >
          <Heading size={"sm"}>{heading}</Heading>
          <Text fontSize={"sm"}>{body}</Text>
          <Button
            colorScheme="brand"
            variant={"outline"}
            isLoading={inColorLoading}
            onClick={() => onSave()}
          >
            {buttonText || "Save"}
          </Button>
        </Flex>
      </Flex>
    </Box>
  );
}
