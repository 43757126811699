import { Flex, Table, Tbody, Tr, Td, IconButton } from "@chakra-ui/react";
import { BiEdit } from "react-icons/bi";
import { useSchoolsContext } from "../../../../Contexts/schoolContext";
import { Level, Subject } from "../../../../services/Dtos";

interface Props {
  handleEditSubject: (subject: Subject) => void;
  level: Level;
}

export default function SubjectTable({ handleEditSubject, level }: Props) {
  const { subjects } = useSchoolsContext();
  return (
    <Flex gap={5} wrap={"wrap"} maxW={"400px"}>
      <Table >
        <Tbody>
          {subjects
            .filter((sub) => sub.levelId === level.id)
            .map((s) => (
              <Tr key={s.id}>
                <Td>{s.subjectName}</Td>
                <Td textAlign={"right"}>
                  <IconButton
                    size={"sm"}
                    icon={<BiEdit />}
                    onClick={() => handleEditSubject(s)}
                    aria-label={"edit subject"}
                  />
                </Td>
              </Tr>
            ))}
        </Tbody>
      </Table>
    </Flex>
  );
}
