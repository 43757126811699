import {
  Text,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Stack,
} from "@chakra-ui/react";
import { Student } from "../../../services/Dtos";
import {
  genderMap,
  tableRowHoverColor,
  tableSelectedRowColor,
} from "../../../services/constants";
import Empty from "../../ui/Empty";
import { useNavigate } from "react-router-dom";

interface Props {
  students: Student[] | null;
  chosenStudent: Student | null;
  handleRowClick: (student: Student) => void;
  selectedClassId: string;
}

export default function ResultStudentsTable({
  students,
  chosenStudent,
  handleRowClick,
  selectedClassId: selectedClass,
}: Props) {
  const tHeadColor = useColorModeValue("gray.50", "gray.900");
  const navigate = useNavigate();
  if (!selectedClass) {
    return <Empty message="No class selected" />;
  }

  if (!students?.length) {
    return (
      <Empty
        hidePicture
        message="Start by adding students to this class"
        hasButton
        buttonText="Go to Students"
        handleClick={() => navigate("/students")}
      />
    );
  }

  return (
    <Table variant="simple" borderRadius={5} >
      <Thead position={"sticky"} top={"0"} bg={tHeadColor} zIndex={2}>
        <Tr>
          <Th>Students</Th>
         
          
        </Tr>
      </Thead>
      <Tbody>
        {students &&
          students.map((s) => (
            <Tr
              key={s.id}
              _hover={{
                backgroundColor:
                  chosenStudent?.id === s.id
                    ? tableSelectedRowColor
                    : tableRowHoverColor,
              }}
              backgroundColor={
                chosenStudent?.id === s.id
                  ? tableSelectedRowColor
                  : "transparent"
              }
              cursor="pointer"
              onClick={() => handleRowClick(s)}
            >
              <Td>
                <Stack>
                  {/* <Avatar src={s.avatarUrl} size={"sm"} /> */}
                  <Text>{`${s.firstName} ${s.middleName} ${s.lastName}`}</Text>
                  <Text opacity={"0.6"} fontSize={"xs"}>{`${s.publicId} • ${genderMap[s.gender]}`}</Text>
                </Stack>
              </Td>
              
              
            </Tr>
          ))}
      </Tbody>
    </Table>
  );
}
