import React, { useState, useEffect } from "react";
import { Box, Button, Flex } from "@chakra-ui/react";
import { useSchoolsContext } from "../../../Contexts/schoolContext";
import TextSetting from "./TextSetting";
import { ApiDataResponse, ApiResponse } from "../../../services/Dtos";
import ColorSetting from "./ColorSetting";
import { ImageUpload } from "../../ui/ImageUpload";
import ErrorAlert from "../../ui/ErrorAlert";
import Loader from "../../ui/Loader";
import HeaderDivider from "../../ui/HeaderDivider";
import useToaster from "../../../hooks/useToaster";
import { getInitials } from "../../../services/Utils";
import useAxios from "../../../hooks/useAxios";

const SchoolSettings: React.FC = () => {
  const { school, setSchool, isLoading, error } = useSchoolsContext();
  const { showSuccessToast, showErrorToast } = useToaster();
  const [cover, setCover] = useState<string | null>(null);
  const [schoolLogo, setSchoolLogo] = useState<string | null>(null);
  const [signature, setSignature] = useState<string | null>(null);
  const axiosClient = useAxios();

  // const handleCoverPhotoChange = (
  //   event: React.ChangeEvent<HTMLInputElement>
  // ) => {
  //   if (event.target.files && event.target.files[0]) {
  //     const file = event.target.files[0];
  //     setSchool((prevSchool) => {
  //       if (prevSchool) {
  //         return {
  //           ...prevSchool,
  //           coverPhoto: URL.createObjectURL(file),
  //         };
  //       }
  //       return prevSchool;
  //     });
  //     // You may want to handle the file upload to your server here
  //   }
  // };

  const handleGeneralSettingsSubmit = (property: string, value: string) => {
    setSchool((prevSchool) =>
      prevSchool ? { ...prevSchool, [property]: value } : prevSchool
    );
  };

  const handleGeneralSettingsSave = async () => {
    try {
      const response = await axiosClient.put<ApiResponse>(`schools`, school);
      console.log(response.data);
      if (response.data.succeeded) {
        showSuccessToast(response.data.message || "");
      } else {
        showErrorToast(response.data.message || "");
      }
    } catch (err) {}
  };

  const handleColorUpload = async (
    schoolColor: string
  ): Promise<ApiResponse | null> => {
    console.log(schoolColor);
    if (!schoolColor) return null;

    try {
      const response = await axiosClient.put<ApiResponse>("schools", {
        accentColor: schoolColor,
      });
      console.log(response.data);
      if (response.data.succeeded) {
        setSchool((prevSchool) =>
          prevSchool
            ? { ...prevSchool, ["accentColor"]: schoolColor }
            : prevSchool
        );
      }
      return response.data;
    } catch (error) {
      console.error("Upload failed:", error);
    }
    return null;
  };

  const handleColorReset = async (): Promise<ApiResponse | null> => {
    try {
      const response = await axiosClient.put<ApiResponse>("schools", {
        accentColor: "",
      });
      console.log(response.data);
      if (response.data.succeeded) {
        setSchool((prevSchool) =>
          prevSchool ? { ...prevSchool, ["accentColor"]: "" } : prevSchool
        );
      }
      return response.data;
    } catch (error) {
      console.error("Reset failed:", error);
    }
    return null;
  };

  const handleImageUpload = async (
    image: string | null,
    type: string,
    endpoint: string
  ): Promise<ApiResponse | null> => {
    console.log(image);
    if (!image) return null;

    const blob = await fetch(image).then((res) => res.blob());
    const formData = new FormData();
    formData.append("file", blob);

    console.log(blob);

    try {
      const response = await axiosClient.post<ApiDataResponse<string>>(
        endpoint,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log(response.data);
      if (response.data.succeeded) {
        setSchool((prevSchool) =>
          prevSchool
            ? { ...prevSchool, [type]: response.data.data }
            : prevSchool
        );
      }
      return response.data;
    } catch (error) {
      console.error("Upload failed:", error);
    }
    return null;
  };

  const handleDelete = async (type: string, deleteEndpoint: string) => {
    try {
      const response = await axiosClient.delete<ApiResponse>(deleteEndpoint);
      console.log(response.data);
      if (response.data.succeeded) {
        setSchool((prevSchool) =>
          prevSchool ? { ...prevSchool, [type]: "" } : prevSchool
        );
      }
      return response.data;
    } catch (error) {
      console.error("delete failed:", error);
    }
    return null;
  };

  useEffect(() => {
    const log = () => {
      console.log(school);
    };
    log();
  }, [school]);

  if (isLoading) {
    return <Loader />;
  }

  if (error) {
    console.log(error);
    return (
      <ErrorAlert message={error} handleClick={() => console.log(error)} />
    );
  }

  return (
    <Flex flexDirection="column" p={5} flex={1}>
      <HeaderDivider heading={"General"} />
      <Flex
        alignItems={"start"}
        flexDirection={"column"}
        borderRadius={"lg"}
        gap={5}
        mb={20}
      >
        <TextSetting
          handleSubmit={handleGeneralSettingsSubmit}
          label="School Name"
          property="name"
          defaultValue={school?.name}
        />
        <TextSetting
          handleSubmit={handleGeneralSettingsSubmit}
          label="School Address"
          property="address"
          defaultValue={school?.address}
        />
        <TextSetting
          handleSubmit={handleGeneralSettingsSubmit}
          label="School Phone Number"
          property="phoneNumber"
          defaultValue={school?.phoneNumber}
        />
        <TextSetting
          handleSubmit={handleGeneralSettingsSubmit}
          label="School Website"
          property="website"
          defaultValue={school?.website}
        />
        <TextSetting
          handleSubmit={handleGeneralSettingsSubmit}
          label="School Email"
          property="email"
          defaultValue={school?.email}
        />
        <TextSetting
          handleSubmit={handleGeneralSettingsSubmit}
          label="School Slogan or Footnote"
          property="footnote"
          defaultValue={school?.footnote}
        />
        <TextSetting
          handleSubmit={handleGeneralSettingsSubmit}
          label="Student ID Prefix"
          property="studentIdPrefix"
          defaultValue={school?.studentIdPrefix}
          placeHolder={`${getInitials(school?.name || "")}/{YEAR}`}
          body="Use {YEAR} - for current year, {MONTH} - for current month, {DAY} - for the current day, {SESSION} - for the current session and {TERM} - for the current term."
        />
        <TextSetting
          handleSubmit={handleGeneralSettingsSubmit}
          label="Result ID Prefix"
          property="resultIdPrefix"
          defaultValue={school?.resultIdPrefix}
          placeHolder=""
          body={
            "Use {YEAR} - for current year, {MONTH} - for current month, {DAY} - for the current day, {SESSION} - for the current session and {TERM} - for the current term."
          }
        />
        <Button colorScheme="brand" onClick={handleGeneralSettingsSave}>
          Save Settings
        </Button>
      </Flex>
      <HeaderDivider heading={"Branding"} />
      <Box>
        <ColorSetting
          color={school?.accentColor || ""}
          handleSave={handleColorUpload}
          handleReset={handleColorReset}
          successMessage={""}
          errorMessage={""}
          heading={"School Accent Color"}
          body={
            "Please select an accent color that complements your school brand."
          }
          buttonText={""}
        />
        <ImageUpload
          handleDelete={() => handleDelete("avatar", "schools/deleteavatar")}
          image={school?.avatar ?? null}
          handleUpload={() =>
            handleImageUpload(schoolLogo, "avatar", "schools/uploadavatar")
          }
          successMessage=""
          errorMessage=""
          heading="School Logo"
          body="Please upload an image to serve as your school’s logo. This logo
            will be prominently displayed through the app for your school
            branding and result printouts."
          buttonText=""
          media={schoolLogo}
          setMedia={setSchoolLogo}
        />
        <ImageUpload
          handleDelete={() =>
            handleDelete("coverPhoto", "schools/deletecoverphoto")
          }
          image={school?.coverPhoto ?? null}
          handleUpload={() =>
            handleImageUpload(cover, "coverPhoto", "schools/uploadcoverphoto")
          }
          successMessage=""
          errorMessage=""
          heading="School Cover Photo"
          body="Upload a cover photo that will be prominently displayed throughout the app for your school branding."
          buttonText=""
          media={cover}
          setMedia={setCover}
        />
        <ImageUpload
          handleDelete={() =>
            handleDelete("adminSignature", "schools/deletesignature")
          }
          image={school?.adminSignature ?? null}
          handleUpload={() =>
            handleImageUpload(
              signature,
              "adminSignature",
              "schools/uploadsignature"
            )
          }
          successMessage=""
          errorMessage=""
          heading="Admin Signature"
          body="Upload a signature that will appear on PDF result printouts. Ensure the signature is clear and high-quality for the best representation."
          buttonText=""
          media={signature}
          setMedia={setSignature}
        />
      </Box>
    </Flex>
  );
};

export default SchoolSettings;
