import { Divider, Flex, Heading, IconButton } from "@chakra-ui/react";
import { JSXElementConstructor, ReactElement } from "react";

interface Props {
  hasButton?: boolean;
  heading: string;
  handleButtonClick?: () => void | undefined;
  icon?: ReactElement<any, string | JSXElementConstructor<any>> | undefined;
  colorScheme?: string;
}

export default function HeaderDivider({
  hasButton,
  heading,
  handleButtonClick,
  icon,
  colorScheme,
}: Props) {
  return (
    <Flex gap={5} alignItems={"center"} height="full" mb={5}>
      <Heading whiteSpace="nowrap" fontSize={"xl"}>{heading}</Heading>
      {hasButton && (
        <IconButton
          colorScheme={colorScheme}
          as={"p"}
          size={"sm"}
          aria-label={heading}
          icon={icon}
          onClick={handleButtonClick}
        />
      )}
      <Divider />
    </Flex>
  );
}
