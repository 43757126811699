import LoginForm from "../../forms/LoginForm";

function Login() {
  return (
    <>
      <LoginForm />
    </>
  );
}

export default Login;
