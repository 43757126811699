import React, { useCallback, useState, useRef, Dispatch } from "react";
import {
  Box,
  Button,
  IconButton,
  Image,
  Input,
  Text,
  useColorModeValue,
  Flex,
  Heading,
} from "@chakra-ui/react";
import { EditIcon, DeleteIcon } from "@chakra-ui/icons";
import useToaster from "../../hooks/useToaster";
import { ApiResponse } from "../../services/Dtos";

interface Props {
  image: string | null;
  handleUpload: () => Promise<ApiResponse | null>;
  handleDelete: () => Promise<ApiResponse | null>;
  successMessage: string;
  errorMessage: string;
  heading: string;
  body: string;
  buttonText: string;
  media: string | null;
  setMedia: Dispatch<React.SetStateAction<string | null>>
}

export function ImageUpload({
  image,
  handleUpload,
  handleDelete,
  heading,
  body,
  buttonText,
  media,
  setMedia,
}: Props) {

  const [loading, setLoading] = useState<boolean>(false);
  const { showSuccessToast, showErrorToast } = useToaster();
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const onFileChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files && event.target.files[0];

      if (file) {
        const reader = new FileReader();
        reader.onload = () => {
          setMedia(reader.result as string);
        };
        reader.readAsDataURL(file);
      }
    },
    []
  );

  const altColor = useColorModeValue("gray.100", "gray.700");

  const handleUploadClick = async () => {
    if (media) {
      setLoading(true);
      const response = await handleUpload();

      if (response?.succeeded) {
        showSuccessToast(response.message || "");
      } else {
        showErrorToast(response?.message || "");
      }
      setLoading(false);
    }
  };

  const handleDeleteClick = async () => {
    if (media) {
      setMedia(null); // Remove the preview image and show the image from the database
    } else if (image) {
      setLoading(true);
      const response = await handleDelete(); // Send a request to delete the image from the server

      if (response?.succeeded) {
        showSuccessToast(response.message || "");
      } else {
        showErrorToast(response?.message || "");
      }
      setLoading(false);
    }
  };

  return (
    <Box
      bg={altColor}
      p={3}
      borderRadius={"lg"}
      borderWidth={1}
      maxW={"600px"}
      minW={"300px"}
      my={5}
    >
      <Flex justifyContent={"space-between"} gap={5}>
        <Box position="relative" display="inline-block" w={"25%"}>
          {media || image ? (
            <Image
              bg={"gray.100"}
              src={media ?? image ?? undefined}
              alt={heading}
              boxSize="150px"
              aspectRatio={1}
              objectFit="cover"
              borderRadius="xl"
            />
          ) : (
            <Box
              boxSize="150px"
              borderRadius="xl"
              borderWidth="1px"
              borderColor="gray.200"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Text>No Image</Text>
            </Box>
          )}
          <IconButton
            aria-label={heading || "edit Image"}
            icon={<EditIcon />}
            position="absolute"
            top="1"
            right="1"
            size={"sm"}
            onClick={() => fileInputRef.current?.click()}
            borderWidth={1}
            bg={altColor}
            sx={{
              opacity: 0.7,
              _hover: {
                opacity: 0.9,
                color: "white",
              },
            }}
          />
          {(media || image) && (
            <IconButton
              aria-label="Delete image"
              icon={<DeleteIcon />}
              position="absolute"
              top="1"
              right="10"
              size={"sm"}
              onClick={handleDeleteClick}
              borderWidth={1}
              bg={altColor}
              sx={{
                opacity: 0.7,
                _hover: {
                  opacity: 0.9,
                  color: "white",
                },
              }}
            />
          )}
        </Box>
        <Input
          ref={fileInputRef}
          id="fileInput"
          type="file"
          accept=".jpg,.jpeg,.png"
          onChange={onFileChange}
          style={{ display: "none" }}
        />
        <Flex
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={"start"}
          flex={1}
          gap={3}
        >
          <Heading size={"sm"}>{heading}</Heading>
          <Text fontSize={"sm"}>{body}</Text>
          <Button
            colorScheme="brand"
            variant={"outline"}
            isLoading={loading}
            onClick={handleUploadClick}
          >
            {buttonText || "Save"}
          </Button>
        </Flex>
      </Flex>
    </Box>
  );
}