import React, { useState, ChangeEvent, useEffect } from "react";
import { ApiResponse, Class, Level } from "../../../../services/Dtos";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  Flex,
  useDisclosure,
  CircularProgress,
  VStack,
  Select,
} from "@chakra-ui/react";
import { BiTrash } from "react-icons/bi";
import {
  validateGreaterThanZero,
  validateRequired,
} from "../../../../services/validation";
import DeleteAlertDialogModal from "../../../ui/DeleteAlertDialogModal";
import useToaster from "../../../../hooks/useToaster";
import { useSchoolsContext } from "../../../../Contexts/schoolContext";

interface ClassRoomModalProps {
  isOpen: boolean;
  onClose: () => void;
  classRoomToEdit: Class | null;
  addClass: (classRoom: Class) => Promise<ApiResponse | null>;
  deleteClass: (classRoomId: string) => Promise<ApiResponse | null>;
  editClass: (
    classRoom: Class,
    classRoomId: string
  ) => Promise<ApiResponse | null>;
  classLevel: Level | null;
}

const ClassRoomModal: React.FC<ClassRoomModalProps> = ({
  isOpen,
  onClose,
  classRoomToEdit,
  addClass,
  deleteClass,
  editClass,
  classLevel,
}) => {
  const initialClassFormState: Class = {
    id: "",
    className: "",
    levelId: classLevel?.id || "",
    subjects: [],
    behavioralSubjects: [],
    order: 0,
    formTeacherId: "",
  };
  const { showSuccessToast, showErrorToast } = useToaster();
  const { levels } = useSchoolsContext();
  const { users } = useSchoolsContext();
  const [isLoading, setIsLoading] = useState(false);

  const {
    isOpen: deleteIsOpen,
    onOpen,
    onClose: deleteOnClose,
  } = useDisclosure();

  const [classRoomFormState, setClassFormState] = useState<Class>(
    initialClassFormState
  );

  useEffect(() => {
    if (classRoomToEdit) {
      setClassFormState(classRoomToEdit);
    } else {
      setClassFormState(initialClassFormState);
    }
  }, [classRoomToEdit, classLevel]);

  const [classRoomFormErrors, setClassFormErrors] = useState<{
    [key: string]: string;
  }>({});

  const validateClassForm = (): boolean => {
    const currentClassFormErrors: { [key: string]: string } = {};

    if (!validateRequired(classRoomFormState.className)) {
      currentClassFormErrors.className = "Class name is required";
    }
    if (!validateRequired(classRoomFormState.levelId)) {
      currentClassFormErrors.levelId = "Class level is required";
    }
    if (!validateGreaterThanZero(classRoomFormState.order)) {
      currentClassFormErrors.order = "Order is required";
    }

    setClassFormErrors(currentClassFormErrors);
    return Object.keys(currentClassFormErrors).length === 0;
  };

  const handleAddClass = async () => {
    if (!validateClassForm()) {
      console.log(classRoomFormErrors);
      return;
    }

    setIsLoading(true);

    try {
      if (classRoomToEdit) {
        const response = await editClass(
          classRoomFormState,
          classRoomToEdit.id
        );
        if (response?.succeeded) {
          showSuccessToast(response?.message || "");
          onClose();
        } else {
          showErrorToast(response?.message || "");
          console.info("Error updating classRoom");
        }
      } else {
        const response = await addClass(classRoomFormState);
        if (response?.succeeded) {
          showSuccessToast(response?.message || "");
          setClassFormState(initialClassFormState);
          onClose();
        } else {
          showErrorToast(response?.message || "");
          console.info("Error adding classRoom");
        }
      }
    } catch (error) {
      showErrorToast();
      console.error("Unexpected error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteClass = async () => {
    if (classRoomToEdit) {
      setIsLoading(true);
      const response = await deleteClass(classRoomToEdit?.id);
      if (response?.succeeded) {
        onClose();
        showSuccessToast(response?.message || "");
      } else {
        showErrorToast(response?.message || "");
      }
    }
    setIsLoading(false);
  };

  const handleClassChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value, type } = e.target;

    if (type === "checkbox") {
      const { checked } = e.target as HTMLInputElement;
      setClassFormState((prevState) => ({
        ...prevState,
        [name]: checked,
      }));
    } else {
      setClassFormState((prevState) => ({
        ...prevState,
        [name]: name === "order" ? parseInt(value, 10) : value,
      }));
    }

    console.log(classRoomFormState);
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick={false}
      motionPreset="slideInBottom"
      scrollBehavior={"inside"}
      size={"xl"}
    >
      <DeleteAlertDialogModal
        handleDelete={handleDeleteClass}
        onClose={deleteOnClose}
        isOpen={deleteIsOpen}
      />
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {classRoomToEdit ? "Edit Class" : "Add New Class"}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack gap={0} alignItems={"center"}>
            <FormControl mb={4} isRequired>
              <FormLabel>Class Name</FormLabel>
              <Input
                name="className"
                value={classRoomFormState.className}
                placeholder="Class Name"
                onChange={handleClassChange}
                isInvalid={!!classRoomFormErrors.className}
              />
            </FormControl>

            {classRoomToEdit && (
              <FormControl mb={4} isRequired>
                <FormLabel>Class Level</FormLabel>
                <Select
                  name="levelId"
                  value={classRoomFormState.levelId}
                  placeholder="Select Class Level"
                  onChange={handleClassChange}
                  isInvalid={!!classRoomFormErrors.levelId}
                >
                  {levels.map((p) => (
                    <option key={p.id} value={p.id}>
                      {p.levelName}
                    </option>
                  ))}
                </Select>
              </FormControl>
            )}

            <FormControl mb={4}>
              <FormLabel>Form Teacher</FormLabel>
              <Select
                name="formTeacherId"
                value={classRoomFormState.formTeacherId}
                placeholder="Select Form Teacher"
                onChange={handleClassChange}
              >
                {users
                  .filter((u) => u.role === 1)
                  .map((u) => (
                    <option
                      key={u.id}
                      value={u.id}
                    >{`${u.firstName} ${u.lastName}`}</option>
                  ))}
              </Select>
            </FormControl>

            <FormControl mb={4} isRequired>
              <FormLabel>Order</FormLabel>
              <Input
                type="number"
                name="order"
                value={classRoomFormState.order}
                placeholder="Select Class Level"
                onChange={handleClassChange}
                isInvalid={!!classRoomFormErrors.order}
              />
            </FormControl>
          </VStack>
        </ModalBody>
        <ModalFooter alignSelf={"center"}>
          {isLoading ? (
            <CircularProgress isIndeterminate color={"brand.300"} />
          ) : (
            <Flex gap={5} justifyContent={"center"}>
              <Button
                colorScheme="brand"
                onClick={handleAddClass}
                isLoading={isLoading}
              >
                Done
              </Button>

              {classRoomToEdit && (
                <Button
                  isLoading={isLoading}
                  variant={"ghost"}
                  colorScheme="red"
                  leftIcon={<BiTrash />}
                  onClick={onOpen}
                >
                  Delete Class
                </Button>
              )}
            </Flex>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ClassRoomModal;
