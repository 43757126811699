import { Text, Center, Button, Image } from "@chakra-ui/react";
import EmptyImage from "../../assets/empty2.svg"
interface Props{
    message?: string;
    buttonText?: string;
    handleClick?: ()=>void;
    hasButton?: boolean;
    height?: string;
    hidePicture?: boolean;
}
export default function Empty({message, buttonText, handleClick, hasButton, height, hidePicture}:Props) {
  return (
    <Center h={height || "100%"} flexDir={"column"} gap={5}>
      {!hidePicture && <Image opacity={"0.5"} src={EmptyImage} w={"300px"} />}
      <Text textAlign={"center"} opacity={"0.5"}>{message || "Nothing to see here"}</Text>
      {hasButton && <Button colorScheme="brand" variant={"ghost"} onClick={handleClick}>{buttonText}</Button>}
    </Center>
  );
}
