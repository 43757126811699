import React, {
  createContext,
  useState,
  useEffect,
  ReactNode,
  useContext,
} from "react";
import {
  ApiDataResponse,
  AssessmentType,
  BehavioralSubject,
  GradeCriteria,
  Level,
  School,
  Session,
  Subject,
  Term,
  User,
} from "../services/Dtos";
import { useUserContext } from "./userContext";
import useAxios from "../hooks/useAxios";

interface SchoolsContextType {
  schools: School[];
  setSchools: React.Dispatch<React.SetStateAction<School[]>>;
  school: School | null;
  setSchool: React.Dispatch<React.SetStateAction<School | null>>;
  sessions: Session[];
  setSessions: React.Dispatch<React.SetStateAction<Session[]>>;
  terms: Term[];
  setTerms: React.Dispatch<React.SetStateAction<Term[]>>;
  gradeCriterias: GradeCriteria[];
  setGradeCriterias: React.Dispatch<React.SetStateAction<GradeCriteria[]>>;
  assessmentTypes: AssessmentType[];
  setAssessmentTypes: React.Dispatch<React.SetStateAction<AssessmentType[]>>;
  levels: Level[];
  setLevels: React.Dispatch<React.SetStateAction<Level[]>>;
  subjects: Subject[];
  setSubjects: React.Dispatch<React.SetStateAction<Subject[]>>;
  behavioralSubjects: BehavioralSubject[];
  setBehavioralSubjects: React.Dispatch<
    React.SetStateAction<BehavioralSubject[]>
  >;
  users: User[];
  setUsers: React.Dispatch<React.SetStateAction<User[]>>;
  error: string | null;
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

interface Props {
  children: ReactNode;
}

const SchoolsContext = createContext<SchoolsContextType | undefined>(undefined);

export const SchoolsProvider = ({ children }: Props) => {
  const [schools, setSchools] = useState<School[]>([]);
  const [school, setSchool] = useState<School | null>(null);
  const [sessions, setSessions] = useState<Session[]>([]);
  const [terms, setTerms] = useState<Term[]>([]);
  const [gradeCriterias, setGradeCriterias] = useState<GradeCriteria[]>([]);
  const [assessmentTypes, setAssessmentTypes] = useState<AssessmentType[]>([]);
  const [levels, setLevels] = useState<Level[]>([]);
  const [subjects, setSubjects] = useState<Subject[]>([]);
  const [behavioralSubjects, setBehavioralSubjects] = useState<
    BehavioralSubject[]
  >([]);
  const [users, setUsers] = useState<User[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const { user, isLoading: userLoading } = useUserContext();
  const axiosClient = useAxios();

  useEffect(() => {
    if (user && !userLoading) {
      const fetchData = async () => {
        setIsLoading(true);
        try {
          // Fetch schools
          const schoolsResponse = await axiosClient.get<
            ApiDataResponse<School>
          >(`schools/${user.schoolId}`);
          if (schoolsResponse.data?.succeeded) {
            setSchool(schoolsResponse.data.data);
          } else {
            setError(
              `Error fetching schools: ${schoolsResponse.data?.message}`
            );
          }

          // Fetch sessions
          const sessionsResponse = await axiosClient.get<
            ApiDataResponse<Session[]>
          >("sessions");
          if (sessionsResponse.data?.succeeded) {
            const sortByStartDate = sessionsResponse.data.data
              .sort(
                (a, b) =>
                  new Date(a.sessionStartDate).getTime() -
                  new Date(b.sessionStartDate).getTime()
              )
              .reverse();

            setSessions(sortByStartDate);
          } else {
            setError(
              `Error fetching sessions: ${sessionsResponse.data?.message}`
            );
          }

          // Fetch terms
          const termsResponse = await axiosClient.get<ApiDataResponse<Term[]>>(
            "terms"
          );
          if (termsResponse.data?.succeeded) {
            const sortByStartDate = termsResponse.data.data;
            setTerms(sortByStartDate);
          } else {
            setError(`Error fetching terms: ${termsResponse.data?.message}`);
          }

          // Fetch grade criterias
          const gradeCriteriasResponse = await axiosClient.get<
            ApiDataResponse<GradeCriteria[]>
          >("gradeCriterias");
          if (gradeCriteriasResponse.data?.succeeded) {
            setGradeCriterias(
              gradeCriteriasResponse.data.data
                .sort((a, b) => b.order - a.order)
                .reverse()
            );
          } else {
            setError(
              `Error fetching grade criterias: ${gradeCriteriasResponse.data?.message}`
            );
          }

          // Fetch levels
          const levelsResponse = await axiosClient.get<
            ApiDataResponse<Level[]>
          >("levels");
          if (levelsResponse.data?.succeeded) {
            setLevels(levelsResponse.data.data);
          } else {
            setError(`Error fetching levels: ${levelsResponse.data?.message}`);
          }

          // Fetch subjects
          const subjectsResponse = await axiosClient.get<
            ApiDataResponse<Subject[]>
          >("subjects");
          if (subjectsResponse.data?.succeeded) {
            setSubjects(subjectsResponse.data.data);
          } else {
            setError(
              `Error fetching subjects: ${subjectsResponse.data?.message}`
            );
          }

          // Fetch subjects
          const behavioralSubjectsResponse = await axiosClient.get<
            ApiDataResponse<BehavioralSubject[]>
          >("behavioralsubjects");
          if (behavioralSubjectsResponse.data?.succeeded) {
            setBehavioralSubjects(behavioralSubjectsResponse.data.data);
          } else {
            setError(
              `Error fetching behavioral subjects: ${behavioralSubjectsResponse.data?.message}`
            );
          }

          // Fetch assessment types
          const assessmentTypesResponse = await axiosClient.get<
            ApiDataResponse<AssessmentType[]>
          >("assessmenttypes");
          if (assessmentTypesResponse.data?.succeeded) {
            setAssessmentTypes(assessmentTypesResponse.data.data);
          } else {
            setError(
              `Error fetching assessment types: ${assessmentTypesResponse.data?.message}`
            );
          }

          // Fetch users
          const usersResponse = await axiosClient.get<ApiDataResponse<User[]>>(
            "users"
          );
          if (usersResponse.data?.succeeded) {
            setUsers(usersResponse.data.data);
          } else {
            setError(`Error fetching users: ${usersResponse.data?.message}`);
          }
        } catch (error) {
          console.error("Failed to fetch data:", error);
          setError("Failed to fetch data");
        } finally {
          setIsLoading(false);
        }
      };

      fetchData();
    }
  }, [user, userLoading]);

  return (
    <SchoolsContext.Provider
      value={{
        schools,
        setSchools,
        school,
        setSchool,
        sessions,
        setSessions,
        terms,
        setTerms,
        gradeCriterias,
        setGradeCriterias,
        assessmentTypes,
        setAssessmentTypes,
        levels,
        subjects,
        setSubjects,
        setLevels,
        users,
        setUsers,
        error,
        isLoading,
        setIsLoading,
        behavioralSubjects,
        setBehavioralSubjects,
      }}
    >
      {children}
    </SchoolsContext.Provider>
  );
};

export const useSchoolsContext = () => {
  const context = useContext(SchoolsContext);
  if (!context) {
    throw new Error("useSchoolsContext must be used within a SchoolsProvider");
  }
  return context;
};
