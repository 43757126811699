import { EditIcon } from "@chakra-ui/icons";
import { Box, Avatar, IconButton, Input } from "@chakra-ui/react";
import React, { Dispatch, useCallback, useRef } from "react";
import { useUserContext } from "../../../../Contexts/userContext";

interface Props {
  isEditing: boolean;
  media: string | null;
  setMedia: Dispatch<React.SetStateAction<string | null>>;
  handleUpload: () => Promise<boolean>;
}
export default function UserImage({
  isEditing,
  media,
  setMedia,
}: Props) {
  const { user } = useUserContext();
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  

  const onFileChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files && event.target.files[0];

      if (file) {
        const reader = new FileReader();
        reader.onload = () => {
          setMedia(reader.result as string);
        };
        reader.readAsDataURL(file);
      }
    },
    []
  );

  

  return (
    <Box position="relative" width="fit-content" h={"fit-content"}>
      <Input
        ref={fileInputRef}
        id="fileInput"
        type="file"
        accept="image/*"
        onChange={onFileChange}
        style={{ display: "none" }}
      />
      <Avatar
        name={user?.firstName + " " + user?.lastName}
        src={media || user?.avatarUrl}
        size={"xl"}
      />
      {isEditing && (
        <Box
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          boxSize={"90px"}
          aspectRatio={1}
          position="absolute"
          top="50%"
          left="50%"
          transform="translate(-50%, -50%)"
          borderRadius={"full"}
          transition={"0.5s"}
          sx={{
            opacity: 1,
          }}
          _hover={{
            opacity: 1,
          }}
        >
          <IconButton
            aria-label="Edit Avatar"
            icon={<EditIcon />}
            size="sm"
            colorScheme="brand"
            onClick={() => fileInputRef.current?.click()}
          />
        </Box>
      )}
    </Box>
  );
}
