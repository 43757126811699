import {
  Box,
  Flex,
  Grid,
  GridItem,
  IconButton,
  useColorModeValue,
  Text,
  Stack,
  Divider,
  Center,
  useMediaQuery,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useStudentsContext } from "../../../Contexts/StudentsContext";
import { mapClass } from "../../../services/Utils";
import { useClassesContext } from "../../../Contexts/ClassesContext";
import { useEffect, useState } from "react";
import { ApiDataResponse, Dashboard, Student } from "../../../services/Dtos";
import { useDashboardContext } from "../../../Contexts/DashboardContext";
import { useSchoolsContext } from "../../../Contexts/schoolContext";
import SchoolInfo from "./SchoolInfo";
import PeriodCard from "./PeriodCard";
import { LuCalendarClock, LuPersonStanding, LuPin } from "react-icons/lu";
import StatCard from "./StatCard";
import { BiBook, BiCustomize, BiGrid, BiGroup } from "react-icons/bi";
import CompleteResultsChart from "./CompleteResultsChart";
import RecentStudentTable from "./RecentStudentTable";
import ClassPopulationChart from "./ClassPopulationChart";
import TopSubjectsChart from "./TopSubjectsChart";
import ChartRefreshButton from "./ChartRefreshButton";
import useToaster from "../../../hooks/useToaster";
import Empty from "../../ui/Empty";
import DashboardSetup from "./DashboardSetup";
import { PiFootprintsDuotone } from "react-icons/pi";
import Loader from "../../ui/Loader";
import { useCombinedLoadingContext } from "../../../Contexts/CombinedLoadingContext";
import BgDark from "../../../assets/Default Cover Photo dark.jpg";
import BgLight from "../../../assets/Default Cover Photo light.jpg";
import useAxios from "../../../hooks/useAxios";
import { mobileQuery } from "../../../services/constants";

function DashboardGrid() {
  const axiosClient = useAxios();
  const coverBG = useColorModeValue(BgLight, BgDark);
  const blendMode = useColorModeValue("screen", "multiply");
  const overlay = useColorModeValue("whiteAlpha.700", "blackAlpha.500");
  const { combinedLoading } = useCombinedLoadingContext();
  const navigate = useNavigate();
  const { sessions, terms, school, subjects, levels } = useSchoolsContext();
  const {
    dashboard,
    isLoading: dashBoardIsLoading,
    setIsLoading,
    setDashboard,
  } = useDashboardContext();
  const { students, setSelectedStudent } = useStudentsContext();
  const { classes } = useClassesContext();
  const { showErrorToast } = useToaster();
  const [isMobile] = useMediaQuery(mobileQuery);
  const [classMap, setClassMap] = useState<{ [key: string]: string }>({});

  useEffect(() => {
    if (classes) {
      const map = mapClass(classes);
      setClassMap(map);
    }
  }, [classes]);
  const gridBorderRadius = "lg";
  const gridBgColor = useColorModeValue("gray.100", "gray.800");
  const periodGridBgColor = useColorModeValue("gray.100", "gray.700");
  const scrollColor = useColorModeValue("gray.200", "#3B3B3B");

  const onClickStudent = (student: Student) => {
    setSelectedStudent(student);
    navigate(isMobile ? "" : "/students");
  };

  const handleRefresh = async () => {
    setIsLoading(true);
    try {
      // Fetch dashboards
      const dashboardsResponse = await axiosClient.get<
        ApiDataResponse<Dashboard>
      >("dashboarddata");
      if (dashboardsResponse.data?.succeeded) {
        setDashboard(dashboardsResponse.data.data);
      } else {
        showErrorToast(dashboardsResponse.data?.message);
      }
    } catch (error) {
      console.error("Failed to fetch dashboard data:", error);
      showErrorToast("Failed to fetch dashboard data");
    } finally {
      setIsLoading(false);
    }
  };

  if (combinedLoading) {
    return (
      <Center h={"full"}>
        <Loader />
      </Center>
    );
  }

  return (
    <Box
      flex={1}
      h={"100%"}
      overflowY={"auto"}
      sx={{
        "&::-webkit-scrollbar": {
          display: "block",
          width: "8px",
        },
        "&:hover": {
          "&::-webkit-scrollbar": {},
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: scrollColor,
            borderRadius: "4px",
          },
        },
      }}
      p={4}
    >
      <Grid
        flex={1}
        h="100%"
        templateColumns={[
          "1fr",
          null,
          null,
          "repeat(6, 1fr)",
          "repeat(6, 1fr)",
        ]}
        gap={5}
      >
        <GridItem
          rowSpan={[4, 2, 2, 2, 2]}
          colSpan={[0, 0, 0, 4, 4]}
          bg={gridBgColor}
          borderRadius={gridBorderRadius}
          backgroundImage={school?.coverPhoto || coverBG}
          bgPos={"center"}
          backgroundSize="cover"
          pos={"relative"}
        >
          <Box
            position="absolute"
            top={0}
            left={0}
            right={0}
            bottom={0}
            bg={school?.accentColor || "brand.300"}
            opacity={0.8}
            mixBlendMode={blendMode}
            zIndex={0}
          />

          <Box
            position="absolute"
            top={0}
            left={0}
            right={0}
            bottom={0}
            bg={overlay}
            zIndex={0}
          />
          <Flex h={"full"}>
            <SchoolInfo />
          </Flex>
        </GridItem>
        <GridItem
          rowSpan={[1, 1, 2, 2, 2]}
          colSpan={[0, 0, 0, 2, 2]}
          bg={periodGridBgColor}
          borderRadius={gridBorderRadius}
          pos={"relative"}
        >
          <Flex
            flexDir={["column", null, "row", "row", "row"]}
            p={3}
            h={"full"}
            justifyContent={"space-around"}
            alignItems={"center"}
            gap={5}
            zIndex={2}
            wrap={"wrap"}
          >
            <PeriodCard
              iconColor={school?.accentColor || "#3FB290"}
              heading={
                sessions.find(
                  (session) => session.id === school?.currentSessionId
                )?.sessionName || ""
              }
              Subtitle="current session"
              icon={LuCalendarClock}
            />
            <PeriodCard
              iconColor={school?.accentColor || "#FFBC12"}
              heading={
                terms.find((term) => term.id === school?.currentTermId)
                  ?.termName || ""
              }
              Subtitle="current term"
              icon={LuPin}
            />
          </Flex>
          <Box
            position="absolute"
            top={0}
            left={0}
            right={0}
            bottom={0}
            bg={school?.accentColor || ""}
            opacity={0.8}
            mixBlendMode={blendMode}
            zIndex={0}
          />
        </GridItem>
        {sessions.length === 0 || terms.length === 0 || levels.length === 0 ? (
          <GridItem
            rowSpan={[1, 1, 2, 2, 2]}
            colSpan={[0, 0, 0, 6, 6]}
            bg={gridBgColor}
            borderRadius={gridBorderRadius}
          >
            <Flex
              minH={"400px"}
              flexDir={["column", "column", "column", "column", "row"]}
            >
              <Stack flex={1} p={10}>
                <Text fontSize={"2xl"}>Welcome aboard! 🚀</Text>
                <Text fontSize={"sm"} mb={5}>
                  {isMobile
                    ? "Before your new dashboard comes to life, you need to set up the items below on a tablet or desktop device."
                    : "Before your new dashboard comes to life, let's set up a few things."}
                </Text>
                {!school?.avatar &&
                  !school?.accentColor &&
                  !school?.coverPhoto &&
                  !school?.footnote && (
                    <DashboardSetup
                      heading={"Customize School"}
                      Subtitle={
                        "Add school logo, cover photo, tagline, accent colour and more!"
                      }
                      backgroundColor={"cyan.600"}
                      iconColor={"white"}
                      icon={BiCustomize}
                      link={!isMobile ? "/settings" : ""}
                    />
                  )}
                {sessions.length === 0 && (
                  <>
                    <Divider />
                    <DashboardSetup
                      heading={"Add Session"}
                      Subtitle={
                        "Start a new academic journey by adding a session!"
                      }
                      backgroundColor={"teal"}
                      iconColor={"white"}
                      icon={LuCalendarClock}
                      link={!isMobile ? "/settings/session" : ""}
                    />
                  </>
                )}
                {terms.length === 0 && (
                  <>
                    <Divider />
                    <DashboardSetup
                      heading={"Add Term"}
                      Subtitle={
                        "Get started by adding new terms and setting the current term."
                      }
                      backgroundColor={"tomato"}
                      iconColor={"white"}
                      icon={LuPin}
                      link={!isMobile ? "/settings/term" : ""}
                    />
                  </>
                )}
                {levels.length === 0 && (
                  <>
                    <Divider />
                    <DashboardSetup
                      heading={"Add School Levels"}
                      Subtitle={
                        "Organize your school by adding new levels and managing them efficiently!"
                      }
                      backgroundColor={"purple.400"}
                      iconColor={"white"}
                      icon={PiFootprintsDuotone}
                      link={!isMobile ? "/settings/level" : ""}
                    />
                  </>
                )}
                <Divider />
                <DashboardSetup
                  heading={"Add Users or Teachers"}
                  Subtitle={"Expand your team by adding new users or teachers!"}
                  backgroundColor={"orange.400"}
                  iconColor={"white"}
                  icon={LuPersonStanding}
                  link={!isMobile ? "/settings/user" : ""}
                />
              </Stack>
              <Flex justifyContent={"center"} flex={1} p={5}>
                <Empty />
              </Flex>
            </Flex>
          </GridItem>
        ) : (
          <>
            <GridItem
              rowSpan={[2, 4, 1, 1, 1]}
              colSpan={[null, null, null, 4, 4]}
              borderRadius={gridBorderRadius}
              bg={gridBgColor}
            >
              <Flex
                justifyContent={"space-between"}
                gap={5}
                alignItems={"center"}
                w={"full"}
                h={"full"}
                flexDir={["column", "column", "row", "row", "row"]}
                p={5}
                wrap={"wrap"}
              >
                <StatCard
                  icon={BiGroup}
                  title={"Students"}
                  value={students.length}
                />

                <StatCard
                  icon={BiGrid}
                  title={"Classes"}
                  value={classes?.length}
                  bg="rgba(254, 110, 75, 0.7)"
                  borderColor="rgb(254, 110, 75)"
                />
                <StatCard
                  icon={BiBook}
                  title={"Subjects"}
                  value={subjects.length}
                  bg="rgba(256, 196, 62, 0.7)"
                  borderColor="rgb(256, 196, 62)"
                />
              </Flex>
            </GridItem>
            <GridItem
              rowSpan={[2, 2, 1, 1, 1]}
              colSpan={[0, 0, 0, 2, 2]}
              borderRadius={gridBorderRadius}
              bg={gridBgColor}
            >
              <Flex alignItems={"center"} p={5} pos={"relative"}>
                <CompleteResultsChart
                  max={dashboard?.results.totalResults || 0}
                  value={dashboard?.results.completedResults || 0}
                  isloading={dashBoardIsLoading}
                />
                <Box pos={"absolute"} right={5} top={2}>
                  <ChartRefreshButton
                    handleRefresh={handleRefresh}
                    isLoading={dashBoardIsLoading}
                  />
                </Box>
              </Flex>
            </GridItem>

            <GridItem
              rowSpan={[4, 3, 3, 3, 3]}
              colSpan={[0, 0, 0, 2, 2]}
              borderRadius={gridBorderRadius}
              bg={gridBgColor}
            >
              <Flex p={5} flexDir={"column"}>
                <Flex
                  alignItems={"center"}
                  justifyContent={"space-between"}
                  mb={5}
                  gap={5}
                >
                  <Text opacity={0.5}>Recently Added Students</Text>
                  <IconButton
                    aria-label="add student"
                    icon={<BiGroup />}
                    onClick={() => navigate("/students")}
                  />
                </Flex>
                <RecentStudentTable
                  students={students.reverse()}
                  classMap={classMap}
                  onClickStudent={onClickStudent}
                />
              </Flex>
            </GridItem>

            <GridItem
              rowSpan={[4, 3, 3, 3, 3]}
              colSpan={[0, 0, 0, 2, 2]}
              borderRadius={gridBorderRadius}
              bg={gridBgColor}
            >
              <Flex p={5} flexDir={"column"} h={"full"}>
                <Flex
                  alignItems={"center"}
                  justifyContent={"space-between"}
                  mb={5}
                  gap={5}
                >
                  <Text opacity={0.5}>Top Classes by Population</Text>
                  <ChartRefreshButton
                    handleRefresh={handleRefresh}
                    isLoading={dashBoardIsLoading}
                  />
                </Flex>

                <ClassPopulationChart
                  classData={dashboard?.classPopulation || null}
                />
              </Flex>
            </GridItem>

            <GridItem
              rowSpan={[4, 3, 3, 3, 3]}
              colSpan={[0, 0, 0, 2, 2]}
              borderRadius={gridBorderRadius}
              bg={gridBgColor}
            >
              <Flex p={5} flexDir={"column"} h={"full"}>
                <Flex
                  alignItems={"center"}
                  justifyContent={"space-between"}
                  mb={5}
                  gap={5}
                >
                  <Text opacity={0.5}>Top Subjects by Performance</Text>
                  <ChartRefreshButton
                    handleRefresh={handleRefresh}
                    isLoading={dashBoardIsLoading}
                  />
                </Flex>
                <TopSubjectsChart
                  subjectData={dashboard?.subjectPerformance || null}
                />
              </Flex>
            </GridItem>
          </>
        )}
      </Grid>
    </Box>
  );
}

export default DashboardGrid;
