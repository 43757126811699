import React, { useState, ChangeEvent, useEffect } from "react";
import { ApiDataResponse, ApiResponse, User } from "../../../../services/Dtos";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  Flex,
  useDisclosure,
  CircularProgress,
  VStack,
  Select,
} from "@chakra-ui/react";
import { BiTrash } from "react-icons/bi";
import {
  validateEmail,
  validateGender,
  validateRequired,
  validateRole,
} from "../../../../services/validation";
import DeleteAlertDialogModal from "../../../ui/DeleteAlertDialogModal";
import useToaster from "../../../../hooks/useToaster";
import ErrorAlert from "../../../ui/ErrorAlert";
import { genders, roles } from "../../../../services/constants";
import { deepEqual } from "../../../../services/Utils";

interface UserModalProps {
  isOpen: boolean;
  onClose: () => void;
  userToEdit: User | null;
  addUser: (user: User) => Promise<ApiDataResponse<User> | null>;
  deleteUser: (userId: string) => Promise<ApiResponse | null>;
  editUser: (user: User, userId: string) => Promise<ApiResponse | null>;
  updateUserRole: (role: number, userId: string) => Promise<ApiResponse | null>;
}

const UserModal: React.FC<UserModalProps> = ({
  isOpen,
  onClose,
  userToEdit,
  addUser,
  deleteUser,
  editUser,
  updateUserRole,
}) => {
  const initialUserFormState: User = {
    id: "",
    firstName: "",
    lastName: "",
    email: "",
    schoolId: "",
    gender: 0,
    role: 0,
    isActive: false,
    avatarUrl: "",
    createdAt: "",
    updatedAt: "",
  };

  const { showSuccessToast, showErrorToast, showWarningToast } = useToaster();

  const [inUserModalLoading, setInUserModalLoading] = useState(false);
  const [inUserModalError, setInUserModalError] = useState<string | undefined>(
    undefined
  );

  const {
    isOpen: deleteIsOpen,
    onOpen,
    onClose: deleteOnClose,
  } = useDisclosure();

  const [userFormState, setUserFormState] =
    useState<User>(initialUserFormState);

  useEffect(() => {
    if (userToEdit) {
      setUserFormState(userToEdit);
    } else {
      setUserFormState(initialUserFormState);
    }
  }, [userToEdit]);

  const [userFormErrors, setUserFormErrors] = useState<{
    [key: string]: string;
  }>({});

  const validateUserForm = (): boolean => {
    const currentUserFormErrors: { [key: string]: string } = {};

    if (!validateRequired(userFormState.firstName)) {
      currentUserFormErrors.firstName = "First name is required";
    }
    if (!validateRequired(userFormState.lastName)) {
      currentUserFormErrors.lastName = "Last name is required";
    }
    if (!validateRequired(userFormState.email)) {
      currentUserFormErrors.email = "Email is required";
    }
    if (!validateEmail(userFormState.email)) {
      currentUserFormErrors.email = "Correct email format is required";
    }
    if (!validateGender(userFormState.gender)) {
      currentUserFormErrors.gender = "Gender is required";
    }
    if (!validateRole(userFormState.role)) {
      currentUserFormErrors.role = "Role is required";
    }

    setUserFormErrors(currentUserFormErrors);
    return Object.keys(currentUserFormErrors).length === 0;
  };

  const handleAddUser = async () => {
    if (!validateUserForm()) {
      console.log(userFormErrors);
      return;
    }

    setInUserModalLoading(true);

    try {
      if (deepEqual(userToEdit, userFormState)){
        showWarningToast("Nothing to change");
        return;
      }
      if (userToEdit) {
        if (userToEdit.role !== userFormState.role) {
          const updateUserRoleResponse = await updateUserRole(
            userFormState.role,
            userToEdit.id
          );
          if (!updateUserRoleResponse?.succeeded) {
            showErrorToast(updateUserRoleResponse?.message || "");
            return;
          }
        }
        const response = await editUser(userFormState, userToEdit.id);
        if (response?.succeeded) {
          showSuccessToast(response?.message || "");
          onClose();
        } else {
          showErrorToast(response?.message || "");
          console.info("Error updating user");
        }
      } else {
        const response = await addUser(userFormState);
        if (response?.succeeded) {
          showSuccessToast(response?.message || "");
          setUserFormState(initialUserFormState);
          onClose();
        } else {
          setInUserModalError(response?.message);
          showErrorToast(response?.message || "");
          console.info("Error adding user");
        }
      }
    } catch (error) {
      showErrorToast();
      console.error("Unexpected error:", error);
    } finally {
      setInUserModalLoading(false);
    }
  };

  const handleDeleteUser = async () => {
    if (userToEdit) {
      setInUserModalLoading(true);
      const response = await deleteUser(userToEdit?.id);
      if (response?.succeeded) {
        onClose();
        showSuccessToast(response?.message || "");
      } else {
        showErrorToast(response?.message || "");
      }
    }
    setInUserModalLoading(false);
  };

  const handleUserChange = (
    event: ChangeEvent<HTMLSelectElement | HTMLInputElement>
  ) => {
    const { name, value } = event.target;

    setUserFormState((prevState) => ({
      ...prevState,
      [name]:
        name === "gender" || name === "role" ? parseInt(value, 10) : value,
    }));

    console.log(userFormState);
  };
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick={false}
      motionPreset="slideInBottom"
      scrollBehavior={"inside"}
      size={"xl"}
    >
      <DeleteAlertDialogModal
        handleDelete={handleDeleteUser}
        onClose={deleteOnClose}
        isOpen={deleteIsOpen}
      />
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{userToEdit ? "Edit User" : "Add New User"}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack gap={0} alignItems={"center"}>
            {inUserModalError && <ErrorAlert message={inUserModalError} />}
            <FormControl mb={4} isRequired>
              <FormLabel>First Name</FormLabel>
              <Input
                name="firstName"
                value={userFormState.firstName}
                placeholder="First name"
                onChange={handleUserChange}
                isInvalid={!!userFormErrors.firstName}
              />
            </FormControl>

            <FormControl mb={4} isRequired>
              <FormLabel>Last Name</FormLabel>
              <Input
                name="lastName"
                value={userFormState.lastName}
                placeholder="Last name"
                onChange={handleUserChange}
                isInvalid={!!userFormErrors.lastName}
              />
            </FormControl>
            <FormControl mb={4} isRequired>
              <FormLabel>Email</FormLabel>
              <Input
                name="email"
                type="email"
                value={userFormState.email}
                placeholder="Email"
                onChange={handleUserChange}
                isInvalid={!!userFormErrors.email}
              />
            </FormControl>
            <FormControl mb={4} isRequired>
              <FormLabel>Gender</FormLabel>
              <Select
                name="gender"
                value={userFormState?.gender}
                placeholder="Select gender"
                onChange={handleUserChange}
                isInvalid={!!userFormErrors.gender}
              >
                {genders.map((g) => (
                  <option value={g.value} key={g.value}>
                    {g.name}
                  </option>
                ))}
              </Select>
            </FormControl>
            
              <FormControl mb={4} isRequired>
                <FormLabel>Role</FormLabel>
                <Select
                  name="role"
                  value={userFormState.role}
                  defaultValue={0}
                  placeholder="Select Role"
                  onChange={handleUserChange}
                  isInvalid={!!userFormErrors.role}
                >
                  {roles.map((r) => (
                    <option value={r.value} key={r.value}>
                      {r.name}
                    </option>
                  ))}
                </Select>
              </FormControl>
            
          </VStack>
        </ModalBody>
        <ModalFooter alignSelf={"center"}>
          {inUserModalLoading ? (
            <CircularProgress isIndeterminate color={"brand.300"} />
          ) : (
            <Flex gap={5} justifyContent={"center"}>
              <Button
                colorScheme="brand"
                onClick={handleAddUser}
                isLoading={inUserModalLoading}
              >
                Done
              </Button>

              {userToEdit && (
                <Button
                  isLoading={inUserModalLoading}
                  variant={"ghost"}
                  colorScheme="red"
                  leftIcon={<BiTrash />}
                  onClick={onOpen}
                >
                  Delete User
                </Button>
              )}
            </Flex>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default UserModal;
