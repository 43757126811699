import {
  Badge,
  Box,
  Table,
  Tbody,
  Td,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import { brandTransparentColor } from "../../../../services/constants";
import { useSchoolsContext } from "../../../../Contexts/schoolContext";
import { Session } from "../../../../services/Dtos";
import { formatDate } from "../../../../services/Utils";

interface Props {
  session: Session;
  handleClick: () => void;
}

export default function SessionCard({ session, handleClick }: Props) {
  const altColor = useColorModeValue("gray.100", "gray.700");
  const { school } = useSchoolsContext();
  return (
    <Box
      bg={altColor}
      p={3}
      borderRadius={"lg"}
      borderWidth={1}
      minW={"270px"}
      transition={"300ms"}
      _hover={{
        backgroundColor: brandTransparentColor,

        cursor: "pointer",
        transform: "scale(1.09)",
      }}
      onClick={handleClick}
      position={"relative"}
    >
      {school?.currentSessionId === session.id && (
        <Badge
          fontSize={"2xs"}
          top={0}
          left={0}
          position={"absolute"}
          colorScheme="green"
        >
          Current
        </Badge>
      )}
      <Table variant={"simple"} size={"xs"} fontSize={"sm"}>
        <Tbody>
          <Tr>
            <Td>Session</Td>
            <Td textAlign={"right"} fontWeight={"bold"} pl={5}>
              <Badge variant={"outline"} colorScheme={"brand"}>
                {session.sessionName}
              </Badge>
            </Td>
          </Tr>
          <Tr>
            <Td>Session Start Date</Td>
            <Td textAlign={"right"} fontWeight={"bold"} pl={5}>
              {formatDate(session.sessionStartDate)}
            </Td>
          </Tr>
          <Tr>
            <Td>Session End Date</Td>
            <Td textAlign={"right"} fontWeight={"bold"} pl={5}>
              {formatDate(session.sessionEndDate)}
            </Td>
          </Tr>
          <Tr>
            <Td>Order</Td>
            <Td textAlign={"right"} fontWeight={"bold"} pl={5}>
              {session.order}
            </Td>
          </Tr>
        </Tbody>
      </Table>
    </Box>
  );
}
