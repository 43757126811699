import { Flex, useDisclosure } from "@chakra-ui/react";

import { useSchoolsContext } from "../../../../Contexts/schoolContext";

import { ApiDataResponse, ApiResponse, Level } from "../../../../services/Dtos";
import { useEffect, useState } from "react";
import Loader from "../../../ui/Loader";
import ErrorAlert from "../../../ui/ErrorAlert";
import LevelModal from "./LevelModal";
import { BiPlus } from "react-icons/bi";
import HeaderDivider from "../../../ui/HeaderDivider";
import LevelCard from "./LevelCard";
import { getNextOrder } from "../../../../services/Utils";
import useAxios from "../../../../hooks/useAxios";

function LevelSettings() {
  const axiosClient = useAxios();
  const { levels, setLevels, isLoading, error } = useSchoolsContext();
  const [nextOrder, setNextOrder] = useState(0);
  const [levelToEdit, setLevelToEdit] = useState<Level | null>(null);

  const { onOpen, isOpen, onClose } = useDisclosure();

  useEffect(() => {
    setNextOrder(getNextOrder(levels));
  }, [levels]);

  const handleRefresh = () => {
    console.log("Handle Click Function not implemented.");
  };

  const handleAddLevel = () => {
    setLevelToEdit(null);
    onOpen();
    console.log(levelToEdit);
  };

  const handleEditLevel = (level: Level) => {
    console.log(level);

    setLevelToEdit(level);
    onOpen();
  };

  const addLevel = async (
    level: Level
  ): Promise<ApiDataResponse<Level> | null> => {
    try {
      const response = await axiosClient.post<ApiDataResponse<Level>>(
        "levels",
        level
      );
      if (response.data.succeeded) {
        setLevels((prev) => [...prev, response.data.data]);
      }
      return response.data;
    } catch (error) {}
    return null;
  };

  const editLevel = async (
    levelToEdit: Level,
    levelId: string
  ): Promise<ApiResponse | null> => {
    try {
      const response = await axiosClient.put<ApiResponse>(
        `levels/${levelId}`,
        levelToEdit
      );
      if (response.data.succeeded) {
        setLevels((prev) =>
          prev.map((level) => (level.id === levelId ? levelToEdit : level))
        );
      }
      return response.data;
    } catch (error) {
      console.error("Error editing level:", error);
    }
    return null;
  };

  const deleteLevel = async (levelId: string): Promise<ApiResponse | null> => {
    try {
      const response = await axiosClient.delete<ApiResponse>(
        `levels/${levelId}`
      );
      if (response.data.succeeded) {
        setLevels((prev) => prev.filter((level) => level.id !== levelId));
      }
      return response.data;
    } catch (error) {
      console.error("Error deleting level:", error);
    }
    return null;
  };

  if (isLoading) {
    return <Loader />;
  }

  if (error) {
    return <ErrorAlert message={error} handleClick={handleRefresh} />;
  }

  return (
    <Flex flexDirection={"column"} p={5}>
      <LevelModal
        nextOrder={nextOrder}
        isOpen={isOpen}
        onClose={onClose}
        levelToEdit={levelToEdit}
        addLevel={addLevel}
        deleteLevel={deleteLevel}
        editLevel={editLevel}
      />
      <HeaderDivider
        heading={"School Levels"}
        hasButton={true}
        handleButtonClick={handleAddLevel}
        icon={<BiPlus />}
        colorScheme={"green"}
      />
      <Flex gap={5} wrap={"wrap"} mb={20}>
        {levels.map((s) => (
          <LevelCard
            key={s.id}
            level={s}
            handleClick={() => handleEditLevel(s)}
          />
        ))}
      </Flex>
    </Flex>
  );
}

export default LevelSettings;
