import React, { useState, ChangeEvent, useEffect } from "react";
import { Guardian } from "../../../services/Dtos";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  Flex,
  useDisclosure,
  InputLeftAddon,
  InputGroup,
  Box,
  Select,
  CircularProgress,
} from "@chakra-ui/react";
import { BiPlus, BiTrash } from "react-icons/bi";
import {
  validateEmail,
  validateNigerianPhoneNumber,
  validateRequired,
} from "../../../services/validation";
import DeleteAlertDialogModal from "../../ui/DeleteAlertDialogModal";
import { useGuardiansContext } from "../../../Contexts/GuardiansContext";
import { useStudentsContext } from "../../../Contexts/StudentsContext";
import { titles } from "../../../services/constants";
import useToaster from "../../../hooks/useToaster";

interface AddGuardianModalProps {
  isOpen: boolean;
  onClose: () => void;
  guardianToEdit: Guardian | null;
}

const AddGuardianModal: React.FC<AddGuardianModalProps> = ({
  isOpen,
  onClose,
  guardianToEdit,
}) => {
  const initialGuardianFormState: Guardian = {
    guardianId: "",
    guardianTitle: "",
    guardianFullName: "",
    guardianPhoneNumber: "",
    guardianEmail: "",
    guardianAddress: "",
  };
  const { showSuccessToast, showErrorToast } = useToaster();

  const [isLoading, setIsLoading] = useState(false);

  const {
    addGuardian,
    editGuardian,
    deleteGuardian,
  } = useGuardiansContext();

  const { selectedStudent } = useStudentsContext();

  const {
    isOpen: deleteIsOpen,
    onOpen,
    onClose: deleteOnClose,
  } = useDisclosure();

  const [guardianFormState, setGuardianFormState] = useState<Guardian>(
    initialGuardianFormState
  );

  useEffect(() => {
    if (guardianToEdit) {
      setGuardianFormState(guardianToEdit);
    } else {
      setGuardianFormState(initialGuardianFormState);
    }
  }, [guardianToEdit]);

  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  const validateForm = (): boolean => {
    const currentErrors: { [key: string]: string } = {};

    if (!validateRequired(guardianFormState.guardianFullName)) {
      currentErrors.guardianFullName = "Full Name is required";
    }
    if (!validateRequired(guardianFormState.guardianTitle)) {
      currentErrors.guardianTitle = "Title is required";
    }
    if (!validateEmail(guardianFormState.guardianEmail)) {
      currentErrors.guardianEmail = "Email not in Correct Format";
    }
    if (!validateRequired(guardianFormState.guardianAddress)) {
      currentErrors.guardianAddress = "Address is required";
    }
    if (!validateNigerianPhoneNumber(guardianFormState.guardianPhoneNumber)) {
      currentErrors.guardianPhoneNumber = "Phone Number not in Correct Format";
    }

    setErrors(currentErrors);
    return Object.keys(currentErrors).length === 0;
  };

  const handleAddGuardian = async () => {
    if (!validateForm()) {
      console.log(errors);
      return;
    }

    setIsLoading(true);

    try {
      if (guardianToEdit && selectedStudent) {
        const response = await editGuardian(
          guardianFormState,
          guardianToEdit.guardianId
        );
        if (response) {
          showSuccessToast();
          onClose();
        } else {
          showErrorToast();
          console.info("Error updating guardian");
        }
      } else if (selectedStudent) {
        setGuardianFormState(initialGuardianFormState);
        const response = await addGuardian(
          guardianFormState,
          selectedStudent.id
        );
        if (response) {
          showSuccessToast();
          onClose();
        } else {
          showErrorToast();
          console.info("Error adding guardian");
        }
      }
    } catch (error) {
      showErrorToast();
      console.error("Unexpected error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteGuardian = async () => {
    console.log("add guardian modal", guardianToEdit);
    if (guardianToEdit) {
      setIsLoading(true);
      const response = await deleteGuardian(guardianToEdit?.guardianId);
      if (response?.succeeded) {
        onClose();
        showSuccessToast();
      } else {
        showErrorToast();
      }
    }
    setIsLoading(false);
  };

  const handleGuardianChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setGuardianFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    console.log(guardianFormState);
  };

  const handleAddAnother = async () => {
    if (validateForm()) {
      if (selectedStudent) {
        setIsLoading(true);
        const response = await addGuardian(
          guardianFormState,
          selectedStudent?.id
        );
        if (response) {
          showSuccessToast();

          setGuardianFormState(initialGuardianFormState);
        }
      }
    }
    setIsLoading(false);
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick={false}
      motionPreset="slideInBottom"
      scrollBehavior={"inside"}
      size={"xl"}
    >
      <DeleteAlertDialogModal
        handleDelete={handleDeleteGuardian}
        onClose={deleteOnClose}
        isOpen={deleteIsOpen}
      />
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {guardianToEdit ? "Edit Guardian" : "Add New Guardian"}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex justifyContent={"space-between"} gap={5} alignItems={"center"}>
            <FormControl mb={4} isRequired w={"30%"}>
              <FormLabel>Title</FormLabel>
              <Select
                name="guardianTitle"
                value={guardianFormState.guardianTitle}
                onChange={handleGuardianChange}
                isInvalid={!!errors.guardianTitle}
                placeholder="Select Title"
              >
                {titles.map((t, index) => (
                  <option key={index} value={t}>
                    {t}
                  </option>
                ))}
              </Select>
            </FormControl>

            <FormControl mb={4} isRequired flex={1}>
              <FormLabel>Guardian's Full Name</FormLabel>
              <Input
                name="guardianFullName"
                value={guardianFormState.guardianFullName}
                onChange={handleGuardianChange}
                isInvalid={!!errors.guardianFullName}
              />
            </FormControl>
          </Flex>

          <FormControl mb={4} isRequired>
            <FormLabel>Guardian's Phone Number</FormLabel>
            <InputGroup>
              <InputLeftAddon>+234</InputLeftAddon>
              <Input
                name="guardianPhoneNumber"
                value={guardianFormState.guardianPhoneNumber}
                onChange={handleGuardianChange}
                isInvalid={!!errors.guardianPhoneNumber}
              />
            </InputGroup>
          </FormControl>
          {errors.guardianPhoneNumber && (
            <Box w={"full"} textAlign={"left"} color="red.500" fontSize={"sm"}>
              {errors.guardianPhoneNumber}
            </Box>
          )}

          <FormControl mb={4} isRequired>
            <FormLabel>Guardian's Email</FormLabel>
            <Input
              name="guardianEmail"
              value={guardianFormState.guardianEmail}
              onChange={handleGuardianChange}
              isInvalid={!!errors.guardianEmail}
            />
          </FormControl>

          <FormControl mb={4} isRequired>
            <FormLabel>Guardian's Address</FormLabel>
            <Input
              name="guardianAddress"
              value={guardianFormState.guardianAddress}
              onChange={handleGuardianChange}
              isInvalid={!!errors.guardianAddress}
            />
          </FormControl>
        </ModalBody>
        <ModalFooter alignSelf={"center"}>
          {isLoading ? (
            <CircularProgress isIndeterminate color={"brand.300"} />
          ) : (
            <Flex gap={5} justifyContent={"center"}>
              <Button
                colorScheme="brand"
                onClick={handleAddGuardian}
                isLoading={isLoading}
              >
                Done
              </Button>
              {!guardianToEdit && (
                <Button
                  leftIcon={<BiPlus />}
                  variant={"outline"}
                  onClick={handleAddAnother}
                  isLoading={isLoading}
                >
                  Add Another Guardian
                </Button>
              )}

              {guardianToEdit && (
                <Button
                  isLoading={isLoading}
                  variant={"ghost"}
                  colorScheme="red"
                  leftIcon={<BiTrash />}
                  onClick={onOpen}
                >
                  Remove Guardian From Student
                </Button>
              )}
            </Flex>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AddGuardianModal;
