import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Text,
  DrawerBody,
  Stack,
  Avatar,
  VStack,
  Heading,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import MobileColorModeSwitch from "./MobileColorModeSwitch";
import MobileLogoutButton from "./MobileLogoutButton";
import { useUserContext } from "../../Contexts/userContext";
import useAxios from "../../hooks/useAxios";
import useToaster from "../../hooks/useToaster";
import { useEffect, useState } from "react";
import { brandTransparentColor } from "../../services/constants";

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export default function MobileMenu({ isOpen, onClose }: Props) {
  const { user, setUser } = useUserContext();
  const navigate = useNavigate();
  const { showErrorToast, showSuccessToast } = useToaster();
  const axiosClient = useAxios();

  const [greeting, setGreeting] = useState("");

  useEffect(() => {
    const updateGreeting = () => {
      const currentHour = new Date().getHours();
      if (currentHour < 12) {
        setGreeting("Good Morning");
      } else if (currentHour < 18) {
        setGreeting("Good Afternoon");
      } else {
        setGreeting("Good Evening");
      }
    };

    updateGreeting(); // Initial call to set the greeting

    const interval = setInterval(updateGreeting, 1800000); // Update every 30 minutes

    return () => clearInterval(interval); // Clean up the interval on component unmount
  }, []);

  const handleLogout = async () => {
    try {
      await axiosClient.post("authentication/logout");
      setUser(null);
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      navigate("/auth/login");
      showSuccessToast("You are Logged out");
    } catch (error) {
      console.error("Error logging out:", error);
      showErrorToast();
    }
  };

  return (
    <Drawer placement="right" onClose={onClose} isOpen={isOpen}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />

        <DrawerBody mt={10}>
          <Stack as="nav" spacing={4} align={"center"}>
            <Avatar
              size={"lg"}
              name={user?.firstName + " " + user?.lastName}
              src={user?.avatarUrl || ""}
            ></Avatar>
            <VStack align={"left"} gap={0} mb={5}>
              <Text
                fontSize={"md"}
                textAlign={"center"}
              >{`${greeting} 👋`}</Text>
              <Text
                textAlign={"center"}
                fontWeight={"bold"}
                fontSize={"lg"}
              >{`${user?.firstName}`}</Text>
              <Text textAlign={"center"} fontSize={"xs"}>
                {user?.email}
              </Text>
            </VStack>

            <MobileColorModeSwitch />
            <MobileLogoutButton handleLogout={handleLogout} />
            <VStack
              mt={5}
              bg={brandTransparentColor}
              p={5}
              borderRadius={"lg"}
            >
              <Heading textAlign={"center"} size={"md"}>
                🚀 Life needs a big Breign!
              </Heading>
              <Text textAlign={"center"}>
                While you can access the Dashboard on mobile, we’ve got so many
                powerful features waiting for you! For the best experience and
                to fully explore Breign, switch to a larger screen.
              </Text>
            </VStack>
          </Stack>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}
