import { useEffect } from "react";
import { Box } from "@chakra-ui/react";
import { gsap } from "gsap";

const LogoLoader = () => {
  useEffect(() => {
    // Get the path element
    const path = document.querySelector("#outer-stroke path") as SVGPathElement;

    if (path) {
      // Set up the initial styles for the path
      const dashLength = 95; // Length of the dash
      const gapLength = 5; // Length of the gap
      path.style.strokeDasharray = `${dashLength} ${gapLength}`;

      // Animate the stroke
      gsap.to(path, {
        strokeDashoffset: `-${dashLength + gapLength}`,
        duration: 2,
        repeat: -1,
        ease: "power2.inOut",
      });
    }
   
  }, []);

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      height="100vh"
    >
      <svg
        width="100"
        height="100"
        viewBox="0 0 88 101"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <defs>
          <filter id="glow">
            <feGaussianBlur stdDeviation="3.5" result="coloredBlur" />
            <feMerge>
              <feMergeNode in="coloredBlur" />
              <feMergeNode in="SourceGraphic" />
            </feMerge>
          </filter>
        </defs>
        <g transform="matrix(1,0,0,1,-737.397,-165.918)">
          <g transform="matrix(1,0,0,1,1464.55,-155.567)">
            <g id="outer-stroke" transform="matrix(1,0,0,1,-1360.02,157.102)">
              <path
                d="M642.012,165.322C636.439,165.322 633.799,167.962 633.799,173.389L633.799,256.26C633.799,261.687 636.439,264.327 642.012,264.327L686.015,264.327C709.336,264.327 719.163,252.887 719.163,237.046C719.163,225.605 713.883,218.271 704.496,214.458C713.736,210.791 718.723,203.897 718.723,192.456C718.723,176.322 708.896,165.322 685.575,165.322L642.012,165.322Z"
                style={{
                  fill: "none",
                  stroke: "rgb(162,86,250)",
                  strokeWidth: "1.87px",
                  filter: "url(#glow)",
                }}
              />
            </g>
          </g>
        </g>
      </svg>
     
    </Box>
  );
};

export default LogoLoader;
