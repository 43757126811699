import React, { useState, ChangeEvent } from "react";
import {
  ApiResponse,
  UpdatePasswordRequestDto,
} from "../../../../services/Dtos";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  Flex,
  CircularProgress,
  VStack,
  InputRightElement,
  InputGroup,
} from "@chakra-ui/react";
import {
  validateConfirmPassword,
  validatePassword,
  validateRequired,
} from "../../../../services/validation";
import useToaster from "../../../../hooks/useToaster";
import ErrorAlert from "../../../ui/ErrorAlert";
import useAxios from "../../../../hooks/useAxios";

interface ChangePasswordProps {
  isOpen: boolean;
  onClose: () => void;
}

const ChangePasswordModal: React.FC<ChangePasswordProps> = ({
  isOpen,
  onClose,
}) => {
  const initialFormState: UpdatePasswordRequestDto = {
    currentPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  };

  const axiosClient = useAxios();

  const { showSuccessToast, showErrorToast } = useToaster();
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);

  const [inChangePasswordLoading, setInChangePasswordLoading] = useState(false);
  const [inChangePasswordError, setInChangePasswordError] = useState<
    string | null
  >(null);

  const [formState, setFormState] =
    useState<UpdatePasswordRequestDto>(initialFormState);

  const [formErrors, setFormErrors] = useState<{
    [key: string]: string;
  }>({});

  const validateForm = (): boolean => {
    const currentFormErrors: { [key: string]: string } = {};

    if (!validateRequired(formState.currentPassword)) {
      currentFormErrors.currentPassword = "Current password is required";
    }
    if (!validatePassword(formState.currentPassword)) {
      currentFormErrors.currentPassword =
        "Password must contain at least one lowercase letter, one uppercase letter, one symbol, and be at least 8 characters long.";
    }
    if (!validateRequired(formState.newPassword)) {
      currentFormErrors.newPassword = "New password is required";
    }
    if (!validatePassword(formState.newPassword)) {
      currentFormErrors.newPassword =
        "Password must contain at least one lowercase letter, one uppercase letter, one symbol, and be at least 8 characters long.";
    }
    if (!validateRequired(formState.confirmNewPassword)) {
      currentFormErrors.confirmNewPassword =
        "Password Confirmation is required";
    }
    if (
      !validateConfirmPassword(
        formState.confirmNewPassword,
        formState.newPassword
      )
    ) {
      currentFormErrors.confirmNewPassword = "Passwords mismatch";
    }

    setFormErrors(currentFormErrors);
    return Object.keys(currentFormErrors).length === 0;
  };

  const handleChangePassword = async () => {
    if (!validateForm()) {
      console.log(formErrors);
      return;
    }

    setInChangePasswordLoading(true);

    try {
      const response = await axiosClient.post<ApiResponse>(
        "authentication/updatepassword",
        formState
      );
      if (response?.data.succeeded) {
        showSuccessToast();
        setFormState(initialFormState);
        onClose();
      } else {
        setInChangePasswordError(response.data.message);
        showErrorToast();
        console.info("Error changing password");
      }
    } catch (error) {
      showErrorToast();
      console.error("Unexpected error:", error);
    } finally {
      setInChangePasswordLoading(false);
    }
  };

  const handleChange = (
    event: ChangeEvent<HTMLSelectElement | HTMLInputElement>
  ) => {
    const { name, value } = event.target;

    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleCloseChangePasswordModal = () => {
    setFormState(initialFormState);
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleCloseChangePasswordModal}
      closeOnOverlayClick={false}
      motionPreset="slideInBottom"
      scrollBehavior={"inside"}
      size={"xl"}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{"Change Password"}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack gap={0} alignItems={"center"}>
            {inChangePasswordError && (
              <ErrorAlert message={inChangePasswordError} />
            )}
            <FormControl mb={4} isRequired>
              <FormLabel>Current Password</FormLabel>
              <InputGroup>
                <Input
                  type={showCurrentPassword ? "text" : "password"}
                  name="currentPassword"
                  value={formState.currentPassword}
                  placeholder="Current password"
                  onChange={handleChange}
                  isInvalid={!!formErrors.currentPassword}
                />
                <InputRightElement width="4.5rem">
                  <Button
                    h="1.75rem"
                    size="sm"
                    onClick={() => setShowCurrentPassword((prev) => !prev)}
                  >
                    {showCurrentPassword ? "Hide" : "Show"}
                  </Button>
                </InputRightElement>
              </InputGroup>
            </FormControl>
            <FormControl mb={4} isRequired>
              <FormLabel>New Password</FormLabel>
              <InputGroup>
                <Input
                  type={showNewPassword ? "text" : "password"}
                  name="newPassword"
                  value={formState.newPassword}
                  placeholder="New password"
                  onChange={handleChange}
                  isInvalid={!!formErrors.newPassword}
                />
                <InputRightElement width="4.5rem">
                  <Button
                    h="1.75rem"
                    size="sm"
                    onClick={() => setShowNewPassword((prev) => !prev)}
                  >
                    {showCurrentPassword ? "Hide" : "Show"}
                  </Button>
                </InputRightElement>
              </InputGroup>
            </FormControl>
            <FormControl mb={4} isRequired>
              <FormLabel>Confirm Password</FormLabel>
              <Input
                type={showNewPassword ? "text" : "password"}
                name="confirmNewPassword"
                value={formState.confirmNewPassword}
                placeholder="Confirm new password"
                onChange={handleChange}
                isInvalid={!!formErrors.confirmNewPassword}
              />
            </FormControl>
          </VStack>
        </ModalBody>
        <ModalFooter alignSelf={"center"}>
          {inChangePasswordLoading ? (
            <CircularProgress isIndeterminate color={"brand.300"} />
          ) : (
            <Flex gap={5} justifyContent={"center"}>
              <Button
                colorScheme="brand"
                onClick={handleChangePassword}
                isLoading={inChangePasswordLoading}
              >
                Done
              </Button>
            </Flex>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ChangePasswordModal;
