import { Flex, CircularProgress, CircularProgressLabel, Stack, Text } from '@chakra-ui/react'
import { calculatePercentage, getColorBasedOnPercentage } from '../../../services/Utils';
interface Props{
    value: number;
    max: number;
    isloading: boolean;
}
export default function CompleteResultsChart({value, max, isloading}: Props) {

    //if(value === 0 || max === 0) return <ErrorAlert message="No results yet" status='info'/>
    
    return (
        <Flex alignItems={"center"} justifyContent={"space-evenly"} gap={5} w={"full"}>
            {(() => {
                let percentage = calculatePercentage(value, max);
                let color = getColorBasedOnPercentage(percentage);
                return (
                    <>
                        <CircularProgress
                            max={max}
                            value={value}
                            capIsRound
                            size={"80px"}
                            isIndeterminate={isloading}
                            color={`${color}.500`} // Use the calculated color
                            trackColor={`${color}.100`}
                        >
                            <CircularProgressLabel>{percentage}%</CircularProgressLabel>
                        </CircularProgress>
                        <Stack pr={10}>
                            <Text fontWeight={"bold"}>Completed Results</Text>
                            <Text fontSize={"sm"}>
                                {value} of {max} result entries for the current term and session.
                            </Text>
                        </Stack>
                    </>
                );
            })()}
        </Flex>
    );
}
