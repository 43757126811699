import React, { useState, ChangeEvent, useEffect } from "react";
import { ApiDataResponse, ApiResponse, AssessmentType, Level } from "../../../../services/Dtos";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  Flex,
  useDisclosure,
  CircularProgress,
  VStack,
  Select,
} from "@chakra-ui/react";
import { BiTrash } from "react-icons/bi";
import {
  validateGreaterThanZero,
  validateRequired,
  validateShortName,
} from "../../../../services/validation";
import DeleteAlertDialogModal from "../../../ui/DeleteAlertDialogModal";
import useToaster from "../../../../hooks/useToaster";
import ErrorAlert from "../../../ui/ErrorAlert";
import { useSchoolsContext } from "../../../../Contexts/schoolContext";

interface AssessmentTypeProps {
  isOpen: boolean;
  onClose: () => void;
  assessmentTypeToEdit: AssessmentType | null;
  addAssessmentType: (
    assessmentType: AssessmentType
  ) => Promise<ApiDataResponse<AssessmentType> | null>;
  deleteAssessmentType: (assessmentTypeId: string) => Promise<ApiResponse | null>;
  editAssessmentType: (
    assessmentType: AssessmentType,
    assessmentTypeId: string
  ) => Promise<ApiResponse | null>;
  assessmentLevel: Level | null;
}

const AssessmentTypeModal: React.FC<AssessmentTypeProps> = ({
  isOpen,
  onClose,
  assessmentTypeToEdit,
  addAssessmentType,
  deleteAssessmentType,
  editAssessmentType,
  assessmentLevel,
}) => {
  const initialAssessmentTypeFormState: AssessmentType = {
    id: "",
    schoolId: "",
    name: "",
    shortName: "",
    maxScore: 0,
    order: 0,
    levelId: assessmentLevel?.id || "",
  };

  const { showSuccessToast, showErrorToast } = useToaster();
  const { levels } = useSchoolsContext();

  const [inAssessmentTypeLoading, setInAssessmentTypeLoading] = useState(false);
  const [inAssessmentTypeError, setInAssessmentTypeError] = useState<
    string | undefined
  >(undefined);

  const {
    isOpen: deleteIsOpen,
    onOpen,
    onClose: deleteOnClose,
  } = useDisclosure();

  const [assessmentTypeFormState, setAssessmentTypeFormState] =
    useState<AssessmentType>(initialAssessmentTypeFormState);

  useEffect(() => {
    if (assessmentTypeToEdit) {
      setAssessmentTypeFormState(assessmentTypeToEdit);
    } else {
      setAssessmentTypeFormState(initialAssessmentTypeFormState);
    }
  }, [assessmentTypeToEdit, assessmentLevel]);

  const [assessmentTypeFormErrors, setAssessmentTypeFormErrors] = useState<{
    [key: string]: string;
  }>({});

  const validateAssessmentTypeForm = (): boolean => {
    const currentAssessmentTypeFormErrors: { [key: string]: string } = {};

    if (!validateRequired(assessmentTypeFormState.name)) {
      currentAssessmentTypeFormErrors.name = "AssessmentType name is required";
    }

    if (!validateRequired(assessmentTypeFormState.shortName)) {
      currentAssessmentTypeFormErrors.shortName =
        "AssessmentType short name is required";
    }

    if (!validateRequired(assessmentTypeFormState.levelId)) {
      currentAssessmentTypeFormErrors.levelId = "Level is required";
    }

    if (!validateShortName(assessmentTypeFormState.shortName)) {
      currentAssessmentTypeFormErrors.shortName =
        "Short name cannot be more than 5 characters";
    }

    if (!validateGreaterThanZero(assessmentTypeFormState.maxScore)) {
      currentAssessmentTypeFormErrors.maxScore =
        "AssessmentType max score is required";
    }

    if (!validateGreaterThanZero(assessmentTypeFormState.order)) {
      currentAssessmentTypeFormErrors.order =
        "AssessmentType order is required";
    }

    setAssessmentTypeFormErrors(currentAssessmentTypeFormErrors);
    return Object.keys(currentAssessmentTypeFormErrors).length === 0;
  };

  const handleAddAssessmentType = async () => {
    if (!validateAssessmentTypeForm()) {
      console.log(assessmentTypeFormErrors);
      return;
    }

    setInAssessmentTypeLoading(true);

    try {
      if (assessmentTypeToEdit) {
        const response = await editAssessmentType(
          assessmentTypeFormState,
          assessmentTypeToEdit.id
        );
        if (response?.succeeded) {
          showSuccessToast(response.message || "");
          onClose();
        } else {
          showErrorToast(response?.message || "");
          console.info("Error updating assessmentType");
        }
      } else {
        const response = await addAssessmentType(assessmentTypeFormState);
        if (response?.succeeded) {
          showSuccessToast(response.message || "");
          setAssessmentTypeFormState(initialAssessmentTypeFormState);
          onClose();
        } else {
          setInAssessmentTypeError(response?.message);
          showErrorToast(response?.message || "");
          console.info("Error adding assessmentType");
        }
      }
    } catch (error) {
      showErrorToast();
      console.error("Unexpected error:", error);
    } finally {
      setInAssessmentTypeLoading(false);
    }
  };

  const handleDeleteAssessmentType = async () => {
    if (assessmentTypeToEdit) {
      setInAssessmentTypeLoading(true);
      const response = await deleteAssessmentType(assessmentTypeToEdit?.id);
      if (response) {
        onClose();
        showSuccessToast();
      } else {
        showErrorToast();
      }
    }
    setInAssessmentTypeLoading(false);
  };

  const handleAssessmentTypeChange = (
    event: ChangeEvent<HTMLSelectElement | HTMLInputElement>
  ) => {
    const { name, value, type } = event.target;

    if (type === "checkbox") {
      const { checked } = event.target as HTMLInputElement;
      setAssessmentTypeFormState((prevState) => ({
        ...prevState,
        [name]: checked,
      }));
    } else {
      setAssessmentTypeFormState((prevState) => ({
        ...prevState,
        [name]:
          name === "maxScore" || name === "order" ? parseInt(value, 10) : value,
      }));
    }
  };
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick={false}
      motionPreset="slideInBottom"
      scrollBehavior={"inside"}
      size={"xl"}
    >
      <DeleteAlertDialogModal
        handleDelete={handleDeleteAssessmentType}
        onClose={deleteOnClose}
        isOpen={deleteIsOpen}
      />
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {assessmentTypeToEdit
            ? "Edit AssessmentType"
            : "Add New AssessmentType"}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack gap={0} alignItems={"center"}>
            {inAssessmentTypeError && (
              <ErrorAlert message={inAssessmentTypeError} />
            )}
            <FormControl mb={4} isRequired>
              <FormLabel>Full Name</FormLabel>
              <Input
                type="text"
                name="name"
                value={assessmentTypeFormState.name}
                placeholder="e.g (Continous Assessment 1)"
                onChange={handleAssessmentTypeChange}
                isInvalid={!!assessmentTypeFormErrors.name}
              />
            </FormControl>
            <FormControl mb={4} isRequired>
              <FormLabel>Short Name</FormLabel>
              <Input
                type="text"
                name="shortName"
                value={assessmentTypeFormState.shortName}
                placeholder="Abbreviated name e.g (CA1)"
                onChange={handleAssessmentTypeChange}
                isInvalid={!!assessmentTypeFormErrors.shortName}
              />
            </FormControl>
            <FormControl mb={4} isRequired>
              <FormLabel>Max Score</FormLabel>
              <Input
                type="number"
                name="maxScore"
                value={assessmentTypeFormState.maxScore}
                placeholder="Max Score e.g (15)"
                onChange={handleAssessmentTypeChange}
                isInvalid={!!assessmentTypeFormErrors.maxScore}
              />
            </FormControl>
            {assessmentTypeToEdit && (
              <FormControl mb={4} isRequired>
                <FormLabel>Level</FormLabel>
                <Select
                  name="levelId"
                  value={assessmentTypeFormState.levelId}
                  placeholder="Select Level"
                  onChange={handleAssessmentTypeChange}
                  isInvalid={!!assessmentTypeFormErrors.order}
                >
                  {levels.map((l) => (
                    <option key={l.id} value={l.id}>
                      {l.levelName}
                    </option>
                  ))}
                </Select>
              </FormControl>
            )}
            <FormControl mb={4} isRequired>
              <FormLabel>Order</FormLabel>
              <Input
                type="number"
                name="order"
                value={assessmentTypeFormState.order}
                placeholder="Position of this assessment type"
                onChange={handleAssessmentTypeChange}
                isInvalid={!!assessmentTypeFormErrors.order}
              />
            </FormControl>
          </VStack>
        </ModalBody>
        <ModalFooter alignSelf={"center"}>
          {inAssessmentTypeLoading ? (
            <CircularProgress isIndeterminate color={"brand.300"} />
          ) : (
            <Flex gap={5} justifyContent={"center"}>
              <Button
                colorScheme="brand"
                onClick={handleAddAssessmentType}
                isLoading={inAssessmentTypeLoading}
              >
                Done
              </Button>

              {assessmentTypeToEdit && (
                <Button
                  isLoading={inAssessmentTypeLoading}
                  variant={"ghost"}
                  colorScheme="red"
                  leftIcon={<BiTrash />}
                  onClick={onOpen}
                >
                  Delete AssessmentType
                </Button>
              )}
            </Flex>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AssessmentTypeModal;
