import { Text, Box } from "@chakra-ui/react";
import { brandTransparentColor, tableSelectedRowColor } from "../../../../services/constants";

interface Props {
  text: string;
  handleClick?: () => void;
selected: boolean;
}
export default function BoxList({ text, handleClick, selected }: Props) {
  return (
    <Box
    fontWeight={selected ? "bold" : "normal"}
      borderRadius={"md"}
      my={1}
      color={selected ? "gray.800" : ""}
      p={1}
      bg={selected ? "brand.100" : brandTransparentColor}
      onClick={handleClick}
      transition={"200ms"}
      _hover={{
        backgroundColor: selected ? "brand.100" : tableSelectedRowColor,
        cursor: "pointer",
        transform: "scale(1.02)",
      }}
    >
      <Text fontSize={"sm"}>{text}</Text>
    </Box>
  );
}
