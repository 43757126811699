import { CheckIcon, CloseIcon, EditIcon } from "@chakra-ui/icons";
import {
  ButtonGroup,
  Editable,
  EditableInput,
  EditablePreview,
  Flex,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  Text,
  useColorModeValue,
  useEditableControls,
} from "@chakra-ui/react";

interface Props {
  handleSubmit: (property: string, value: string) => void;
  label: string;
  defaultValue: string | undefined;
  property: string;
  placeHolder?: string;
  body?: string;
}

export default function TextSetting({
  handleSubmit,
  label,
  property,
  defaultValue,
  placeHolder,
  body,
}: Props) {
  function EditableControls() {
    const {
      isEditing,
      getSubmitButtonProps,
      getCancelButtonProps,
      getEditButtonProps,
    } = useEditableControls();

    return isEditing ? (
      <ButtonGroup justifyContent="center" size="sm">
        <IconButton
          aria-label="Save"
          icon={<CheckIcon />}
          {...getSubmitButtonProps()}
        />
        <IconButton
          aria-label="Close"
          icon={<CloseIcon />}
          {...getCancelButtonProps()}
        />
      </ButtonGroup>
    ) : (
      <Flex justifyContent="left">
        <IconButton
          aria-label="Edit"
          size="sm"
          icon={<EditIcon />}
          {...getEditButtonProps()}
        />
      </Flex>
    );
  }

  const altColor = useColorModeValue("gray.100", "gray.700");

  return (
    <FormControl
      bg={altColor}
      p={3}
      borderRadius={"lg"}
      borderWidth={1}
      maxW={"600px"}
      minW={"300px"}

      //   display={"flex"}
      //   flexDirection={"row"}
      //   justifyContent={"space-between"}
    >
      <FormLabel fontSize={"xs"} w={"100%"} textAlign={"left"}>
        {label}{" "}
      </FormLabel>
      <Text opacity={"0.5"} fontSize={"xs"}>
        {body || ""}
      </Text>
      <Editable
        defaultValue={defaultValue}
        fontWeight={"bold"}
        isPreviewFocusable={false}
        onSubmit={(v) => handleSubmit(property, v)}
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        placeholder={placeHolder || ""}
      >
        <EditablePreview />
        <Input as={EditableInput} />
        <EditableControls />
      </Editable>
    </FormControl>
  );
}
