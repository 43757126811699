import { Button, Flex, Input, Select, useDisclosure } from "@chakra-ui/react";
import { BiPlus } from "react-icons/bi";
import { useSchoolsContext } from "../../../../Contexts/schoolContext";
import { ApiDataResponse, ApiResponse, Term } from "../../../../services/Dtos";
import { ChangeEvent, useEffect, useState } from "react";
import Loader from "../../../ui/Loader";
import ErrorAlert from "../../../ui/ErrorAlert";
import { formatDate } from "../../../../services/Utils";
import HeaderDivider from "../../../ui/HeaderDivider";
import useToaster from "../../../../hooks/useToaster";
import TermModal from "./TermModal";
import TermCard from "./TermCard";
import useAxios from "../../../../hooks/useAxios";

function TermSettings() {
  const axiosClient = useAxios();
  const { school, setSchool, terms, setTerms, isLoading, error } =
    useSchoolsContext();
  const [, setInTermLoading] = useState(false);
  const [currentTermLoading, setCurrentTermLoading] = useState(false);
  const [currentTermDaysOpenLoading, setCurrentTermDaysOpenLoading] =
    useState(false);
  const [nextTermLoading, setNextTermLoading] = useState(false);
  const [nextTermStartDateLoading, setNextTermStartDateLoading] =
    useState(false);
  const [currentTermStartDateLoading, setCurrentTermStartDateLoading] =
    useState(false);
  const [currentTermEndDateLoading, setCurrentTermEndDateLoading] =
    useState(false);

  const [termToEdit, setTermToEdit] = useState<Term | null>(null);
  const { onOpen, isOpen, onClose } = useDisclosure();
  const [currentTerm, setCurrentTerm] = useState<string | undefined>(undefined);
  const [nextTerm, setNextTerm] = useState<string | undefined>(undefined);
  const [currentTermDaysOpen, setCurrentTermDaysOpen] = useState<
    number | undefined
  >(undefined);
  const [nextTermStartDate, setNextTermStartDate] = useState<
    string | undefined
  >(undefined);
  const [currentTermStartDate, setCurrentTermStartDate] = useState<
    string | undefined
  >(undefined);
  const [currentTermEndDate, setCurrentTermEndDate] = useState<
    string | undefined
  >(undefined);

  const { showSuccessToast, showErrorToast } = useToaster();

  useEffect(() => {
    setCurrentTerm(school?.currentTermId);
    setCurrentTermDaysOpen(school?.currentTermDaysOpen);
    setNextTermStartDate(school?.nextTermStartDate);
    setCurrentTermStartDate(school?.currentTermStartDate);
    setCurrentTermEndDate(school?.currentTermEndDate);
  }, [school]);

  useEffect(() => {
    setNextTerm(school?.nextTermId);
  }, [terms]);

  const handleRefresh = () => {
    console.log("Handle Click Function not implemented.");
  };

  const handleAddTerm = () => {
    setTermToEdit(null);
    onOpen();
    console.log(termToEdit);
  };

  const handleEditStudent = (term: Term) => {
    console.log(term);

    setTermToEdit(term);
    onOpen();
  };

  const addTerm = async (term: Term): Promise<ApiDataResponse<Term> | null> => {
    setInTermLoading(true);
    try {
      const response = await axiosClient.post<ApiDataResponse<Term>>(
        "terms",
        term
      );
      if (response.data.succeeded) {
        setTerms((prev) => [...prev, response.data.data]);
      }
      return response.data;
    } catch (error) {
    } finally {
      setInTermLoading(false);
    }
    return null;
  };

  const editTerm = async (
    termToEdit: Term,
    termId: string
  ): Promise<ApiResponse | null> => {
    setInTermLoading(true);
    try {
      const response = await axiosClient.put<ApiResponse>(
        `terms/${termId}`,
        termToEdit
      );
      if (response.data.succeeded) {
        setTerms((prev) =>
          prev.map((term) => (term.id === termId ? termToEdit : term))
        );
      }
      return response.data;
    } catch (error) {
      console.error("Error editing term:", error);
    } finally {
      setInTermLoading(false);
    }
    return null;
  };

  const deleteTerm = async (termId: string): Promise<ApiResponse | null> => {
    setInTermLoading(true);
    try {
      const response = await axiosClient.delete<ApiResponse>(`terms/${termId}`);
      if (response.data.succeeded) {
        setTerms((prev) => prev.filter((term) => term.id !== termId));
      }
      return response.data;
    } catch (error) {
      console.error("Error deleting term:", error);
    } finally {
      setInTermLoading(false);
    }
    return null;
  };

  const onCurrentTermChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setCurrentTerm(e.target.value);
  };

  const handleCurrentTermSave = async () => {
    if (!currentTerm) {
      return;
    }
    setCurrentTermLoading(true);
    try {
      const response = await axiosClient.put<ApiResponse>(`schools/`, {
        currentTermId: currentTerm,
      });
      if (response.data.succeeded) {
        setSchool((prev) => {
          if (!prev) return prev; // If `prev` is null, return it as is.
          return {
            ...prev,
            currentTermId: currentTerm,
          };
        });
        showSuccessToast();
      } else {
        showErrorToast();
      }
    } catch (error) {
      console.error("Error updating school with current term ID:", error);
      showErrorToast();
    } finally {
      setCurrentTermLoading(false);
    }
  };

  const onCurrentTermDaysOpenChange = (e: ChangeEvent<HTMLInputElement>) => {
    setCurrentTermDaysOpen(Number(e.target.value));
  };

  const handleCurrentTermDaysOpenSave = async () => {
    if (currentTermDaysOpen === undefined) {
      return;
    }
    setCurrentTermDaysOpenLoading(true);
    try {
      const response = await axiosClient.put<ApiResponse>(`schools/`, {
        currentTermDaysOpen: currentTermDaysOpen,
      });
      if (response.data.succeeded) {
        setSchool((prev) => {
          if (!prev) return prev; // If `prev` is null, return it as is.
          return {
            ...prev,
            currentTermDaysOpen: currentTermDaysOpen,
          };
        });
        showSuccessToast();
      } else {
        showErrorToast();
      }
    } catch (error) {
      console.error(
        "Error updating school with current term days open:",
        error
      );
      showErrorToast();
    } finally {
      setCurrentTermDaysOpenLoading(false);
    }
  };

  const onNextTermChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setNextTerm(e.target.value);
  };

  const handleNextTermSave = async () => {
    if (!nextTerm) {
      return;
    }
    setNextTermLoading(true);
    try {
      const response = await axiosClient.put<ApiResponse>(`schools/`, {
        nextTermId: nextTerm,
      });
      if (response.data.succeeded) {
        setSchool((prev) => {
          if (!prev) return prev; // If `prev` is null, return it as is.
          return {
            ...prev,
            nextTermId: nextTerm,
          };
        });
        showSuccessToast();
      } else {
        showErrorToast();
      }
    } catch (error) {
      console.error("Error updating school with next term ID:", error);
      showErrorToast();
    } finally {
      setNextTermLoading(false);
    }
  };

  const onNextTermStartDateChange = (e: ChangeEvent<HTMLInputElement>) => {
    setNextTermStartDate(e.target.value);
  };

  const handleNextTermStartDateSave = async () => {
    if (!nextTermStartDate) {
      return;
    }
    setNextTermStartDateLoading(true);
    try {
      const response = await axiosClient.put<ApiResponse>(`schools/`, {
        nextTermStartDate: nextTermStartDate,
      });
      if (response.data.succeeded) {
        setSchool((prev) => {
          if (!prev) return prev; // If `prev` is null, return it as is.
          return {
            ...prev,
            nextTermStartDate: nextTermStartDate,
          };
        });
        showSuccessToast();
      } else {
        showErrorToast();
      }
    } catch (error) {
      console.error("Error updating school with next term start date:", error);
      showErrorToast();
    } finally {
      setNextTermStartDateLoading(false);
    }
  };

  const onCurrentTermStartDateChange = (e: ChangeEvent<HTMLInputElement>) => {
    setCurrentTermStartDate(e.target.value);
  };

  const handleCurrentTermStartDateSave = async () => {
    if (!currentTermStartDate) {
      return;
    }
    setCurrentTermStartDateLoading(true);
    try {
      const response = await axiosClient.put<ApiResponse>(`schools/`, {
        currentTermStartDate: currentTermStartDate,
      });
      if (response.data.succeeded) {
        setSchool((prev) => {
          if (!prev) return prev; // If `prev` is null, return it as is.
          return {
            ...prev,
            currentTermStartDate: currentTermStartDate,
          };
        });
        showSuccessToast();
      } else {
        showErrorToast();
      }
    } catch (error) {
      console.error(
        "Error updating school with current term start date:",
        error
      );
      showErrorToast();
    } finally {
      setCurrentTermStartDateLoading(false);
    }
  };

  const onCurrentTermEndDateChange = (e: ChangeEvent<HTMLInputElement>) => {
    setCurrentTermEndDate(e.target.value);
  };

  const handleCurrentTermEndDateSave = async () => {
    if (!currentTermEndDate) {
      return;
    }
    setCurrentTermEndDateLoading(true);
    try {
      const response = await axiosClient.put<ApiResponse>(`schools/`, {
        currentTermEndDate: currentTermEndDate,
      });
      if (response.data.succeeded) {
        setSchool((prev) => {
          if (!prev) return prev; // If `prev` is null, return it as is.
          return {
            ...prev,
            currentTermEndDate: currentTermEndDate,
          };
        });
        showSuccessToast();
      } else {
        showErrorToast();
      }
    } catch (error) {
      console.error("Error updating school with current term end date:", error);
      showErrorToast();
    } finally {
      setCurrentTermEndDateLoading(false);
    }
  };

  if (isLoading) {
    return <Loader />;
  }

  if (error) {
    return <ErrorAlert message={error} handleClick={handleRefresh} />;
  }

  return (
    <Flex flexDirection={"column"} p={5} h={"100%"}>
      <Flex flexDirection={"column"}>
        <TermModal
          isOpen={isOpen}
          onClose={onClose}
          termToEdit={termToEdit}
          addTerm={addTerm}
          deleteTerm={deleteTerm}
          editTerm={editTerm}
        />
        <HeaderDivider
          heading={"Terms"}
          hasButton={true}
          handleButtonClick={handleAddTerm}
          icon={<BiPlus />}
          colorScheme={"green"}
        />
        <Flex gap={5} wrap={"wrap"} mb={20}>
          {terms.map((s) => (
            <TermCard
              key={s.id}
              term={s}
              handleClick={() => handleEditStudent(s)}
            />
          ))}
        </Flex>
        {terms.length !== 0 && (
          <>
            <HeaderDivider heading={"Current Term"} />
            <Flex gap={5} mb={20}>
              <Select
                placeholder="Select Current Term"
                name="currentTermId"
                maxW={300}
                value={currentTerm || ""}
                onChange={onCurrentTermChange}
              >
                {terms.map((s) => (
                  <option key={s.id} value={s.id}>
                    {s.termName}
                  </option>
                ))}
              </Select>
              <Button
                colorScheme="brand"
                variant={"outline"}
                isLoading={currentTermLoading}
                onClick={handleCurrentTermSave}
              >
                {"Save"}
              </Button>
            </Flex>
            <HeaderDivider heading={"Current Term Days Open"} />
            <Flex gap={5} mb={20}>
              <Input
                type="number"
                placeholder="Number of days current term opens"
                name="currentTermDaysOpen"
                maxW={300}
                value={currentTermDaysOpen || ""}
                onChange={onCurrentTermDaysOpenChange}
              />
              <Button
                colorScheme="brand"
                variant={"outline"}
                isLoading={currentTermDaysOpenLoading}
                onClick={handleCurrentTermDaysOpenSave}
              >
                {"Save"}
              </Button>
            </Flex>

            <HeaderDivider heading={"Current Term Start Date"} />
            <Flex gap={5} mb={20}>
              <Input
                type="date"
                placeholder="Select current term start date"
                name="currentTermStartDate"
                maxW={300}
                value={formatDate(currentTermStartDate || "")}
                onChange={onCurrentTermStartDateChange}
              />
              <Button
                colorScheme="brand"
                variant={"outline"}
                isLoading={currentTermStartDateLoading}
                onClick={handleCurrentTermStartDateSave}
              >
                {"Save"}
              </Button>
            </Flex>

            <HeaderDivider heading={"Current Term End Date"} />
            <Flex gap={5} mb={20}>
              <Input
                type="date"
                placeholder="Select current term end date"
                name="currentTermEndDate"
                maxW={300}
                value={formatDate(currentTermEndDate || "")}
                onChange={onCurrentTermEndDateChange}
              />
              <Button
                colorScheme="brand"
                variant={"outline"}
                isLoading={currentTermEndDateLoading}
                onClick={handleCurrentTermEndDateSave}
              >
                {"Save"}
              </Button>
            </Flex>

            <HeaderDivider heading={"Next Term"} />
            <Flex gap={5} mb={20}>
              <Select
                placeholder="Select Next Term"
                name="nextTermId"
                maxW={300}
                value={nextTerm || ""}
                onChange={onNextTermChange}
              >
                {terms.map((s) => (
                  <option key={s.id} value={s.id}>
                    {s.termName}
                  </option>
                ))}
              </Select>
              <Button
                colorScheme="brand"
                variant={"outline"}
                isLoading={nextTermLoading}
                onClick={handleNextTermSave}
              >
                {"Save"}
              </Button>
            </Flex>

            <HeaderDivider heading={"Next Term Start Date"} />
            <Flex gap={5} mb={20}>
              <Input
                type="date"
                placeholder="Select next term start date"
                name="nextTermStartDate"
                maxW={300}
                value={formatDate(nextTermStartDate || "")}
                onChange={onNextTermStartDateChange}
              />
              <Button
                colorScheme="brand"
                variant={"outline"}
                isLoading={nextTermStartDateLoading}
                onClick={handleNextTermStartDateSave}
              >
                {"Save"}
              </Button>
            </Flex>
          </>
        )}
      </Flex>
    </Flex>
  );
}

export default TermSettings;
