import {
  Button,
  Divider,
  Flex,
  IconButton,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tag,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { BiBook, BiPlus } from "react-icons/bi";
import { useSchoolsContext } from "../../../../Contexts/schoolContext";
import ClassModal from "./ClassModal";
import {
  ApiDataResponse,
  ApiResponse,
  BehavioralSubject,
  Class,
  Level,
  Subject,
} from "../../../../services/Dtos";
import { useEffect, useState } from "react";
import HeaderDivider from "../../../ui/HeaderDivider";
import ClassCard from "./ClassCard";
import { useClassesContext } from "../../../../Contexts/ClassesContext";
import Clave from "../../../ui/Clave";
import BoxList from "./BoxList";
import { ArrowDownIcon, ArrowForwardIcon } from "@chakra-ui/icons";
import Empty from "../../../ui/Empty";
import { useNavigate } from "react-router-dom";
import useUnsavedChangesPrompt from "../../../../hooks/useUnsavedChangesPrompt";
import useToaster from "../../../../hooks/useToaster";
import useAxios from "../../../../hooks/useAxios";

export default function ClassSettings() {
  const axiosClient = useAxios();
  const [classLevel, setClassLevel] = useState<Level | null>(null);
  const { levels, subjects, behavioralSubjects, isLoading, error } =
    useSchoolsContext();
  const { classes, setClasses } = useClassesContext();
  const [inClassLoading, setInClassLoading] = useState(false);
  const [classRoomToEdit, setClassToEdit] = useState<Class | null>(null);
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [selectedClass, setSelectedClass] = useState<Class | null>(null);
  const [, setInitialSubjects] = useState<Subject[]>([]);
  const [editedSubjects, setEditedSubjects] = useState<Subject[]>([]);
  const [, setInitialBehavioralSubjects] = useState<BehavioralSubject[]>([]);
  const [editedBehavioralSubjects, setEditedBehavioralSubjects] = useState<
    BehavioralSubject[]
  >([]);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const { onOpen, isOpen, onClose } = useDisclosure();
  const navigate = useNavigate();

  const { showSuccessToast, showErrorToast, showWarningToast } = useToaster();

  useUnsavedChangesPrompt(unsavedChanges);

  useEffect(() => {
    setSelectedClass(null);
    setEditedSubjects([]);
    setEditedBehavioralSubjects([]);
  }, [activeTabIndex]);

  const handleTabChange = (index: number) => {
    if (unsavedChanges) {
      const confirmLeave = window.confirm(
        "You have unsaved changes. Are you sure you want to leave?"
      );
      if (!confirmLeave) {
        return;
      }
      setUnsavedChanges(false);
    }
    setActiveTabIndex(index);
  };

  const handleClassBoxClick = (classRoom: Class) => {
    if (unsavedChanges) {
      const confirmLeave = window.confirm(
        "You have unsaved changes. Are you sure you want to leave?"
      );
      if (!confirmLeave) {
        return;
      }
    }
    if (classRoom.id !== selectedClass?.id) {
      setSelectedClass(classRoom);
      setInitialSubjects(classRoom.subjects);
      setEditedSubjects(
        classRoom.subjects.filter((s) => s.levelId === classRoom.levelId)
      );
      setInitialBehavioralSubjects(classRoom.behavioralSubjects || []);
      setEditedBehavioralSubjects(classRoom.behavioralSubjects || []);
      setUnsavedChanges(false);
    }
  };

  const handleSubjectClick = (subject: Subject) => {
    if (selectedClass) {
      setEditedSubjects((prev) => {
        const subjectExists = prev.some((s) => s.id === subject.id);
        const updatedSubjects = subjectExists
          ? prev.filter((s) => s.id !== subject.id)
          : [...prev, subject];
        setUnsavedChanges(true);
        return updatedSubjects;
      });
    } else {
      showWarningToast("select a class");
    }
  };

  const handleBehavioralSubjectClick = (
    behavioralSubject: BehavioralSubject
  ) => {
    if (selectedClass) {
      setEditedBehavioralSubjects((prev) => {
        const subjectExists = prev.some((s) => s.id === behavioralSubject.id);
        const updatedSubjects = subjectExists
          ? prev.filter((s) => s.id !== behavioralSubject.id)
          : [...prev, behavioralSubject];
        setUnsavedChanges(true);
        return updatedSubjects;
      });
    }
  };

  const handleAddAllSubjects = () => {
    if (selectedClass) {
      setEditedSubjects((prev) => {
        const newSubjects = subjects
          .filter((s) => s.levelId === levels[activeTabIndex].id)
          .filter((s) => !prev.some((sub) => sub.id === s.id));
        setUnsavedChanges(true);
        return [...prev, ...newSubjects];
      });
    }
  };

  const handleAddAllBehavioralSubjects = () => {
    if (selectedClass) {
      setEditedBehavioralSubjects((prev) => {
        const newSubjects = behavioralSubjects
          .filter((s) => s.levelId === levels[activeTabIndex].id)
          .filter((s) => !prev.some((sub) => sub.id === s.id));
        setUnsavedChanges(true);
        return [...prev, ...newSubjects];
      });
    }
  };

  const handleSave = async () => {
    if (!selectedClass) return;

    const updatedClass = {
      ...selectedClass,
      subjects: editedSubjects,
      behavioralSubjects: editedBehavioralSubjects,
    };

    setInClassLoading(true);
    try {
      const response = await axiosClient.put<ApiDataResponse<Class>>(
        `classes/AddClassSubjectsAndBehavioralSubjects/${selectedClass.id}`,
        updatedClass
      );
      if (response.data.succeeded) {
        setClasses((prev) =>
          prev.map((classRoom) =>
            classRoom.id === selectedClass.id
              ? {
                  ...classRoom,
                  subjects: editedSubjects,
                  behavioralSubjects: editedBehavioralSubjects,
                }
              : classRoom
          )
        );
        setInitialSubjects(editedSubjects);
        setInitialBehavioralSubjects(editedBehavioralSubjects);
        setUnsavedChanges(false);
        showSuccessToast();
      } else {
        showErrorToast();
        console.error("Failed to save subjects:", response.data);
      }
    } catch (error) {
      console.error("Error saving subjects:", error);
    } finally {
      setInClassLoading(false);
    }
  };

  const handleAddClass = (level: Level) => {
    setClassToEdit(null);
    setClassLevel(level);
    onOpen();
    console.log(classRoomToEdit);
  };

  const handleEditClass = (classRoom: Class) => {
    console.log(classRoom);

    setClassToEdit(classRoom);
    onOpen();
  };

  const addClass = async (
    classRoom: Class
  ): Promise<ApiDataResponse<Class> | null> => {
    setInClassLoading(true);
    try {
      const response = await axiosClient.post<ApiDataResponse<Class>>(
        "classes",
        classRoom
      );

      if (response.data.succeeded) {
        const newClass = response.data.data;

        setClasses((prev) => {
          // Update other classes
          const updatedClasses = prev.map((cls) => {
            return cls;
          });

          // Add the new class
          const newClassList = [...updatedClasses, newClass];

          // Sort the classes if necessary, otherwise just return the updated list
          return newClassList.sort((a, b) =>
            a.className.localeCompare(b.className)
          );
        });
      }
      return response.data;
    } catch (error) {
      console.error("Error adding class:", error);
      return null;
    } finally {
      setInClassLoading(false);
    }
  };

  const editClass = async (
    classRoomToEdit: Class,
    classRoomId: string
  ): Promise<ApiResponse | null> => {
    setInClassLoading(true);
    try {
      const response = await axiosClient.put<ApiResponse>(
        `classes/${classRoomId}`,
        classRoomToEdit
      );
      if (response.data.succeeded) {
        setClasses((prev) => {
          // Update the classes
          let updatedClasses = prev.map((classRoom) =>
            classRoom.id === classRoomId ? classRoomToEdit : classRoom
          );

          // Sort the classes using localeCompare
          return updatedClasses;
        });
      }
      return response.data;
    } catch (error) {
      console.error("Errorediting classRoom:", error);
      return null;
    } finally {
      setInClassLoading(false);
    }
  };

  const deleteClass = async (
    classRoomId: string
  ): Promise<ApiResponse | null> => {
    setInClassLoading(true);
    try {
      const response = await axiosClient.delete<ApiResponse>(
        `classes/${classRoomId}`
      );
      if (response.data.succeeded) {
        setClasses((prev) =>
          prev.filter((classRoom) => classRoom.id !== classRoomId)
        );
      }
      return response.data;
    } catch (error) {
      console.error("Error deleting classRoom:", error);
    } finally {
      setInClassLoading(false);
    }
    return null;
  };

  if (isLoading) {
    return;
  }

  if (error) {
    return;
  }

  return (
    <Flex flexDirection="column" p={5} h={"100%"}>
      <ClassModal
        isOpen={isOpen}
        onClose={onClose}
        classRoomToEdit={classRoomToEdit}
        addClass={addClass}
        deleteClass={deleteClass}
        editClass={editClass}
        classLevel={classLevel}
      />
      {levels.length > 0 ? (
        <Flex flexDir={"column"} height={"100%"}>
          {subjects.length > 0 ? (
            <Flex flexDir={"column"}>
              <HeaderDivider heading={"Add and Assign Class Subjects"} />

              <Flex gap={5} wrap={"wrap"} w={"full"}>
                <Clave header={"Classes"} w="fit-content" maxW="fit-content">
                  <Tabs
                    mt={2}
                    isFitted
                    variant="enclosed"
                    size={"sm"}
                    colorScheme="green"
                    onChange={handleTabChange}
                  >
                    <TabList mb="1em">
                      {levels.map((level) => (
                        <Tab
                          display={"flex"}
                          justifyContent={"space-between"}
                          whiteSpace={"nowrap"}
                          key={level.id}
                        >
                          <Text mr={2}>{level.levelName}</Text>
                          {level.id === levels[activeTabIndex].id && (
                            <IconButton
                              colorScheme="green"
                              size={"xs"}
                              icon={<BiPlus />}
                              aria-label={"add class"}
                              onClick={() => handleAddClass(level)}
                            />
                          )}
                        </Tab>
                      ))}
                    </TabList>
                    <TabPanels>
                      {levels.map((level) => (
                        <TabPanel key={level.id} p={0}>
                          {classes
                            .filter((c) => c.levelId === level.id)
                            .map((cls) => (
                              <ClassCard
                                selected={cls.id === selectedClass?.id}
                                key={cls.id}
                                classRoom={cls}
                                handleEdit={() => handleEditClass(cls)}
                                handleClick={() => handleClassBoxClick(cls)}
                              />
                              // <BoxList
                              //   handleClick={() => handleClassBoxClick(cls)}
                              //   key={cls.id}
                              //   text={cls.className}
                              //   selected={cls.id === selectedClass?.id}
                              // />
                            ))}
                        </TabPanel>
                      ))}
                    </TabPanels>
                  </Tabs>
                </Clave>

                <Clave header="Subjects" w="400px" icon={<BiBook />}>
                  <Flex
                    my={2}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                  >
                    <Tag colorScheme="brand" py={1}>
                      {`${levels[activeTabIndex].levelName} Cognitive Subjects`}
                    </Tag>
                    <Button
                      onClick={handleAddAllSubjects}
                      size={"xs"}
                      colorScheme="brand"
                      variant={"link"}
                      isDisabled={
                        subjects.filter(
                          (s) => s.levelId === levels[activeTabIndex].id
                        ).length === 0 || !selectedClass
                      }
                    >
                      <ArrowForwardIcon />
                      Assign All
                    </Button>
                  </Flex>
                  {subjects
                    .filter((s) => s.levelId === levels[activeTabIndex].id)
                    .map((s) => (
                      <BoxList
                        key={s.id}
                        text={s.subjectName}
                        handleClick={() => handleSubjectClick(s)}
                        selected={
                          editedSubjects.some(
                            (subject) => subject.id === s.id
                          ) || false
                        }
                      />
                    ))}
                  <Divider my={5} />
                  <Flex
                    my={2}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                  >
                    <Tag colorScheme="brand" py={1}>
                      {`${levels[activeTabIndex].levelName} Behavioral Subjects`}
                    </Tag>
                    <Button
                      onClick={handleAddAllBehavioralSubjects}
                      size={"xs"}
                      colorScheme="brand"
                      variant={"link"}
                      isDisabled={
                        behavioralSubjects.filter(
                          (s) => s.levelId === levels[activeTabIndex].id
                        ).length === 0 || !selectedClass
                      }
                    >
                      <ArrowForwardIcon />
                      Assign All
                    </Button>
                  </Flex>
                  {behavioralSubjects
                    .filter((s) => s.levelId === levels[activeTabIndex].id)
                    .map((s) => (
                      <BoxList
                        key={s.id}
                        text={s.name}
                        handleClick={() => handleBehavioralSubjectClick(s)}
                        selected={
                          editedBehavioralSubjects.some(
                            (subject) => subject.id === s.id
                          ) || false
                        }
                      />
                    ))}
                </Clave>

                <Clave
                  header={"Assigned Class Subjects"}
                  w="400px"
                  icon={<ArrowForwardIcon />}
                >
                  <Flex
                    my={2}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                  >
                    <Flex>
                      {selectedClass && (
                        <Tag colorScheme="brand" py={1}>
                          {selectedClass.className}
                        </Tag>
                      )}
                    </Flex>

                    <Button
                      isDisabled={!selectedClass || !unsavedChanges}
                      onClick={handleSave}
                      size="xs"
                      colorScheme="green"
                      isLoading={inClassLoading}
                    >
                      <ArrowDownIcon />
                      Save
                    </Button>
                  </Flex>

                  {editedSubjects.map((s) => (
                    <BoxList key={s.id} text={s.subjectName} selected />
                  ))}
                  {editedBehavioralSubjects.map((s) => (
                    <BoxList key={s.id} text={s.name} selected />
                  ))}
                </Clave>
              </Flex>
            </Flex>
          ) : (
            <Empty
              hasButton
              buttonText="Go to Subjects Settings"
              handleClick={() => navigate("/settings/subject")}
              message="To create classes and assign subjects to classes, add 'Subjects'"
            />
          )}
        </Flex>
      ) : (
        <Empty
          hasButton
          message="Something will show up here when you set up School Levels"
          buttonText="Go to Levels Settings"
          handleClick={() => navigate("/settings/level")}
        />
      )}
    </Flex>
  );
}
