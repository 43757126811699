import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useUserContext } from '../Contexts/userContext';
import { loginRoute } from './constants';


const PrivateRoute: React.FC = () => {
  const { user, isLoading } = useUserContext()

  if(isLoading){
    return <>Loading...</>
  }

  return user ? <Outlet /> : <Navigate to={loginRoute} />;
};

export default PrivateRoute;