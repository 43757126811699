import { Flex, Center, Icon, Stack, Heading, Text } from "@chakra-ui/react";
import { IconType } from "react-icons";

interface Props {
  icon: IconType;
  title: string;
  value: number;
  borderColor?: string;
  bg?: string;
}

export default function StatCard({ icon, title, value, borderColor, bg }: Props) {
  return (
    <Flex alignItems={"center"} gap={3}>
      <Center
        boxSize={"70px"}
        borderWidth={1}
        borderColor={ borderColor || "rgb(79, 213, 255)"}
        borderRadius={"2xl"}
        bg={bg || "rgba(79, 213, 255, 0.7)"}
      >
        <Icon
          aria-label="total students in the school"
          fontSize={"xl"}
          as={icon}
        />
      </Center>
      <Stack justifyContent={"center"} gap={0} p={3}>
        <Text p={0} m={0}>
          {title}
        </Text>
        <Heading p={0} m={0}>
          {value}
        </Heading>
      </Stack>
    </Flex>
  );
}
