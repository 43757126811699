import React, { useState, ChangeEvent, useEffect } from "react";
import { ApiDataResponse, ApiResponse, Level } from "../../../../services/Dtos";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  Flex,
  useDisclosure,
  CircularProgress,
  VStack,
  Select,
} from "@chakra-ui/react";
import { BiTrash } from "react-icons/bi";
import {
  validateGreaterThanZero,
  validateRequired,
} from "../../../../services/validation";
import DeleteAlertDialogModal from "../../../ui/DeleteAlertDialogModal";
import useToaster from "../../../../hooks/useToaster";
import ErrorAlert from "../../../ui/ErrorAlert";
import { useSchoolsContext } from "../../../../Contexts/schoolContext";

interface AddGuardianModalProps {
  isOpen: boolean;
  onClose: () => void;
  levelToEdit: Level | null;
  addLevel: (level: Level) => Promise<ApiDataResponse<Level> | null>;
  deleteLevel: (levelId: string) => Promise<ApiResponse | null>;
  editLevel: (level: Level, levelId: string) => Promise<ApiResponse | null>;
  nextOrder: number;
}

const LevelModal: React.FC<AddGuardianModalProps> = ({
  isOpen,
  onClose,
  levelToEdit,
  addLevel,
  deleteLevel,
  editLevel,
  nextOrder,
}) => {

  const initialLevelFormState: Level = {
    id: "",
    promotionCriteriaScore: 0,
    levelName: "",
    schoolId: "",
    order: 0,
    nextTermFee: 0,
    adminId: "",
  };
  const { users } = useSchoolsContext();
  const { showSuccessToast, showErrorToast } = useToaster();
  const [inGradeModalLoading, setInGradeModalLoading] = useState(false);
  const [inGradeModalError, setInGradeModalError] = useState<
    string | undefined
  >(undefined);

  const {
    isOpen: deleteIsOpen,
    onOpen,
    onClose: deleteOnClose,
  } = useDisclosure();

  const [levelFormState, setLevelFormState] = useState<Level>(
    initialLevelFormState
  );

  useEffect(() => {
    if (levelToEdit) {
      setLevelFormState(levelToEdit);
    } else {
      setLevelFormState({...initialLevelFormState, order:nextOrder});
    }
  }, [levelToEdit]);

  const [levelFormErrors, setLevelFormErrors] = useState<{
    [key: string]: string;
  }>({});

  const validateLevelForm = (): boolean => {
    const currentLevelFormErrors: { [key: string]: string } = {};

    if (!validateRequired(levelFormState.levelName)) {
      currentLevelFormErrors.levelName = "Level name is required";
    }
    if (!validateGreaterThanZero(levelFormState.promotionCriteriaScore)) {
      currentLevelFormErrors.promotionCriteriaScore =
        "Minimum Promotion Score Should be More Than Zero";
    }

    if (!validateGreaterThanZero(levelFormState.order)) {
      currentLevelFormErrors.order = "Level Order Should be More Than Zero";
    }

    setLevelFormErrors(currentLevelFormErrors);
    return Object.keys(currentLevelFormErrors).length === 0;
  };

  const handleAddLevel = async () => {
    if (!validateLevelForm()) {
      console.log(levelFormErrors);
      return;
    }

    setInGradeModalLoading(true);

    try {
      if (levelToEdit) {
        const response = await editLevel(levelFormState, levelToEdit.id);
        if (response?.succeeded) {
          showSuccessToast(response?.message || "");
          onClose();
        } else {
          console.log(response?.message);
          showErrorToast(response?.message || "");
          console.info("Error updating level");
        }
      } else {
        const response = await addLevel(levelFormState);
        if (response?.succeeded) {
          showSuccessToast(response.message);
          setLevelFormState({...initialLevelFormState, order:nextOrder});
          onClose();
        } else {
          setInGradeModalError(response?.message);
          showErrorToast(response?.message);
          console.info("Error adding level");
        }
      }
    } catch (error) {
      showErrorToast();
      console.error("Unexpected error:", error);
    } finally {
      setInGradeModalLoading(false);
    }
  };

  const handleDeleteLevel = async () => {
    if (levelToEdit) {
      setInGradeModalLoading(true);
      const response = await deleteLevel(levelToEdit?.id);
      if (response?.succeeded) {
        onClose();
        showSuccessToast(response?.message || "");
      } else {
        showErrorToast(response?.message || "");
      }
    }
    setInGradeModalLoading(false);
  };

  const handleLevelChange = (
    event: ChangeEvent<HTMLSelectElement | HTMLInputElement>
  ) => {
    const { name, value, type } = event.target;

    if (type === "checkbox") {
      const { checked } = event.target as HTMLInputElement;
      setLevelFormState((prevState) => ({
        ...prevState,
        [name]: checked,
      }));
    } else {
      setLevelFormState((prevState) => ({
        ...prevState,
        [name]:
          name === "order" || name === "nextTermFee"
            ? parseInt(value, 10)
            : value,
      }));
    }
  };
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick={false}
      motionPreset="slideInBottom"
      scrollBehavior={"inside"}
      size={"xl"}
    >
      <DeleteAlertDialogModal
        handleDelete={handleDeleteLevel}
        onClose={deleteOnClose}
        isOpen={deleteIsOpen}
      />
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {levelToEdit ? "Edit Level" : "Add New Level"}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack gap={0} alignItems={"center"}>
            {inGradeModalError && <ErrorAlert message={inGradeModalError} />}
            <FormControl mb={4} isRequired>
              <FormLabel>Level Name</FormLabel>
              <Input
                name="levelName"
                value={levelFormState.levelName}
                placeholder="e.g (Primary School)"
                onChange={handleLevelChange}
                isInvalid={!!levelFormErrors.levelName}
              />
            </FormControl>
            <FormControl mb={4} isRequired>
              <FormLabel>Minimum Promotion Score</FormLabel>
              <Input
                type="number"
                name="promotionCriteriaScore"
                value={levelFormState.promotionCriteriaScore}
                onChange={handleLevelChange}
                isInvalid={!!levelFormErrors.promotionCriteriaScore}
              />
            </FormControl>
            <FormControl mb={4}>
              <FormLabel>Next Term Fees</FormLabel>
              <Input
                type="number"
                name="nextTermFee"
                value={levelFormState.nextTermFee}
                onChange={handleLevelChange}
              />
            </FormControl>
            <FormControl mb={4}>
              <FormLabel>Level Admin</FormLabel>
              <Select
                name="adminId"
                value={levelFormState.adminId}
                onChange={handleLevelChange}
                placeholder="Select Admin"
              >
                {users
                  .filter((u) => u.role === 3)
                  .map((u) => (
                    <option
                      key={u.id}
                      value={u.id}
                    >{`${u.firstName} ${u.lastName}`}</option>
                  ))}
              </Select>
            </FormControl>
            <FormControl mb={4} isRequired>
              <FormLabel>Order</FormLabel>
              <Input
                type="number"
                name="order"
                value={levelFormState.order}
                onChange={handleLevelChange}
                isInvalid={!!levelFormErrors.order}
              />
            </FormControl>
          </VStack>
        </ModalBody>
        <ModalFooter alignSelf={"center"}>
          {inGradeModalLoading ? (
            <CircularProgress isIndeterminate color={"brand.300"} />
          ) : (
            <Flex gap={5} justifyContent={"center"}>
              <Button
                colorScheme="brand"
                onClick={handleAddLevel}
                isLoading={inGradeModalLoading}
              >
                Done
              </Button>

              {levelToEdit && (
                <Button
                  isLoading={inGradeModalLoading}
                  variant={"ghost"}
                  colorScheme="red"
                  leftIcon={<BiTrash />}
                  onClick={onOpen}
                >
                  Delete Level
                </Button>
              )}
            </Flex>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default LevelModal;
