import {
  Box,
  VStack,
  Text,
  Input,
  Button,
  Flex,
  InputGroup,
  InputRightElement,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BiLockOpenAlt } from "react-icons/bi";
import { RiAccountCircleLine } from "react-icons/ri";
import {
  validateEmail,
  validatePassword,
  validateRequired,
} from "../../services/validation";
import { ApiDataResponse, LoginRequestDto, AppUser } from "../../services/Dtos";
import { useUserContext } from "../../Contexts/userContext";
import { loginEndpoint } from "../../services/constants";
import useToaster from "../../hooks/useToaster";
import useSound from "use-sound";
import useAxios from "../../hooks/useAxios";

function LoginForm() {
  const axiosClient = useAxios();
  const { setUser, user, setIsLoading, isLoading } = useUserContext();
  const [loginData, setLoginData] = useState<LoginRequestDto>({
    username: "",
    password: "",
  });

  const { showSuccessToast, showErrorToast } = useToaster();

  const [play] = useSound("../../../../welcome-sound-MP3.mp3", {
    volume: 0.2,
  });

  const navigate = useNavigate();

  const [show, setShow] = useState(false);
  const handleShowClick = () => setShow(!show);

  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const validForm = (): boolean => {
    const currentErrors: { [key: string]: string } = {};

    if (
      !validateRequired(loginData.username) ||
      !validateEmail(loginData.username)
    ) {
      currentErrors.email = "Valid Email is required";
    }

    if (
      !validateRequired(loginData.password) ||
      !validatePassword(loginData.password)
    ) {
      currentErrors.password = "Password must be at least 8 characters";
    }

    setErrors(currentErrors);
    return Object.keys(currentErrors).length === 0;
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setLoginData((prevData) => ({ ...prevData, [name]: value }));
    console.log(loginData);
  };

  const handleLogin = async () => {
    try {
      if (validForm()) {
        setIsLoading(true);
        const result = await axiosClient.post<ApiDataResponse<AppUser>>(
          loginEndpoint,
          loginData
        );
        if (result?.data.succeeded) {
          console.log(result.data);
          localStorage.setItem("token", result?.data.data.token);
          localStorage.setItem("user", JSON.stringify(result?.data.data));
          setUser(result?.data.data);
          showSuccessToast(result?.data.message || "");

          navigate("/");
          play();
        } else {
          showErrorToast(result?.data.message || "");
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (user) {
      console.log("User state has been updated:", user);
    }
  }, [user]);

  return (
    <Flex flexDirection={"column"} w={"full"} h={"full"}>
      <Box
        flex={"1"}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Box
          p={5}
          borderWidth={[0, null, 1]}
          borderRadius="lg"
          width="100%"
          maxWidth="500px"
        >
          <VStack spacing={4}>
            <Text fontSize="2xl">Login</Text>

            <Input
              placeholder="Email *"
              name="username"
              value={loginData.username}
              onChange={handleChange}
              isInvalid={!!errors.email}
            />
            {errors.email && (
              <Box
                w={"full"}
                textAlign={"left"}
                color="red.500"
                fontSize={"sm"}
              >
                {errors.email}
              </Box>
            )}
            <InputGroup>
              <Input
                type={show ? "text" : "password"}
                placeholder="Password *"
                name="password"
                value={loginData.password}
                onChange={handleChange}
                isInvalid={!!errors.password}
              />
              <InputRightElement width="4.5rem">
                <Button h="1.75rem" size="sm" onClick={handleShowClick}>
                  {show ? "Hide" : "Show"}
                </Button>
              </InputRightElement>
            </InputGroup>
            {errors.password && (
              <Box
                w={"full"}
                textAlign={"left"}
                color="red.500"
                fontSize={"sm"}
              >
                {errors.password}
              </Box>
            )}
            <Button
              w={"full"}
              onClick={handleLogin}
              isDisabled={isLoading}
              colorScheme="brand"
              isLoading={isLoading}
            >
              Login
            </Button>
            {/* <Button
              w={"full"}
              onClick={handleRefresh}
              isDisabled={loading}
              colorScheme="brand"
            >
              Refresh Token
            </Button> */}
            <Flex mt={5} w={"full"} justifyContent={"space-between"}>
              <Button
                size={"sm"}
                leftIcon={<RiAccountCircleLine />}
                isDisabled={isLoading}
                variant={"link"}
                onClick={() => navigate("/auth/register")}
              >
                Create Account
              </Button>
              <Button
                size={"sm"}
                leftIcon={<BiLockOpenAlt />}
                isDisabled={isLoading}
                variant={"link"}
                onClick={() => navigate("/auth/forgotpassword")}
              >
                Forgot Password
              </Button>
            </Flex>

            {/* {error && <Box color="red.500">{error}</Box>}
            {success && <Box color="green.500">{success}</Box>} */}
          </VStack>
        </Box>
      </Box>
    </Flex>
  );
}

export default LoginForm;
