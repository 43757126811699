export const validateEmail = (email: string): boolean => {
  const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return re.test(String(email).toLowerCase());
};

export const validatePassword = (password: string): boolean => {
  const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[\W_]).{8,}$/;
  return passwordRegex.test(password);
};

export const validateConfirmPassword = (
  password: string,
  comparer: string
): boolean => {
  return password === comparer; // Example: Password must be at least 6 characters
};

export const validateRequired = (value: string | null | undefined): boolean => {
  return value != null && value.trim() !== "";
};

export const validateGreaterThanZero = (value: number): boolean => {
  return value > 0;
};

export const validateNigerianPhoneNumber = (value: string): boolean => {
  const phoneRegex =
    /^(\+234|234)?(0?\d{10}|\d{10})$|^(07|08|09)\d{9}$|^[789]\d{9}$/;
  return phoneRegex.test(value);
};

export const validateGender = (value: number | undefined | string): boolean => {
  return value === 1 || value === 2 || value === 3;
};

export const validateStatus = (value: number | undefined | string): boolean => {
  return (
    value === 0 || value === 1 || value === 2 || value === 3 || value === 4
  );
};

export const validateRole = (value: number | undefined | string): boolean => {
  return value === 0 || value === 1 || value === 2 || value === 3;
};

export const validateShortName = (value: string): boolean => {
  return value.length < 6;
};

export const sanitizeUrl = (url: string): string => {
  if (!/^https?:\/\//i.test(url)) {
    return `http://${url}`;
  }
  return url;
};

export const validateUrl = (url: string): boolean => {
  try {
    new URL(url);
    return true;
  } catch (_) {
    return false;
  }
};
