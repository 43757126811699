import React, {
  createContext,
  useState,
  useEffect,
  ReactNode,
  useContext,
} from "react";
import { ApiDataResponse, Class } from "../services/Dtos";
import { useUserContext } from "./userContext";
import useAxios from "../hooks/useAxios";

interface ClassesContextType {
  classes: Class[];
  setClasses: React.Dispatch<React.SetStateAction<Class[]>>;
  error: string | null;
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  selectedClass: Class | null;
  setSelectedClass: React.Dispatch<React.SetStateAction<Class | null>>;
}

interface Props {
  children: ReactNode;
}

const ClassesContext = createContext<ClassesContextType | undefined>(undefined);

export const ClassesProvider = ({ children }: Props) => {
  const [classes, setClasses] = useState<Class[]>([]);
  const [selectedClass, setSelectedClass] = useState<Class | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const { user, isLoading: userLoading } = useUserContext();
  const axiosClient = useAxios();

  useEffect(() => {
    if (user && !userLoading) {
      const getClasses = async () => {
        setIsLoading(true);
        try {
          //fetch classes
          const classesResponse = await axiosClient.get<
            ApiDataResponse<Class[]>
          >("classes", { headers: { SchoolId: user.schoolId } });
          if (classesResponse.data?.succeeded) {
            const classes = classesResponse.data.data;

            setClasses(classes);
          } else {
            setError(
              `Error fetching classes: ${classesResponse.data?.message}`
            );
          }
        } catch (error) {
          console.error("Failed to fetch classes or subjects:", error);
          setError("Failed to fetch classes or subjects");
        } finally {
          setIsLoading(false);
        }
      };

      getClasses();
    }
  }, [user, userLoading]);

  return (
    <ClassesContext.Provider
      value={{
        classes,
        setClasses,
        selectedClass,
        setSelectedClass,
        error,
        isLoading,
        setIsLoading,
      }}
    >
      {children}
    </ClassesContext.Provider>
  );
};

export const useClassesContext = () => {
  const context = useContext(ClassesContext);
  if (!context) {
    throw new Error("useClassesContext must be used within a ClassProvider");
  }
  return context;
};
