import React from 'react';
import { Button, useColorMode } from '@chakra-ui/react';
import {SunIcon, MoonIcon } from "@chakra-ui/icons";

const MobileColorModeSwitch: React.FC = () => {
  const { colorMode, toggleColorMode } = useColorMode();

  return (
    
    <Button
      aria-label="Toggle color mode"
      leftIcon={colorMode === 'light' ? <MoonIcon/> : <SunIcon/>}
      onClick={toggleColorMode}
    >{colorMode === 'light' ? "Dark Mode" : "Light Mode"}</Button>
  );
};

export default MobileColorModeSwitch;