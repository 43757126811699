import { Flex } from "@chakra-ui/react";
import Blox from "../../ui/Blox";
import { BiCalendarEvent, BiCube, BiGrid, BiUser } from "react-icons/bi";

interface Props {
  currentSessionName: string | undefined;
  currentTermName: string | undefined;
  selectedClassName: string | undefined;
  selectedStudentName: string | undefined;
}

export default function ResultTableInfo({
  currentSessionName,
  currentTermName,
  selectedClassName,
  selectedStudentName,
}: Props) {
  
  return (
    <Flex
    
   
      gap={5}
      mb={5}
      justifyContent={"space-between"}
      alignItems={"center"}
      wrap={"wrap"}
    >
      <Flex gap={2} wrap={"wrap"}>
        <Blox
          icon={BiCalendarEvent}
          header={"Session"}
          body={currentSessionName}
        />
        <Blox icon={BiCube} header={"Term"} body={currentTermName} />
        {selectedClassName && (
          <Blox
            icon={BiGrid}
            header={"Class"}
            body={selectedClassName || "No class selected"}
          />
        )}
        {selectedStudentName && (
          <Blox
            icon={BiUser}
            header={"Student"}
            body={selectedStudentName || "Select a student"}
          />
        )}
      </Flex>
      
    </Flex>
  );
}
