import React, { useState, ChangeEvent, useEffect } from "react";
import { ApiDataResponse, ApiResponse, Level, Subject } from "../../../../services/Dtos";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  Flex,
  useDisclosure,
  CircularProgress,
  VStack,
  Select,
} from "@chakra-ui/react";
import { BiTrash } from "react-icons/bi";
import { validateRequired } from "../../../../services/validation";
import DeleteAlertDialogModal from "../../../ui/DeleteAlertDialogModal";
import useToaster from "../../../../hooks/useToaster";
import ErrorAlert from "../../../ui/ErrorAlert";
import { useSchoolsContext } from "../../../../Contexts/schoolContext";

interface AddGuardianModalProps {
  isOpen: boolean;
  onClose: () => void;
  subjectToEdit: Subject | null;
  addSubject: (subject: Subject) => Promise<ApiDataResponse<Subject> | null>;
  deleteSubject: (subjectId: string) => Promise<ApiResponse | null>;
  editSubject: (subject: Subject, subjectId: string) => Promise<ApiResponse | null>;
  subjectLevel: Level | null;
}

const SubjectModal: React.FC<AddGuardianModalProps> = ({
  isOpen,
  onClose,
  subjectToEdit,
  addSubject,
  deleteSubject,
  editSubject,
  subjectLevel,
}) => {
  const initialSubjectFormState: Subject = {
    id: "",
    subjectName: "",
    description: "",
    levelId: subjectLevel?.id || "",
    schoolId: "",
  };

  const { showSuccessToast, showErrorToast } = useToaster();

  const { levels } = useSchoolsContext();

  const [inGradeModalLoading, setInGradeModalLoading] = useState(false);
  const [inGradeModalError, setInGradeModalError] = useState<
    string | undefined
  >(undefined);

  const {
    isOpen: deleteIsOpen,
    onOpen,
    onClose: deleteOnClose,
  } = useDisclosure();

  const [subjectFormState, setSubjectFormState] = useState<Subject>(
    initialSubjectFormState
  );

  useEffect(() => {
    if (subjectToEdit) {
      setSubjectFormState(subjectToEdit);
    } else {
      setSubjectFormState(initialSubjectFormState);
    }
  }, [subjectToEdit, subjectLevel]);

  const [subjectFormErrors, setSubjectFormErrors] = useState<{
    [key: string]: string;
  }>({});

  const validateSubjectForm = (): boolean => {
    const currentSubjectFormErrors: { [key: string]: string } = {};

    if (!validateRequired(subjectFormState.subjectName)) {
      currentSubjectFormErrors.subjectName = "Subject name is required";
    }
    if (!validateRequired(subjectFormState.levelId)) {
      currentSubjectFormErrors.levelId = "Subject level is required";
    }

    setSubjectFormErrors(currentSubjectFormErrors);
    return Object.keys(currentSubjectFormErrors).length === 0;
  };

  const handleAddSubject = async () => {
    if (!validateSubjectForm()) {
      console.log(subjectFormErrors);
      return;
    }

    setInGradeModalLoading(true);

    try {
      if (subjectToEdit) {
        const response = await editSubject(subjectFormState, subjectToEdit.id);
        if (response?.succeeded) {
          showSuccessToast(response.message || "");
          onClose();
        } else {
          showErrorToast(response?.message || "");
          console.info("Error updating subject");
        }
      } else {
        const response = await addSubject(subjectFormState);
        if (response?.succeeded) {
          showSuccessToast(response.message || "");
          setSubjectFormState(initialSubjectFormState);
          onClose();
        } else {
          setInGradeModalError(response?.message);
          showErrorToast(response?.message || "");
          console.info("Error adding subject");
        }
      }
    } catch (error) {
      showErrorToast();
      console.error("Unexpected error:", error);
    } finally {
      setInGradeModalLoading(false);
    }
  };

  const handleDeleteSubject = async () => {
    if (subjectToEdit) {
      setInGradeModalLoading(true);
      const response = await deleteSubject(subjectToEdit?.id);
      if (response?.succeeded) {
        onClose();
        showSuccessToast(response.message || "");
      } else {
        showErrorToast(response?.message || "");
      }
    }
    setInGradeModalLoading(false);
  };

  const handleSubjectChange = (
    event: ChangeEvent<HTMLSelectElement | HTMLInputElement>
  ) => {
    console.log("subJect Level", subjectLevel);
    console.log("initial Form State", initialSubjectFormState);
    console.log("subject Form State", subjectFormState);
    const { name, value, type } = event.target;

    if (type === "checkbox") {
      const { checked } = event.target as HTMLInputElement;
      setSubjectFormState((prevState) => ({
        ...prevState,
        [name]: checked,
      }));
    } else {
      setSubjectFormState((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick={false}
      motionPreset="slideInBottom"
      scrollBehavior={"inside"}
      size={"xl"}
    >
      <DeleteAlertDialogModal
        handleDelete={handleDeleteSubject}
        onClose={deleteOnClose}
        isOpen={deleteIsOpen}
      />
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {subjectToEdit ? "Edit Subject" : "Add New Subject"}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack gap={0} alignItems={"center"}>
            {inGradeModalError && <ErrorAlert message={inGradeModalError} />}
            <FormControl mb={4} isRequired>
              <FormLabel>Subject Name</FormLabel>
              <Input
                name="subjectName"
                value={subjectFormState.subjectName}
                placeholder="Subject Name"
                onChange={handleSubjectChange}
                isInvalid={!!subjectFormErrors.subjectName}
              />
            </FormControl>

            {subjectToEdit && (
              <FormControl mb={4} isRequired>
                <FormLabel>Subject Level</FormLabel>
                <Select
                  name="levelId"
                  value={subjectFormState.levelId}
                  placeholder="Select Subject Level"
                  onChange={handleSubjectChange}
                  isInvalid={!!subjectFormErrors.levelId}
                >
                  {levels.map((l) => (
                    <option key={l.id} value={l.id}>
                      {l.levelName}
                    </option>
                  ))}
                </Select>
              </FormControl>
            )}

            <FormControl mb={4}>
              <FormLabel>Description</FormLabel>
              <Input
                name="description"
                value={subjectFormState.description}
                placeholder="Subject Description"
                onChange={handleSubjectChange}
              />
            </FormControl>
          </VStack>
        </ModalBody>
        <ModalFooter alignSelf={"center"}>
          {inGradeModalLoading ? (
            <CircularProgress isIndeterminate color={"brand.300"} />
          ) : (
            <Flex gap={5} justifyContent={"center"}>
              <Button
                colorScheme="brand"
                onClick={handleAddSubject}
                isLoading={inGradeModalLoading}
              >
                Done
              </Button>

              {subjectToEdit && (
                <Button
                  isLoading={inGradeModalLoading}
                  variant={"ghost"}
                  colorScheme="red"
                  leftIcon={<BiTrash />}
                  onClick={onOpen}
                >
                  Delete Subject
                </Button>
              )}
            </Flex>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default SubjectModal;
