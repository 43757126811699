import {
  Badge,
  Box,
  Table,
  Tbody,
  Td,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import { brandTransparentColor } from "../../../../services/constants";
import { AssessmentType } from "../../../../services/Dtos";
import { useSchoolsContext } from "../../../../Contexts/schoolContext";

interface Props {
  assessmentType: AssessmentType;
  handleClick: () => void;
}

export default function AssessmentTypeCard({
  assessmentType,
  handleClick,
}: Props) {
  const altColor = useColorModeValue("gray.100", "gray.700");

  const { levels } = useSchoolsContext();

  return (
    <Box
      w={"350px"}
      bg={altColor}
      p={3}
      borderRadius={"lg"}
      borderWidth={1}
      minW={"270px"}
      transition={"300ms"}
      _hover={{
        backgroundColor: brandTransparentColor,

        cursor: "pointer",
        transform: "scale(1.09)",
      }}
      onClick={handleClick}
      position={"relative"}
    >
      <Table variant={"simple"} size={"xs"} fontSize={"sm"}>
        <Tbody>
          <Tr>
            <Td >Short Name</Td>
            <Td textAlign={"right"} fontWeight={"bold"} pl={5}>
              <Badge variant={"outline"} colorScheme={"brand"}>
                {assessmentType.shortName}
              </Badge>
            </Td>
          </Tr>

          <Tr>
            <Td>Full Name</Td>
            <Td textAlign={"right"} fontWeight={"bold"} pl={5}>
              {assessmentType.name}
            </Td>
          </Tr>
          <Tr>
            <Td>Max Score</Td>
            <Td textAlign={"right"} fontWeight={"bold"} pl={5}>
              {assessmentType.maxScore}
            </Td>
          </Tr>
          <Tr>
            <Td>Level</Td>
            <Td textAlign={"right"} fontWeight={"bold"} pl={5}>
              {levels.find((l) => l.id === assessmentType.levelId)?.levelName}
            </Td>
          </Tr>
          <Tr>
            <Td>Order</Td>
            <Td textAlign={"right"} fontWeight={"bold"} pl={5}>
              {assessmentType.order}
            </Td>
          </Tr>
        </Tbody>
      </Table>
    </Box>
  );
}
