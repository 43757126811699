import { useState } from "react";
import {
  ApiResponse,
  RegisterResponseDto,
  LoginRequestDto,
  ForgotPasswordRequestDto,
  ResetPasswordRequestDto,
  ApiDataResponse,
  AppUser,
} from "../services/Dtos";
import useAxios from "./useAxios";

const useAuth = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const axiosClient = useAxios();

  const register = async (data: any) => {
    setLoading(true);
    setError(null);
    try {
      const response = await axiosClient.post<RegisterResponseDto>(
        "authentication/register",
        data
      );
      console.log(response.data);
      setLoading(false);
      return response.data;
    } catch (err: any) {
      setError(err.response?.data?.message || "Registration failed");
      setLoading(false);
      return null;
    }
  };

  const login = async (loginRequest: LoginRequestDto) => {
    console.log(loginRequest);
    setLoading(true);
    setError(null);
    try {
      const response = await axiosClient.post<ApiDataResponse<AppUser>>(
        "authentication/login",
        loginRequest
      );
      setLoading(false);
      console.log(response.data);
      return response.data;
    } catch (err: any) {
      setError(err.response?.data?.message || "Login failed");
      setLoading(false);
      return null;
    }
  };

  const forgotPassword = async (
    forgotPasswordRequest: ForgotPasswordRequestDto
  ) => {
    console.log(forgotPasswordRequest.email);
    setLoading(true);
    setError(null);
    try {
      const response = await axiosClient.post<ApiResponse>(
        "authentication/forgotpassword",
        forgotPasswordRequest
      );
      setLoading(false);
      console.log(response.data);
      return response.data;
    } catch (err: any) {
      setError(err.response?.data?.message || "Login failed");
      setLoading(false);
      return null;
    }
  };

  const resetPassword = async (
    resetPasswordRequest: ResetPasswordRequestDto
  ) => {
    console.log(resetPasswordRequest);
    setLoading(true);
    setError(null);
    try {
      const response = await axiosClient.post<ApiResponse>(
        "authentication/resetpassword",
        resetPasswordRequest
      );
      setLoading(false);
      console.log(response.data);
      return response.data;
    } catch (err: any) {
      setError(err.response?.data?.message || "Request Failed");
      setLoading(false);
      return null;
    }
  };

  const refreshToken = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await axiosClient.post<ApiDataResponse<AppUser>>(
        "authentication/refreshtoken"
      );
      setLoading(false);
      console.log(response.data);
      return response.data;
    } catch (err: any) {
      setError(err.response?.data?.message || "Request Failed");
      setLoading(false);
      return null;
    }
  };

  return {
    register,
    login,
    forgotPassword,
    resetPassword,
    refreshToken,
    loading,
    error,
  };
};

export default useAuth;
