import {
  Box,
  Button,
  Flex,
  Input,
  InputGroup,
  InputRightElement,
  VStack,
  Text,
} from "@chakra-ui/react";
import useAuth from "../../hooks/useAuth";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ResetPasswordRequestDto } from "../../services/Dtos";
import { validatePassword, validateRequired } from "../../services/validation";
import { useUserContext } from "../../Contexts/userContext";
import useToaster from "../../hooks/useToaster";
import useSound from "use-sound";

export default function ResetPasswordForm() {
  const [play] = useSound("../../../../welcome-sound-MP3.mp3", {
    volume: 0.2,
  });
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };

  const { resetPassword, login, loading } = useAuth();
  const { setUser } = useUserContext();
  const [resetPasswordSuccess, setresetPasswordSuccess] =
    useState<boolean>(false);
  const [, setLoginSuccess] = useState<boolean>(false);
  const query = useQuery();
  const navigate = useNavigate();
  const [resetPasswordData, setResetPasswordData] =
    useState<ResetPasswordRequestDto>({
      newPassword: "",
      confirmNewPassword: "",
      email: "",
      token: "",
    });

  const { showSuccessToast, showErrorToast } = useToaster();

  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setResetPasswordData((prevData) => ({ ...prevData, [name]: value }));
    console.log(resetPasswordData);
  };

  const validateForm = (): boolean => {
    const currentErrors: { [key: string]: string } = {};
    if (
      !validateRequired(resetPasswordData.newPassword) ||
      !validatePassword(resetPasswordData.newPassword)
    ) {
      currentErrors.password =
        "Password must have 8 characters including 1 number, 1 uppercase letter, 1 lowercase letter and 1 special character";
    }
    if (
      resetPasswordData.newPassword !== resetPasswordData.confirmNewPassword
    ) {
      currentErrors.confirmPassword = "Passwords do not match";
    }
    setErrors(currentErrors);
    return Object.keys(currentErrors).length === 0;
  };

  const [show, setShow] = useState(false);
  const handleShowClick = () => setShow(!show);

  const handleChangePassword = async () => {
    if (validateForm()) {
      const email = query.get("email");
      let token = query.get("token");
      if (!token || !email) {
        showErrorToast("Invalid Password Reset Link");
        return;
      }
      try {
        token = token.replace(/ /g, "+");
        resetPasswordData.email = email;
        resetPasswordData.token = token;
        const result = await resetPassword(resetPasswordData);
        console.log(result);
        if (result?.succeeded) {
          setresetPasswordSuccess(true);
          const loginResult = await login({
            username: email,
            password: resetPasswordData.newPassword,
          });
          if (loginResult) {
            setUser(loginResult.data);
            localStorage.setItem("token", loginResult?.data.token);
            localStorage.setItem("user", JSON.stringify(loginResult?.data));

            showSuccessToast(loginResult.message || "");
            setLoginSuccess(true);
            // Redirect to dashboard
            navigate("/");
            play();
          }
        }
      } catch (error) {
        showErrorToast();
      }
    }
  };

  return (
    <Flex flexDirection={"column"} w={"full"} mt={-20}>
      <Box
        flex={"1"}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Box
          p={5}
          borderWidth={[0, null, 1]}
          borderRadius="lg"
          width="100%"
          maxWidth="500px"
        >
          <VStack spacing={4}>
            <Text mb={5} fontSize="2xl">
              Choose a new Password
            </Text>

            <InputGroup>
              <Input
                type={show ? "text" : "password"}
                placeholder="Password *"
                name="newPassword"
                value={resetPasswordData.newPassword}
                onChange={handleChange}
                isInvalid={!!errors.password}
              />
              <InputRightElement width="4.5rem">
                <Button h="1.75rem" size="sm" onClick={handleShowClick}>
                  {show ? "Hide" : "Show"}
                </Button>
              </InputRightElement>
            </InputGroup>
            {errors.password && (
              <Box
                w={"full"}
                textAlign={"left"}
                color="red.500"
                fontSize={"sm"}
              >
                {errors.password}
              </Box>
            )}
            <InputGroup>
              <Input
                type={show ? "text" : "password"}
                placeholder="Confirm Password *"
                name="confirmNewPassword"
                value={resetPasswordData.confirmNewPassword}
                onChange={handleChange}
                isInvalid={!!errors.confirmPassword}
              />
              <InputRightElement width="4.5rem">
                <Button h="1.75rem" size="sm" onClick={handleShowClick}>
                  {show ? "Hide" : "Show"}
                </Button>
              </InputRightElement>
            </InputGroup>
            {errors.confirmPassword && (
              <Box
                w={"full"}
                textAlign={"left"}
                color="red.500"
                fontSize={"sm"}
              >
                {errors.confirmPassword}
              </Box>
            )}

            <Button
              isLoading={loading}
              loadingText={resetPasswordSuccess ? "Now Logging in" : ""}
              w={"full"}
              onClick={handleChangePassword}
              isDisabled={loading}
              colorScheme="brand"
            >
              Change Password
            </Button>
          </VStack>
        </Box>
      </Box>
    </Flex>
  );
}
