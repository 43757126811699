import { Button, Flex } from "@chakra-ui/react";
import React from "react";
import {
  BiBook,
  BiBulb,
  BiCalendarEvent,
  BiCool,
  BiCube,
  BiGrid,
} from "react-icons/bi";
import { IconType } from "react-icons";
import { LuSchool } from "react-icons/lu";
import { TbSquareLetterA } from "react-icons/tb";
import { Link, useLocation } from "react-router-dom";
import { PiStepsBold } from "react-icons/pi";
import { useUserContext } from "../../../Contexts/userContext";
import { adminPolicy } from "../../../services/constants";

const SettingsTopMenu: React.FC = () => {
  const { user } = useUserContext();
  interface NavLinks {
    Name: string;
    Link: string;
    Icon: IconType;
  }
  const adminSettingsNavLinks: NavLinks[] = [
    { Name: "School", Link: "/settings", Icon: LuSchool },
    { Name: "Session", Link: "session", Icon: BiCalendarEvent },
    { Name: "Term", Link: "term", Icon: BiCube },
    { Name: "Level", Link: "level", Icon: PiStepsBold },
    { Name: "Assessment", Link: "assessment", Icon: BiBulb },
    { Name: "Grade Criteria", Link: "grade", Icon: TbSquareLetterA },

    { Name: "Subject", Link: "subject", Icon: BiBook },
    { Name: "Class", Link: "class", Icon: BiGrid },
    { Name: "User", Link: "user", Icon: BiCool },
  ];

  const viewerSettingsNavLinks: NavLinks[] = [
    { Name: "User", Link: "user", Icon: BiCool },
  ];

  let settingsNavLinks;
  if (user?.role === 3) {
    settingsNavLinks = adminSettingsNavLinks;
  } else {
    settingsNavLinks = viewerSettingsNavLinks;
  }

  const location = useLocation();

  const getPageName = (pathname: string): string => {
    switch (pathname) {
      case "/settings":
        return adminPolicy.includes(user?.role || 0) ? "School" : "User";
      case "/settings/session":
        return "Session";
      case "/settings/term":
        return "Term";
      case "/settings/class":
        return "Class";
      case "/settings/grade":
        return "Grade Criteria";
      case "/settings/level":
        return "Level";
      case "/settings/assessment":
        return "Assessment";
      case "/settings/subject":
        return "Subject";
      case "/settings/user":
        return "User";
      default:
        return "";
    }
  };

  return (
    <Flex
      alignItems={"center"}
      w={"full"}
      justifyContent={"space-between"}
      p={5}
      borderWidth={1}
      borderRadius="lg"
      width="100%"
      gap={5}
    >
      <Flex alignItems={"center"} gap={2} wrap={"wrap"}>
        {settingsNavLinks.map((nav) => (
          <Button
            fontWeight={"normal"}
            justifyContent={"left"}
            leftIcon={<nav.Icon />}
            as={Link}
            key={nav.Name}
            to={nav.Link}
            isActive={getPageName(location.pathname) === nav.Name}
            variant={"outline"}
          >
            {nav.Name}
          </Button>
        ))}
      </Flex>
      <Flex gap={5}></Flex>
    </Flex>
  );
};

export default SettingsTopMenu;
