import {
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  Radar,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { Box, useColorModeValue, Text } from "@chakra-ui/react";
import { SubjectPerformance } from "../../../services/Dtos";
import { getShortName } from "../../../services/Utils";
interface Props{
    subjectData: SubjectPerformance[] | null;
  }
const TopSubjectsChart = ({subjectData}: Props) => {
  

  const bg = useColorModeValue("gray.100", "gray.700");
  const color = useColorModeValue("gray.800", "white");
  // Custom Tooltip Component
  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      return (
        <Box
          bg={bg}
          color={color}
          p={4}
          borderRadius="md"
          boxShadow="md"
          borderWidth={1}
          fontSize={"sm"}
          
        >
          <Text fontWeight="bold">{label}</Text>
          <Text>{`Performance: ${payload[0].value}`}</Text>
        </Box>
      );
    }

    return null;
  };
  

  return (
    <Box width="100%" height="100%">
      <ResponsiveContainer width="100%" minHeight={300}>
        <RadarChart outerRadius="80%" data={subjectData || []}>
          {/* Define gradient */}
          <defs>
            <linearGradient id="colorSubjects" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#C2C0FF" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#A45BE6" stopOpacity={0.8} />
            </linearGradient>
          </defs>

          <PolarGrid />
          <PolarAngleAxis dataKey="subject" fontSize={"12px"} tickFormatter={(value) => getShortName(value)} />
          <PolarRadiusAxis angle={55} domain={[0, 100]} fontSize={"12px"} />
          <Radar
            name="Performance"
            dataKey="score"
            stroke="#5A57FF"
            fill="url(#colorSubjects)"
            fillOpacity={0.8}
          />
          <Tooltip content={<CustomTooltip />}/>
        </RadarChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default TopSubjectsChart;
