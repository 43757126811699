import {Text, Flex, Table, Thead, Tr, Th, Tbody, Td, HStack, Avatar, AvatarBadge, Badge, IconButton } from '@chakra-ui/react'
import { BiEdit } from 'react-icons/bi'
import { genderMap, roleMap } from '../../../../services/constants'
import { useUserContext } from '../../../../Contexts/userContext';
import { useSchoolsContext } from '../../../../Contexts/schoolContext';
import { User } from '../../../../services/Dtos';

interface Props{
    editUser: (user: User) => void
}
export default function UsersTable({editUser }:Props) {
    const { users} = useSchoolsContext();
    const { user } = useUserContext();
  return (
    <Flex gap={5} mb={20}>
        <Table>
          <Thead>
            <Tr>
              <Th>Name</Th>
              <Th>Email</Th>
              <Th>Gender</Th>
              <Th>Role</Th>
              <Th>Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {users
              .filter((u) => u.id !== user?.id)
              .map((u) => (
                <Tr key={u.id}>
                  <Td>
                    <HStack gap={5}>
                      <Avatar
                        size={"sm"}
                        src={u.avatarUrl}
                        name={`${u.firstName} ${u.lastName}`}
                      >
                        <AvatarBadge
                          boxSize="1.25em"
                          bg={u.isActive ? "green.500" : "red.500"}
                        />
                      </Avatar>

                      <Text>{`${u.firstName} ${u.lastName}`}</Text>
                    </HStack>
                  </Td>
                  <Td>{u.email}</Td>
                  <Td>{genderMap[u.gender]}</Td>
                  <Td>
                    <Badge
                      variant={"outline"}
                      colorScheme={roleMap[u.role].color}
                    >
                      {roleMap[u.role].name}
                    </Badge>
                  </Td>
                  <Td>
                    <IconButton onClick={()=>editUser(u)} aria-label="edit user" icon={<BiEdit />} />
                  </Td>
                </Tr>
              ))}
          </Tbody>
        </Table>
      </Flex>
  )
}
