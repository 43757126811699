import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Button,
  Center,
  VStack,
} from "@chakra-ui/react";
import { capitalizeFirstLetter } from "../../services/Utils";

interface Props {
  message?: string;
  handleClick?: () => void;
  status?: "error" | "info" | "warning" | "success" | "loading";
  useButton?: boolean;
}

export default function ErrorAlert({
  message,
  handleClick,
  status,
  useButton,
}: Props) {
  return (
    <Center height="100%" mb={2}>
      <VStack w={"full"} gap={5}>
        <Alert status={status || "error"} minW={"300px"} borderRadius={"md"}>
          <AlertIcon />
          <AlertTitle>{capitalizeFirstLetter(status || "Error")}</AlertTitle>
          <AlertDescription>
            {message || "Something Happened"}
          </AlertDescription>
        </Alert>
        {useButton && <Button onClick={handleClick}>Retry</Button>}
      </VStack>
    </Center>
  );
}
