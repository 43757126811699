import React, { useState, ChangeEvent, useEffect } from "react";
import { ApiDataResponse, Level, BehavioralSubject, ApiResponse } from "../../../../services/Dtos";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  Flex,
  useDisclosure,
  CircularProgress,
  VStack,
  Select,
} from "@chakra-ui/react";
import { BiTrash } from "react-icons/bi";
import { validateRequired } from "../../../../services/validation";
import DeleteAlertDialogModal from "../../../ui/DeleteAlertDialogModal";
import useToaster from "../../../../hooks/useToaster";
import ErrorAlert from "../../../ui/ErrorAlert";
import { useSchoolsContext } from "../../../../Contexts/schoolContext";

interface AddGuardianModalProps {
  isOpen: boolean;
  onClose: () => void;
  behavioralSubjectToEdit: BehavioralSubject | null;
  addBehavioralSubject: (behavioralSubject: BehavioralSubject) => Promise<ApiDataResponse<BehavioralSubject> | null>;
  deleteBehavioralSubject: (behavioralSubjectId: string) => Promise<ApiResponse | null>;
  editBehavioralSubject: (behavioralSubject: BehavioralSubject, behavioralSubjectId: string) => Promise<ApiResponse | null>;
  behavioralSubjectLevel: Level | null;
}

const BehavioralSubjectModal: React.FC<AddGuardianModalProps> = ({
  isOpen,
  onClose,
  behavioralSubjectToEdit,
  addBehavioralSubject,
  deleteBehavioralSubject,
  editBehavioralSubject,
  behavioralSubjectLevel,
}) => {
  const initialBehavioralSubjectFormState: BehavioralSubject = {
    id: "",
    name: "",
    description: "",
    levelId: behavioralSubjectLevel?.id || "",
    schoolId: "",
  };

  const { showSuccessToast, showErrorToast } = useToaster();

  const { levels } = useSchoolsContext();

  const [inGradeModalLoading, setInGradeModalLoading] = useState(false);
  const [inGradeModalError, setInGradeModalError] = useState<
    string | undefined
  >(undefined);

  const {
    isOpen: deleteIsOpen,
    onOpen,
    onClose: deleteOnClose,
  } = useDisclosure();

  const [behavioralSubjectFormState, setBehavioralSubjectFormState] = useState<BehavioralSubject>(
    initialBehavioralSubjectFormState
  );

  useEffect(() => {
    if (behavioralSubjectToEdit) {
      setBehavioralSubjectFormState(behavioralSubjectToEdit);
    } else {
      setBehavioralSubjectFormState(initialBehavioralSubjectFormState);
    }
  }, [behavioralSubjectToEdit, behavioralSubjectLevel]);

  const [behavioralSubjectFormErrors, setBehavioralSubjectFormErrors] = useState<{
    [key: string]: string;
  }>({});

  const validateBehavioralSubjectForm = (): boolean => {
    const currentBehavioralSubjectFormErrors: { [key: string]: string } = {};

    if (!validateRequired(behavioralSubjectFormState.name)) {
      currentBehavioralSubjectFormErrors.name = "Behavioral Subject name is required";
    }
    if (!validateRequired(behavioralSubjectFormState.levelId)) {
      currentBehavioralSubjectFormErrors.levelId = "Behavioral Subject level is required";
    }

    setBehavioralSubjectFormErrors(currentBehavioralSubjectFormErrors);
    return Object.keys(currentBehavioralSubjectFormErrors).length === 0;
  };

  const handleAddBehavioralSubject = async () => {
    if (!validateBehavioralSubjectForm()) {
      console.log(behavioralSubjectFormErrors);
      return;
    }

    setInGradeModalLoading(true);

    try {
      if (behavioralSubjectToEdit) {
        const response = await editBehavioralSubject(behavioralSubjectFormState, behavioralSubjectToEdit.id);
        if (response?.succeeded) {
          showSuccessToast(response?.message || "");
          onClose();
        } else {
          showErrorToast(response?.message || "");
          console.info("Error updating behavioralSubject");
        }
      } else {
        const response = await addBehavioralSubject(behavioralSubjectFormState);
        if (response?.succeeded) {
          showSuccessToast(response?.message || "");
          setBehavioralSubjectFormState(initialBehavioralSubjectFormState);
          onClose();
        } else {
          setInGradeModalError(response?.message);
          showErrorToast(response?.message || "");
          console.info("Error adding behavioralSubject");
        }
      }
    } catch (error) {
      showErrorToast();
      console.error("Unexpected error:", error);
    } finally {
      setInGradeModalLoading(false);
    }
  };

  const handleDeleteBehavioralSubject = async () => {
    if (behavioralSubjectToEdit) {
      setInGradeModalLoading(true);
      const response = await deleteBehavioralSubject(behavioralSubjectToEdit?.id);
      if (response?.succeeded) {
        onClose();
        showSuccessToast(response?.message || "");
      } else {
        showErrorToast(response?.message || "");
      }
    }
    setInGradeModalLoading(false);
  };

  const handleBehavioralSubjectChange = (
    event: ChangeEvent<HTMLSelectElement | HTMLInputElement>
  ) => {
    console.log("subJect Level", behavioralSubjectLevel);
    console.log("initial Form State", initialBehavioralSubjectFormState);
    console.log("behavioralSubject Form State", behavioralSubjectFormState);
    const { name, value, type } = event.target;

    if (type === "checkbox") {
      const { checked } = event.target as HTMLInputElement;
      setBehavioralSubjectFormState((prevState) => ({
        ...prevState,
        [name]: checked,
      }));
    } else {
      setBehavioralSubjectFormState((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick={false}
      motionPreset="slideInBottom"
      scrollBehavior={"inside"}
      size={"xl"}
    >
      <DeleteAlertDialogModal
        handleDelete={handleDeleteBehavioralSubject}
        onClose={deleteOnClose}
        isOpen={deleteIsOpen}
      />
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {behavioralSubjectToEdit ? "Edit Behavioral Subject" : "Add New Behavioral Subject"}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack gap={0} alignItems={"center"}>
            {inGradeModalError && <ErrorAlert message={inGradeModalError} />}
            <FormControl mb={4} isRequired>
              <FormLabel>Behavioral Subject Name</FormLabel>
              <Input
                name="name"
                value={behavioralSubjectFormState.name}
                placeholder="Behavioral Subject Name"
                onChange={handleBehavioralSubjectChange}
                isInvalid={!!behavioralSubjectFormErrors.behavioralSubjectName}
              />
            </FormControl>

            {behavioralSubjectToEdit && (
              <FormControl mb={4} isRequired>
                <FormLabel>BehavioralSubject Level</FormLabel>
                <Select
                  name="levelId"
                  value={behavioralSubjectFormState.levelId}
                  placeholder="Select Behavioral Subject Level"
                  onChange={handleBehavioralSubjectChange}
                  isInvalid={!!behavioralSubjectFormErrors.levelId}
                >
                  {levels.map((l) => (
                    <option key={l.id} value={l.id}>
                      {l.levelName}
                    </option>
                  ))}
                </Select>
              </FormControl>
            )}

            <FormControl mb={4}>
              <FormLabel>Description</FormLabel>
              <Input
                name="description"
                value={behavioralSubjectFormState.description}
                placeholder="Behavioral Subject Description"
                onChange={handleBehavioralSubjectChange}
              />
            </FormControl>
          </VStack>
        </ModalBody>
        <ModalFooter alignSelf={"center"}>
          {inGradeModalLoading ? (
            <CircularProgress isIndeterminate color={"brand.300"} />
          ) : (
            <Flex gap={5} justifyContent={"center"}>
              <Button
                colorScheme="brand"
                onClick={handleAddBehavioralSubject}
                isLoading={inGradeModalLoading}
              >
                Done
              </Button>

              {behavioralSubjectToEdit && (
                <Button
                  isLoading={inGradeModalLoading}
                  variant={"ghost"}
                  colorScheme="red"
                  leftIcon={<BiTrash />}
                  onClick={onOpen}
                >
                  Delete BehavioralSubject
                </Button>
              )}
            </Flex>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default BehavioralSubjectModal;
