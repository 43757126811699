import axios from "axios";
import { refreshTokenEndpoint } from "../services/constants";
import { ApiDataResponse, AppUser } from "../services/Dtos";
import { useUserContext } from "../Contexts/userContext";

const useAxios = () => {
  const { setUser } = useUserContext(); // Get the setUser function from context

  const axiosClient = axios.create({
    baseURL:
      import.meta.env.MODE === "development"
        ? import.meta.env.VITE_LOCAL_BASE_URL
        : import.meta.env.VITE_BASE_URL,
    withCredentials: true,
  });

  axiosClient.interceptors.request.use((config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  });

  axiosClient.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config;
      console.log("Response interceptor entered", error);
      console.log("Response error", error.response);
  
      // Check if error.response exists and the error is a 401 Unauthorized
      if (error.response && error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
  
        try {
          console.log("Attempting to refresh token");
  
          // Attempt to refresh the token
          const { data } = await axiosClient.post<ApiDataResponse<AppUser>>(refreshTokenEndpoint);
          console.log("Token refreshed successfully", data);
  
          // Store the new token and user data
          localStorage.setItem("token", data.data.token);
          localStorage.setItem("user", JSON.stringify(data.data));
  
          // Update the Authorization headers with the new token
          axiosClient.defaults.headers.common['Authorization'] = `Bearer ${data.data.token}`;
          originalRequest.headers['Authorization'] = `Bearer ${data.data.token}`;
  
          // Retry the original request with the refreshed token
          return axiosClient(originalRequest);
        } catch (refreshError: unknown) {
          // Narrow the type of refreshError to check if it has a 'response' property
          if (axios.isAxiosError(refreshError) && refreshError.response?.status === 401) {
            console.error("Token refresh failed, logging out", refreshError);
            setUser(null);
            localStorage.removeItem("token");
            localStorage.removeItem("user");
          }
          return Promise.reject(refreshError);
        }
      } else {
        // Handle any other errors without logging the user out
        console.info("API call error", error);
        return Promise.reject(error);
      }
    }
  );
  
  

  return axiosClient;
};

export default useAxios;
