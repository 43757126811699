import { Box, Flex, useColorModeValue } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import ResultTopMenu from "./ResultTopMenu";
import ResultStudentsTable from "./ResultStudentsTable";
import { useStudentsContext } from "../../../Contexts/StudentsContext";
import ResultEntryTable from "./ResultEntryTable";
import {
  ApiDataResponse,
  ApiErrorResponse,
  BehavioralResultEntry,
  BehavioralSubject,
  CognitiveResultEntry,
  Student,
  Subject,
  SummaryResultEntry,
} from "../../../services/Dtos";
import useUnsavedChangesPrompt from "../../../hooks/useUnsavedChangesPrompt";
import ResultTableInfo from "./ResultTableInfo";
import { useSchoolsContext } from "../../../Contexts/schoolContext";
import { useClassesContext } from "../../../Contexts/ClassesContext";
import useToaster from "../../../hooks/useToaster";
import axios from "axios";
import { isNullOrEmpty } from "../../../services/Utils";
import LogoLoader from "../../ui/LogoLoader";
import useAxios from "../../../hooks/useAxios";

function Results() {
  const axiosClient = useAxios();
  const [chosenStudent, setChosenStudent] = useState<Student | null>(null);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [currentLevel, setCurrentLevel] = useState("");
  const [fetchingPdf, setFetchingPdf] = useState(false);

  const [behavioralResultEntries, setBehavioralResultEntries] = useState<
    BehavioralResultEntry[]
  >([]);

  const [summaryResultEntry, setSummaryResultEntry] =
    useState<SummaryResultEntry>({} as SummaryResultEntry);

  const [editedCognitiveEntries, setEditedCognitiveEntries] = useState<
    CognitiveResultEntry[]
  >([]);
  const [editedBehavioralEntries, setEditedBehavioralEntries] = useState<
    BehavioralResultEntry[]
  >([]);

  const { school, sessions, terms, assessmentTypes, gradeCriterias } =
    useSchoolsContext();
  const { classes } = useClassesContext();
  const [fetchingStudentResult, setFetchingStudentResult] = useState(false);
  const [savingStudentResultEntries, setSavingStudentResultEntries] =
    useState(false);

  const [resultEntries, setResultEntries] = useState<CognitiveResultEntry[]>(
    []
  );

  const [editedSummaryEntry, setEditedSummaryEntry] =
    useState<SummaryResultEntry>({} as SummaryResultEntry);
  const [currentClassSubjects, setCurrentClassSubjects] = useState<Subject[]>(
    []
  );
  const [currentBehavioralSubjects, setCurrentBehavioralSubjects] = useState<
    BehavioralSubject[]
  >([]);
  const [selectedClassId, setSelectedClassId] = useState(() => {
    return localStorage.getItem("selectedClassId") || "";
  });
  const [editingSubjectIds, setEditingSubjectIds] = useState<string[]>([]);

  const { showSuccessToast, showErrorToast } = useToaster();

  const filteredAssessmentTypes = assessmentTypes.filter(
    (type) => type.levelId === currentLevel
  );

  const filteredGradeCriterias = gradeCriterias.filter(
    (criteria) => criteria.levelId === currentLevel
  );

  const isValidResult = (): boolean => {
    return (
      !isNullOrEmpty(school?.currentSessionId) &&
      !isNullOrEmpty(school?.currentTermId) &&
      filteredAssessmentTypes.length !== 0 &&
      filteredGradeCriterias.length !== 0
    );
  };

  useEffect(() => {
    const selectedClass = classes.find((c) => c.id === selectedClassId);
    if (selectedClass) {
      setCurrentClassSubjects(selectedClass.subjects);
      setCurrentBehavioralSubjects(selectedClass.behavioralSubjects);
      setCurrentLevel(selectedClass.levelId);

      // Fetch result entries for the chosen student
      const fetchResults = async () => {
        try {
          if (chosenStudent) {
            setFetchingStudentResult(true);
            const response = await axiosClient.get<
              ApiDataResponse<CognitiveResultEntry[]>
            >(
              `cognitiveresultentries/getstudentcognitiveresultentries/${chosenStudent.id}`
            );
            if (response.data.succeeded) {
              setResultEntries(response.data.data);
              // Clear edited entries when a new student is selected
              setEditingSubjectIds([]);
              setUnsavedChanges(false); // Reset unsaved changes when a new student is selected
            } else {
              showErrorToast();
            }

            const behavioralResponse = await axiosClient.get<
              ApiDataResponse<BehavioralResultEntry[]>
            >(
              `behavioralresultentries/GetStudentBehavioralResultEntries/${chosenStudent.id}`
            );
            if (behavioralResponse.data.succeeded) {
              setBehavioralResultEntries(behavioralResponse.data.data);
              setEditedBehavioralEntries([]);
            } else {
              showErrorToast();
            }

            const SummaryResponse = await axiosClient.get<
              ApiDataResponse<SummaryResultEntry>
            >(
              `summaryresultentries/GetStudentSummaryResultEntry/${chosenStudent?.id}`
            );
            if (SummaryResponse.data.succeeded) {
              console.log("summary data", SummaryResponse.data.data);

              setSummaryResultEntry(SummaryResponse.data.data);
              //setEditedSummaryEntry(SummaryResponse.data.data); // Load initial data to editedSummaryEntry
            } else {
              showErrorToast();
            }
          } else {
            setResultEntries([]);
            setBehavioralResultEntries([]);
            setSummaryResultEntry({} as SummaryResultEntry);
          }
        } catch (error) {
          showErrorToast();
          console.error("Error fetching student result entry:", error);
        } finally {
          setFetchingStudentResult(false);
        }
      };

      fetchResults();
    } else {
      setCurrentClassSubjects([]); // Clear current subjects when no class is selected
      setCurrentBehavioralSubjects([]);
    }
  }, [chosenStudent, selectedClassId, classes]);

  const currentSession = sessions.find(
    (s) => s.id === school?.currentSessionId
  );

  const currentTerm = terms.find((t) => t.id === school?.currentTermId);

  useUnsavedChangesPrompt(unsavedChanges);

  const handleRowClick = (student: Student | null) => {
    if (unsavedChanges) {
      const confirmLeave = window.confirm(
        "You have unsaved changes. Are you sure you want to leave?"
      );
      if (!confirmLeave) {
        return;
      }
    }
    if (chosenStudent === student) {
      setChosenStudent(null);
    } else {
      setChosenStudent(student);
    }
    setUnsavedChanges(false);
    console.log("Student changed to: ", student);
  };

  const scrollColor = useColorModeValue("gray.200", "#3B3B3B");
  const altColor = useColorModeValue("gray.50", "gray.800");
  const { students } = useStudentsContext();

  const [searchQuery, setSearchQuery] = useState<string>("");

  const handleClassChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    if (unsavedChanges) {
      const confirmLeave = window.confirm(
        "You have unsaved changes. Are you sure you want to leave?"
      );
      if (!confirmLeave) {
        return;
      }
    }
    setSelectedClassId(e.target.value);
    setChosenStudent(null);
    console.log("Class changed to: ", e.target.value);
  };

  const handleSearchChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    setSearchQuery(e.target.value);
    console.log("Search query: ", e.target.value);
  };

  const filteredStudents = students?.filter((student) => {
    const fullName = `${student.firstName.toLowerCase()} ${student.middleName.toLowerCase()} ${student.lastName.toLowerCase()}`;
    return (
      fullName.includes(searchQuery.toLowerCase()) &&
      student.currentClassId === selectedClassId
    );
  });

  useEffect(() => {
    const saveStates = () => {
      localStorage.setItem("selectedClassId", selectedClassId);
    };

    saveStates();
  }, [selectedClassId]);

  const handleSave = async () => {
    if (!chosenStudent || !selectedClassId) return;

    try {
      setSavingStudentResultEntries(true);

      // Prepare cognitive and behavioral promises
      const cognitivePromise = editedCognitiveEntries.length
        ? axiosClient.post<ApiDataResponse<CognitiveResultEntry[]>>(
            `cognitiveresultentries/addorupdatecognitiveresultentries`,
            editedCognitiveEntries
          )
        : null;

      const behavioralPromise = editedBehavioralEntries.length
        ? axiosClient.post<ApiDataResponse<BehavioralResultEntry[]>>(
            `behavioralresultentries/addorupdatebehavioralresultentries`,
            editedBehavioralEntries
          )
        : null;

      // Wait for cognitive and behavioral results to complete
      const cognitiveResponse = cognitivePromise
        ? await cognitivePromise
        : null;
      const behavioralResponse = behavioralPromise
        ? await behavioralPromise
        : null;

      // Handle cognitive response
      if (cognitiveResponse?.data.succeeded) {
        setResultEntries(cognitiveResponse.data.data);
        setEditedCognitiveEntries([]);
        showSuccessToast(cognitiveResponse.data.message);
      } else if (cognitivePromise && !cognitiveResponse?.data.succeeded) {
        showErrorToast(cognitiveResponse?.data.message || "");
        throw new Error("Failed to save cognitive result entries");
      } else if (cognitivePromise) {
        throw new Error("Failed to save cognitive result entries");
      }

      // Handle behavioral response
      if (behavioralResponse?.data.succeeded) {
        setBehavioralResultEntries(behavioralResponse.data.data);
        setEditedBehavioralEntries([]);
        showSuccessToast(behavioralResponse.data.message);
      } else if (behavioralPromise && !behavioralResponse?.data.succeeded) {
        showErrorToast(behavioralResponse?.data.message || "");
        throw new Error("Failed to save behavioral result entries");
      } else if (behavioralPromise) {
        throw new Error("Failed to save behavioral result entries");
      }

      // Only after cognitive and behavioral entries are saved, make the summary API call
      const summaryResponse = await axiosClient.post<
        ApiDataResponse<SummaryResultEntry>
      >(
        `summaryresultentries/addorupdatesummaryresultentry`,
        editedSummaryEntry
      );

      // Handle summary response
      if (summaryResponse.data.succeeded) {
        setSummaryResultEntry(summaryResponse.data.data);
        showSuccessToast(summaryResponse.data.message);
      } else {
        throw new Error("Failed to save summary result entries");
      }

      setUnsavedChanges(false);
      setEditingSubjectIds([]);
      console.log("Saved result entries for student:", chosenStudent);
    } catch (error) {
      showErrorToast();
      console.error("Error saving result entries:", error);
    } finally {
      setSavingStudentResultEntries(false);
    }
  };

  const handlePreview = async () => {
    try {
      setFetchingPdf(true);
      const response = await axiosClient.get<ApiDataResponse<string>>(
        `resultreports/getcurrentreportpdf/${chosenStudent?.id}`
      );

      if (response.data.succeeded) {
        // Base64 string
        const base64String = response.data.data;

        // Decode base64 to binary string
        const binaryString = atob(base64String);

        // Convert binary string to Uint8Array
        const len = binaryString.length;
        const bytes = new Uint8Array(len);
        for (let i = 0; i < len; i++) {
          bytes[i] = binaryString.charCodeAt(i);
        }

        // Create Blob from Uint8Array
        const blob = new Blob([bytes], { type: "application/pdf" });

        // Create Blob URL and open it
        const blobUrl = URL.createObjectURL(blob);
        window.open(blobUrl, "_blank");
      } else {
        showErrorToast(response.data.message);
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const response = error.response?.data as ApiErrorResponse;
        showErrorToast(response.Message || "");
      } else {
        console.error(error);
        showErrorToast();
      }
    } finally {
      setFetchingPdf(false);
    }
  };

  if (fetchingPdf) {
    return <LogoLoader />;
  }

  return (
    <Flex flex={1} flexDirection={"column"} overflow="hidden" h={"100%"} p={4}>
      <ResultTopMenu
        validResult={isValidResult()}
        handlePreview={handlePreview}
        searchQuery={searchQuery}
        handleSearchChange={handleSearchChange}
        handleClassChange={handleClassChange}
        selectedClassId={selectedClassId}
        unsavedChanges={unsavedChanges}
        handleSave={handleSave}
        savingStudentResultEntries={savingStudentResultEntries}
        selectedStudentName={
          chosenStudent
            ? `${chosenStudent?.firstName} ${chosenStudent?.lastName}`
            : undefined
        }
      />
      <Flex
        flex={1}
        w={"full"}
        h={"100%"}
        pt={3}
        flexDir={{ base: "column", lg: "row" }}
        gap={3}
        overflow="hidden"
      >
        <Box
          pr={3}
          flex={1}
          overflow="auto"
          sx={{
            "&::-webkit-scrollbar": {
              display: "block",
              width: "8px",
            },
            "&:hover": {
              "&::-webkit-scrollbar": {
                // display: "block",
                // width: "8px",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: scrollColor,
                borderRadius: "4px",
              },
            },
          }}
        >
          <ResultStudentsTable
            selectedClassId={selectedClassId}
            students={filteredStudents}
            chosenStudent={chosenStudent}
            handleRowClick={handleRowClick}
          />
        </Box>
        <Box
          flex={4}
          bg={altColor}
          p={5}
          borderWidth={1}
          borderRadius="lg"
          width="100%"
          h={"100%"}
          overflow={"auto"}
          ml={2}
          sx={{
            "&::-webkit-scrollbar": {
              display: "block",
              width: "8px",
            },
            "&:hover": {
              "&::-webkit-scrollbar": {},
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: scrollColor,
                borderRadius: "4px",
              },
            },
          }}
        >
          <ResultTableInfo
            currentSessionName={currentSession?.sessionName}
            currentTermName={currentTerm?.termName}
            selectedClassName={
              classes.find((c) => c.id === selectedClassId)?.className
            }
            selectedStudentName={
              chosenStudent
                ? `${chosenStudent?.firstName} ${chosenStudent?.lastName}`
                : undefined
            }
          />

          <ResultEntryTable
            currentLevel={currentLevel}
            setUnsavedChanges={setUnsavedChanges}
            chosenStudent={chosenStudent}
            selectedClassId={selectedClassId}
            currentClassSubjects={currentClassSubjects}
            editingSubjectIds={editingSubjectIds}
            setEditingSubjectIds={setEditingSubjectIds}
            fetchingStudentResult={fetchingStudentResult}
            cognitiveResultEntries={resultEntries}
            behavioralResultEntries={behavioralResultEntries}
            editedCognitiveEntries={editedCognitiveEntries}
            editedBehavioralEntries={editedBehavioralEntries}
            setEditedCognitiveEntries={setEditedCognitiveEntries}
            setEditedBehavioralEntries={setEditedBehavioralEntries}
            currentBehavioralSubjects={currentBehavioralSubjects}
            summaryResultEntry={summaryResultEntry}
            editedSummaryEntry={editedSummaryEntry}
            setEditedSummaryEntry={setEditedSummaryEntry}
            setSummaryResultEntry={setSummaryResultEntry}
            filteredAssessmentTypes={filteredAssessmentTypes}
            filteredGradeCriterias={filteredGradeCriterias}
          />
        </Box>
      </Flex>
    </Flex>
  );
}

export default Results;
