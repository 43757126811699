import React, {
  createContext,
  useState,
  useEffect,
  ReactNode,
  useContext,
} from "react";
import {
  AddStudentDto,
  ApiDataResponse,
  ApiResponse,
  Student,
} from "../services/Dtos";
import { useUserContext } from "./userContext";
import useAxios from "../hooks/useAxios";

interface StudentsContextType {
  students: Student[];
  setStudents: React.Dispatch<React.SetStateAction<Student[]>>;
  error: string | null;
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  selectedStudent: Student | null;
  setSelectedStudent: React.Dispatch<React.SetStateAction<Student | null>>;
  addStudent: (newStudent: AddStudentDto) => Promise<Student | null>;
  editStudent: (
    studentToEdit: AddStudentDto,
    studentId: string
  ) => Promise<Student | null>;
  deleteStudent: (studentId: string) => Promise<ApiResponse | null>;
}

interface Props {
  children: ReactNode;
}

const StudentsContext = createContext<StudentsContextType | undefined>(
  undefined
);

export const StudentsProvider = ({ children }: Props) => {
  const axiosClient = useAxios();
  const [students, setStudents] = useState<Student[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const { user, isLoading: userLoading } = useUserContext();
  const [selectedStudent, setSelectedStudent] = useState<Student | null>(null);

  useEffect(() => {
    if (user && !userLoading) {
      const getStudents = async () => {
        setIsLoading(true);
        try {
          const response = await axiosClient<ApiDataResponse<Student[]>>(
            "students"
          );

          if (response.data?.succeeded) {
            const students = response.data.data;
            setStudents(students);
          } else {
            setError(`Error fetching students: ${response.data?.message}`);
          }
        } catch (error) {
          console.error("Failed to fetch students:", error);
          setError("Failed to fetch students");
        } finally {
          setIsLoading(false);
        }
      };
      getStudents();
    }
  }, [user, userLoading]);

  const addStudent = async (
    newStudent: AddStudentDto
  ): Promise<Student | null> => {
    try {
      const response = await axiosClient.post<ApiDataResponse<Student>>(
        "students",
        newStudent
      );

      if (response.data?.succeeded) {
        const savedStudent = response.data.data;
        setStudents((prevStudents) => [...prevStudents, savedStudent]);
        return savedStudent;
      } else {
        return null;
      }
    } catch (error) {
      console.error("Failed to add student:", error);
      return null;
    } finally {
    }
  };

  //Edit or Update Student
  const editStudent = async (
    studentToEdit: AddStudentDto,
    studentId: string
  ): Promise<Student | null> => {
    let updatedStudent: Student | null = null;

    try {
      const response = await axiosClient.put<ApiResponse>(
        `students/${studentId}`,
        studentToEdit
      );

      if (response.data?.succeeded) {
        setStudents((prevStudents) => {
          return prevStudents.map((s) => {
            if (s.id === studentId) {
              updatedStudent = {
                ...s,
                ...studentToEdit,
                gender: studentToEdit.gender as number,
                status: studentToEdit.status,
              };
              return updatedStudent;
            }
            return s;
          });
        });

        // Optionally, you can update any other state here using the updatedStudent variable
        if (updatedStudent) {
          console.log("Updated Student:", updatedStudent);
          // For example, if you want to update the selected student state:
          setSelectedStudent(updatedStudent);
        }

        return updatedStudent;
      } else {
        return updatedStudent;
      }
    } catch (error) {
      console.error("Failed to update student:", error);
      return null;
    } finally {
    }
  };

  //DeleteStudent
  const deleteStudent = async (
    studentId: string
  ): Promise<ApiResponse | null> => {
    try {
      const response = await axiosClient.delete<ApiResponse>(
        `students/${studentId}`
      );

      if (response.data?.succeeded) {
        setStudents((prevStudents) =>
          prevStudents.filter((s) => s.id !== studentId)
        );

        // Optionally, you can update any other state here
        // For example, if the deleted student was the selected student, clear the selection
        if (selectedStudent?.id === studentId) {
          setSelectedStudent(null);
        }

        return response.data;
      } else {
        return response.data;
      }
    } catch (error) {
      console.error("Failed to delete student:", error);
      return null;
    } finally {
    }
  };

  return (
    <StudentsContext.Provider
      value={{
        students,
        setStudents,
        error,
        isLoading,
        setIsLoading,
        addStudent,
        editStudent,
        selectedStudent,
        setSelectedStudent,
        deleteStudent,
      }}
    >
      {children}
    </StudentsContext.Provider>
  );
};

export const useStudentsContext = () => {
  const context = useContext(StudentsContext);
  if (!context) {
    throw new Error("useStudentsContext must be used within a StudentProvider");
  }
  return context;
};
