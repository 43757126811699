import React, { useState, ChangeEvent, useEffect } from "react";
import { ApiResponse, Session } from "../../../../services/Dtos";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  Flex,
  useDisclosure,
  CircularProgress,
  VStack,
} from "@chakra-ui/react";
import { BiTrash } from "react-icons/bi";
import {
  validateGreaterThanZero,
  validateRequired,
} from "../../../../services/validation";
import DeleteAlertDialogModal from "../../../ui/DeleteAlertDialogModal";
import useToaster from "../../../../hooks/useToaster";
import { getTodayDate } from "../../../../services/Utils";
import ErrorAlert from "../../../ui/ErrorAlert";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  sessionToEdit: Session | null;
  addSession: (session: Session) => Promise<ApiResponse | null>;
  deleteSession: (sessionId: string) => Promise<ApiResponse | null>;
  editSession: (
    session: Session,
    sessionId: string
  ) => Promise<ApiResponse | null>;
  nextOrder: number;
  errors: string[];
  setErrors: React.Dispatch<React.SetStateAction<string[]>>;
}

const SessionModal: React.FC<Props> = ({
  isOpen,
  onClose,
  sessionToEdit,
  addSession,
  deleteSession,
  editSession,
  nextOrder,
  errors,
  setErrors,
}) => {
  const initialSessionFormState: Session = {
    id: "",
    sessionName: "",
    sessionStartDate: getTodayDate(),
    sessionEndDate: getTodayDate(),
    schoolId: "",
    order: nextOrder,
  };
  const { showSuccessToast, showErrorToast } = useToaster();

  const [isLoading, setIsLoading] = useState(false);

  const {
    isOpen: deleteIsOpen,
    onOpen,
    onClose: deleteOnClose,
  } = useDisclosure();

  const [sessionFormState, setSessionFormState] = useState<Session>(
    initialSessionFormState
  );

  useEffect(() => {
    if (sessionToEdit) {
      setSessionFormState(sessionToEdit);
    } else {
      setSessionFormState(initialSessionFormState);
    }
  }, [sessionToEdit]);

  const [sessionFormErrors, setSessionFormErrors] = useState<{
    [key: string]: string;
  }>({});

  const validateSessionForm = (): boolean => {
    const currentSessionFormErrors: { [key: string]: string } = {};

    if (!validateRequired(sessionFormState.sessionName)) {
      currentSessionFormErrors.sessionName = "Session Name is required";
    }

    if (!validateGreaterThanZero(sessionFormState.order)) {
      currentSessionFormErrors.order = "Order is required";
    }

    setSessionFormErrors(currentSessionFormErrors);
    return Object.keys(currentSessionFormErrors).length === 0;
  };

  const handleAddSession = async () => {
    if (!validateSessionForm()) {
      console.log(sessionFormErrors);
      return;
    }

    setIsLoading(true);

    try {
      if (sessionToEdit) {
        const response = await editSession(sessionFormState, sessionToEdit.id);
        if (response?.succeeded) {
          showSuccessToast(response.message || "");
          onClose();
        } else {
          showErrorToast(response?.message || "");
          console.info("Error updating session");
        }
      } else {
        const response = await addSession(sessionFormState);
        if (response?.succeeded) {
          showSuccessToast(response.message || "");
          setSessionFormState(initialSessionFormState);
          onClose();
        } else {
          showErrorToast(response?.message || "");
          console.info("Error adding session");
        }
      }
    } catch (error) {
      showErrorToast();
      console.error("Unexpected error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteSession = async () => {
    if (sessionToEdit) {
      setIsLoading(true);
      const response = await deleteSession(sessionToEdit?.id);
      if (response?.succeeded) {
        onClose();
        showSuccessToast(response.message || "");
      } else {
        setErrors(response?.errors || []);
        console.log(errors);
        showErrorToast(response?.message || "");
      }
    }
    setIsLoading(false);
  };

  const handleSessionChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setSessionFormState((prevState) => ({
      ...prevState,
      [name]: name === "order" ? parseInt(value, 10) : value,
    }));
    console.log(sessionFormState);
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick={false}
      motionPreset="slideInBottom"
      scrollBehavior={"inside"}
      size={"xl"}
    >
      <DeleteAlertDialogModal
        handleDelete={handleDeleteSession}
        onClose={deleteOnClose}
        isOpen={deleteIsOpen}
      />
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {sessionToEdit ? "Edit Session" : "Add New Session"}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {errors.slice(0, 3).map((error, index) => (
            <ErrorAlert key={index} message={error} />
          ))}
          <VStack gap={0} alignItems={"center"}>
            <FormControl mb={4} isRequired>
              <FormLabel>Session</FormLabel>
              <Input
                name="sessionName"
                value={sessionFormState.sessionName}
                placeholder="e.g (2020/2021)"
                onChange={handleSessionChange}
                isInvalid={!!sessionFormErrors.sessionName}
              />
            </FormControl>

            <FormControl mb={4}>
              <FormLabel>Session Start Date</FormLabel>
              <Input
                type="date"
                name="sessionStartDate"
                value={sessionFormState.sessionStartDate}
                onChange={handleSessionChange}
                isInvalid={!!sessionFormErrors.sessionStartDate}
              />
            </FormControl>

            <FormControl mb={4}>
              <FormLabel>Session End Date</FormLabel>
              <Input
                type="date"
                name="sessionEndDate"
                value={sessionFormState.sessionEndDate}
                onChange={handleSessionChange}
                isInvalid={!!sessionFormErrors.sessionEndDate}
              />
            </FormControl>

            <FormControl mb={4} isRequired>
              <FormLabel>Order</FormLabel>
              <Input
                type="number"
                name="order"
                value={sessionFormState.order}
                onChange={handleSessionChange}
                isInvalid={!!sessionFormErrors.order}
              />
            </FormControl>
          </VStack>
        </ModalBody>
        <ModalFooter alignSelf={"center"}>
          {isLoading ? (
            <CircularProgress isIndeterminate color={"brand.300"} />
          ) : (
            <Flex gap={5} justifyContent={"center"}>
              <Button
                colorScheme="brand"
                onClick={handleAddSession}
                isLoading={isLoading}
              >
                Done
              </Button>

              {sessionToEdit && (
                <Button
                  isLoading={isLoading}
                  variant={"ghost"}
                  colorScheme="red"
                  leftIcon={<BiTrash />}
                  onClick={onOpen}
                >
                  Delete Session
                </Button>
              )}
            </Flex>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default SessionModal;
