import React, { useEffect, useState } from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Box,
  Center,
  Avatar,
  HStack,
  Text,
  Flex,
  useColorModeValue,
  VStack,
  Alert,
  AlertIcon,
  Button,
  Badge,
  Heading,
  AvatarGroup,
  IconButton,
} from "@chakra-ui/react";
import {
  Student,
  AddStudentDto,
  Guardian,
  ApiResponse,
  ApiDataResponse,
} from "../../../services/Dtos";
import StudentTopMenu from "./StudentTopMenu";
import AddStudentModal from "./AddStudentModal";
import { useStudentsContext } from "../../../Contexts/StudentsContext";
import { useClassesContext } from "../../../Contexts/ClassesContext";
import {
  brandTransparentColor,
  greenTransparentColor,
  genderMap,
  studentStatusMap,
  tableRowHoverColor,
  tableSelectedRowColor,
  editorPolicy,
} from "../../../services/constants";
import {
  calculateAge,
  convertStudentToDto,
  getStudentClassNumberMessage,
  mapClass,
} from "../../../services/Utils";
import { BiEdit, BiHeart, BiPlus } from "react-icons/bi";
import AddGuardianModal from "./AddGuardianModal";
import useToaster from "../../../hooks/useToaster";
import Empty from "../../ui/Empty";
import Loader from "../../ui/Loader";
import useAxios from "../../../hooks/useAxios";
import { useUserContext } from "../../../Contexts/userContext";

const Students: React.FC = () => {
  const axiosClient = useAxios();
  const [searchQuery, setSearchQuery] = useState("");
  const [sortDate, setSortDate] = useState(true);
  const altColor = useColorModeValue("gray.100", "gray.700");
  const scrollColor = useColorModeValue("gray.200", "#3B3B3B");
  const tHeadColor = useColorModeValue("gray.50", "gray.900");
  const [selectedClass, setSelectedClass] = useState<string>("");
  const [studentToEdit, setStudentToEdit] = useState<AddStudentDto | null>(
    null
  );
  const { user } = useUserContext();
  const [guardianToEdit, setGuardianToEdit] = useState<Guardian | null>(null);
  const [openGuradianModal, setOpenGuardianModal] = useState(false);
  const [openStudentModal, setOpenStudentModal] = useState(false);
  const {
    students,
    isLoading: studentsLoading,
    error: studentsError,
    selectedStudent,
    setSelectedStudent,
    setStudents,
  } = useStudentsContext();

  const { showSuccessToast, showErrorToast } = useToaster();

  const {
    classes,
    isLoading: classesLoading,
    error: classesError,
  } = useClassesContext();

  const [classMap, setClassMap] = useState<{ [key: string]: string }>({});

  useEffect(() => {
    if (classes) {
      const map = mapClass(classes);
      setClassMap(map);
    }
  }, [classes]);

  if (studentsLoading || classesLoading) {
    return <Loader />;
  }

  if (studentsError || classesError) {
    return (
      <Center height="100%">
        <Alert status="error" w={"50%"}>
          <AlertIcon />
          {studentsError || classesError}
        </Alert>
        <Button>Retry</Button>
      </Center>
    );
  }

  const justFilteredStudents = students?.filter((student) => {
    const fullName = `${student.firstName.toLowerCase()} ${student.middleName.toLowerCase()} ${student.lastName.toLowerCase()}`;
    return (
      fullName.includes(searchQuery.toLowerCase()) &&
      (selectedClass === "" || student.currentClassId === selectedClass)
    );
  });

  const filteredStudents = sortDate
    ? justFilteredStudents.reverse()
    : justFilteredStudents;

  const handleSortDate = () => {
    setSortDate((prev) => !prev);
  };

  const handleRowClick = (student: Student) => {
    if (selectedStudent === student) {
      setSelectedStudent(null);
    } else {
      setSelectedStudent(student);
    }
  };

  const handleAddOpenGuardian = () => {
    setGuardianToEdit(null);
    console.log(selectedStudent);
    setOpenGuardianModal(true);
  };

  const handleEditGuardian = (guardianInBox: Guardian) => {
    setGuardianToEdit(guardianInBox);
    setOpenGuardianModal(true);
  };

  const handleAddStudent = () => {
    setStudentToEdit(null);
    setOpenStudentModal(true);
    console.log(studentToEdit);
  };

  const handleEditStudent = (studentToEdit: AddStudentDto) => {
    setStudentToEdit(studentToEdit);
    setOpenStudentModal(true);
  };

  const handleStudentSubmit = async (
    newStudent: AddStudentDto,
    studentId: string
  ): Promise<ApiResponse | null> => {
    console.log(newStudent);

    let updatedStudent: Student | null = null;
    if (studentToEdit) {
      try {
        const response = await axiosClient.put<ApiResponse>(
          `students/${studentId}`,
          newStudent
        );

        if (response.data?.succeeded) {
          setStudents((prevStudents) => {
            return prevStudents.map((s) => {
              if (s.id === studentId) {
                updatedStudent = {
                  ...s,
                  ...newStudent,
                  gender: newStudent.gender as number,
                  status: newStudent.status,
                };
                return updatedStudent;
              }
              return s;
            });
          });

          showSuccessToast(response.data.message || "");

          if (updatedStudent) {
            console.log("Updated Student:", updatedStudent);
            setSelectedStudent(updatedStudent);
          }
          return response.data;
        } else {
          showErrorToast(response.data.message || "");
          setStudentToEdit(null);
          return null;
        }
      } catch (error) {
        console.error("Failed to update student:", error);
        return null;
      } finally {
      }
    } else {
      try {
        const response = await axiosClient.post<ApiDataResponse<Student>>(
          "students",
          newStudent
        );

        if (response.data?.succeeded) {
          setStudents((prevStudents) => [...prevStudents, response.data.data]);

          showSuccessToast(response.data.message || "");

          return response.data;
        } else {
          showErrorToast(response.data.message || "");
          setStudentToEdit(null);
          return null;
        }
      } catch (error) {
        console.error("Failed to update student:", error);
        return null;
      } finally {
      }
    }
  };

  const handleSearchChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    setSearchQuery(e.target.value);
    console.log(searchQuery);
  };

  const handleClassChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    setSelectedClass(e.target.value);
  };

  function firstFiveClassStudents(student: Student): Student[] {
    return students
      .filter((s) => s.currentClassId === student.currentClassId)
      .slice(0, 5);
  }

  function classCount(student: Student) {
    return students.filter((s) => s.currentClassId === student.currentClassId)
      .length;
  }

  function handleSameClass(studentClass: string) {
    selectedClass === studentClass
      ? setSelectedClass("")
      : setSelectedClass(studentClass);
  }

  return (
    <Flex flex={1} flexDirection={"column"} overflow="hidden" h={"100%"} p={4}>
      <StudentTopMenu
        searchQuery={searchQuery}
        selectedClass={selectedClass}
        handleClassChange={handleClassChange}
        handleSearchChange={handleSearchChange}
        classes={classes}
        onOpen={handleAddStudent}
        sortDate={sortDate}
        handleSortDate={handleSortDate}
      />
      <AddStudentModal
        isOpen={openStudentModal}
        onClose={() => setOpenStudentModal(false)}
        onAddStudent={handleStudentSubmit}
        openGuardianForm={handleAddOpenGuardian}
        studentToEdit={studentToEdit}
        selectedStudentId={selectedStudent?.id || ""}
      />

      <AddGuardianModal
        isOpen={openGuradianModal}
        onClose={() => setOpenGuardianModal(false)}
        guardianToEdit={guardianToEdit}
      />

      {students.length != 0 ? (
        <Flex
          w={"full"}
          flex={1}
          pt={3}
          overflow="hidden"
          h={"100%"}
          direction={{ base: "column", lg: "row" }}
        >
          <Box
            flex={2.5}
            p={5}
            overflow={"auto"}
            h={"100%"}
            sx={{
              "&::-webkit-scrollbar": {
                display: "block",
                width: "8px",
              },
              "&:hover": {
                "&::-webkit-scrollbar": {
                  // display: "block",
                  // width: "8px",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: scrollColor,
                  borderRadius: "4px",
                },
              },
            }}
          >
            <Table variant="simple" size={"sm"} borderRadius={5}>
              <Thead position={"sticky"} top={"-5"} bg={tHeadColor} zIndex={2}>
                <Tr>
                  <Th>Name</Th>
                  <Th>Public ID</Th>
                  <Th>Status</Th>
                  <Th>Class</Th>
                  <Th>Gender</Th>
                </Tr>
              </Thead>
              <Tbody>
                {filteredStudents &&
                  filteredStudents.map((student) => (
                    <Tr
                      key={student.id}
                      _hover={{
                        backgroundColor:
                          selectedStudent?.id === student.id
                            ? tableSelectedRowColor
                            : tableRowHoverColor,
                      }}
                      backgroundColor={
                        selectedStudent?.id === student.id
                          ? tableSelectedRowColor
                          : "transparent"
                      }
                      cursor="pointer"
                      onClick={() => handleRowClick(student)}
                    >
                      <Td>
                        <HStack gap={5}>
                          <Avatar
                            colorScheme="red"
                            src={student.avatarUrl}
                            size={"sm"}
                          />
                          <Text>{`${student.firstName} ${student.middleName} ${student.lastName}`}</Text>
                        </HStack>
                      </Td>
                      <Td>{student.publicId}</Td>
                      <Td>
                        <Badge
                          colorScheme={studentStatusMap[student.status].color}
                        >
                          {studentStatusMap[student.status].name}
                        </Badge>
                      </Td>
                      <Td>{classMap[student.currentClassId] || "Unknown"}</Td>
                      <Td>{genderMap[student.gender]}</Td>
                    </Tr>
                  ))}
              </Tbody>
            </Table>
          </Box>
          <Box
            flex={1}
            bg={altColor}
            p={5}
            borderWidth={1}
            borderRadius="lg"
            width="100%"
            h={"100%"}
            overflowY={"auto"}
            ml={2}
            sx={{
              "&::-webkit-scrollbar": {
                display: "block",
                width: "8px",
              },
              "&:hover": {
                "&::-webkit-scrollbar": {
                  // display: "block",
                  // width: "8px",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: scrollColor,
                  borderRadius: "4px",
                },
              },
            }}
          >
            {selectedStudent ? (
              <Flex flexDirection={"column"} alignItems={"center"} h={"100%"}>
                <Avatar
                  name={`${selectedStudent.firstName} ${selectedStudent.lastName}`}
                  src={selectedStudent.avatarUrl}
                  borderColor={"tomato"}
                  size={"xl"}
                ></Avatar>
                <Center
                  position={"sticky"}
                  top={"-20px"}
                  zIndex={2}
                  bg={altColor}
                  p={3}
                  w={"full"}
                >
                  <VStack gap={0}>
                    <Heading textAlign={"center"} size={"lg"}>
                      {selectedStudent?.firstName +
                        " " +
                        selectedStudent?.middleName +
                        " " +
                        selectedStudent?.lastName}
                    </Heading>
                    {/* <Button variant={"link"} size={"sm"} mt={2}>
                    View Result
                  </Button> */}
                  </VStack>
                </Center>
                <Box
                  bg={brandTransparentColor}
                  borderRadius={"md"}
                  p={3}
                  w={"100%"}
                  mt={5}
                  position={"relative"}
                >
                  {editorPolicy.includes(user?.role || 0) && (
                    <IconButton
                      position={"absolute"}
                      right={"7px"}
                      top={"7px"}
                      size={"sm"}
                      bg={brandTransparentColor}
                      variant={"outline"}
                      icon={<BiEdit />}
                      aria-label={"edit Student"}
                      onClick={() =>
                        handleEditStudent(convertStudentToDto(selectedStudent))
                      }
                    />
                  )}
                  <Table variant={"simple"} size={"sm"}>
                    <Tbody>
                      <Tr>
                        <Td>Public Id</Td>
                        <Td fontWeight={"bold"}>{selectedStudent?.publicId}</Td>
                      </Tr>
                      <Tr>
                        <Td>Class</Td>
                        <Td fontWeight={"bold"}>
                          {classMap[selectedStudent?.currentClassId]}
                        </Td>
                      </Tr>
                      <Tr>
                        <Td>Gender</Td>
                        <Td fontWeight={"bold"}>
                          {genderMap[selectedStudent?.gender]}
                        </Td>
                      </Tr>
                      <Tr>
                        <Td>Age</Td>
                        <Td fontWeight={"bold"}>
                          {calculateAge(selectedStudent?.dateOfBirth)}
                        </Td>
                      </Tr>
                      <Tr>
                        <Td>Status</Td>
                        <Td fontWeight={"bold"}>
                          <Badge
                            colorScheme={
                              studentStatusMap[selectedStudent.status].color
                            }
                          >
                            {studentStatusMap[selectedStudent.status].name}
                          </Badge>
                        </Td>
                      </Tr>
                    </Tbody>
                  </Table>
                  <Flex gap={3} alignItems={"center"} pt={6}>
                    <AvatarGroup max={5} spacing={-6}>
                      {firstFiveClassStudents(selectedStudent).map(
                        (student) => (
                          <Avatar
                            key={student.id}
                            name={`${student.firstName} ${student.lastName}`}
                            src={student.avatarUrl}
                            borderColor={"tomato"}
                            size={"md"}
                            sx={{
                              border: "none",
                              boxShadow: "none",
                            }}
                          />
                        )
                      )}
                    </AvatarGroup>
                    <Button
                      // _hover={{ textDecoration: "underline", cursor: "pointer" }}
                      variant={"link"}
                      fontSize={"xs"}
                      maxW={"200px"}
                      whiteSpace="normal"
                      textAlign={"left"}
                      onClick={() =>
                        handleSameClass(selectedStudent.currentClassId)
                      }
                    >
                      {getStudentClassNumberMessage(
                        classCount(selectedStudent) - 1
                      )}
                    </Button>
                  </Flex>
                </Box>

                <Flex
                  mt={10}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                  w={"100%"}
                >
                  <Heading size={"md"} textAlign="left">
                    Guardians
                  </Heading>
                  {editorPolicy.includes(user?.role || 0) && (
                    <IconButton
                      size={"sm"}
                      bg={greenTransparentColor}
                      variant={"outline"}
                      icon={<BiPlus />}
                      aria-label={"add guardian"}
                      onClick={handleAddOpenGuardian}
                    />
                  )}
                </Flex>

                {selectedStudent.guardians.length === 0 ? (
                  <Box
                    bg={brandTransparentColor}
                    borderRadius={"md"}
                    p={3}
                    w={"100%"}
                    mt={5}
                    flex={1}
                  >
                    <Center p={5} gap={5} flexDirection={"column"} h={"100%"}>
                      <Text textAlign={"center"}>
                        No Guardian Added for Selected Student
                      </Text>
                      {editorPolicy.includes(user?.role || 0) && (
                        <Button
                          onClick={handleAddOpenGuardian}
                          leftIcon={<BiHeart />}
                        >
                          Add Guardian
                        </Button>
                      )}
                    </Center>
                  </Box>
                ) : (
                  selectedStudent.guardians.map((guardian, index) => (
                    <Box
                      key={index}
                      bg={brandTransparentColor}
                      borderRadius={"md"}
                      p={3}
                      w={"100%"}
                      mt={5}
                      position={"relative"}
                    >
                      <IconButton
                        position={"absolute"}
                        right={"7px"}
                        top={"7px"}
                        size={"sm"}
                        bg={brandTransparentColor}
                        variant={"outline"}
                        icon={<BiEdit />}
                        aria-label={"add guardian"}
                        onClick={() => handleEditGuardian(guardian)}
                      />
                      <Table variant={"simple"} size={"sm"}>
                        <Tbody>
                          <Tr>
                            <Td>Name</Td>
                            <Td fontWeight={"bold"}>
                              {`${guardian.guardianTitle} 
                        ${guardian.guardianFullName}`}
                            </Td>
                          </Tr>
                          <Tr>
                            <Td>Phone</Td>
                            <Td fontWeight={"bold"}>
                              {guardian.guardianPhoneNumber}
                            </Td>
                          </Tr>
                          <Tr>
                            <Td>Email</Td>
                            <Td fontWeight={"bold"}>
                              {guardian.guardianEmail}
                            </Td>
                          </Tr>
                          <Tr>
                            <Td>Address</Td>
                            <Td fontWeight={"bold"}>
                              {guardian.guardianAddress}
                            </Td>
                          </Tr>
                        </Tbody>
                      </Table>
                    </Box>
                  ))
                )}
              </Flex>
            ) : (
              <Empty
                hidePicture
                message="Select a Student to see Details Here"
              />
            )}
          </Box>
        </Flex>
      ) : (
        <Empty message="Start by adding students" />
      )}
    </Flex>
  );
};

export default Students;
