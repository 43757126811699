import { Button } from "@chakra-ui/react";
import { BiLogOut } from "react-icons/bi";

interface Props {
  handleLogout: () => void;
}

export default function MobileLogoutButton({ handleLogout }: Props) {
  return (
    <Button aria-label="log out" leftIcon={<BiLogOut />} onClick={handleLogout}>
      Log out
    </Button>
  );
}
