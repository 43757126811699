import {
  Box,
  Flex,
  Button,
  Image,
  Text,
  Heading,
} from "@chakra-ui/react";
import { useSchoolsContext } from "../../../Contexts/schoolContext";
import Logo from "../../../assets/logo-here-heart.png";
import { RiExternalLinkLine } from "react-icons/ri";

export default function SchoolInfo() {
  const { school } = useSchoolsContext();

  return (
    <Box
    minH={"220px"}
      flex={1}
      //backgroundImage={school?.coverPhoto || coverBG}
      //bgPos={"center"}
      //backgroundSize="cover"
      w={"100%"}
      display={"flex"}
      alignItems={"center"}
      //position={"relative"}
      px={5}
    >
      {/* <Box
        position="absolute"
        top={0}
        left={0}
        right={0}
        bottom={0}
        bg={school?.accentColor || "brand.300"}
        opacity={0.8}
        mixBlendMode={blendMode}
        zIndex={0}
      />

      <Box
        position="absolute"
        top={0}
        left={0}
        right={0}
        bottom={0}
        bg={overlay}
        zIndex={0}
      /> */}

      <Flex
        gap={5}
        w={"full"}
        p={5}
        borderRadius={"lg"}
        alignItems={"center"}
        zIndex={2}
        justify={"space-between"}
        flexDir={["column", "column", "row"]}
      >
        <Image
          boxSize={"130px"}
          zIndex={1}
          src={school?.avatar || Logo}
          borderRadius={"lg"}
          aspectRatio={1}
          objectFit={"cover"}
        />

        <Flex flexDir={"column"} justifyContent={"flex-end"} alignItems={["center", "center", "flex-end"]}>
          <Heading
            textAlign={["center", "center", "right"]}
            p={2}
            bg={school?.accentColor || "brand.300"}
            color={"white"}
            fontWeight={"bold"}
            borderRadius={"md"}
          >
            {school?.name}
          </Heading>
          <Text
            textAlign={["center", "center", "right"]}
            p={2}
            bg={school?.accentColor || "brand.300"}
            color={"white"}
            borderRadius={"md"}
            my={1}
          >
            {school?.footnote}
          </Text>
          {school?.website && (
            <Button
              
              // p={2}
              // bg={school?.accentColor || "brand.300"}
              // color={"white"}
              mt={2}
              zIndex={2}
              // _hover={{ bg: school?.accentColor || "brand.300", opacity: 0.8 }}
              w={"fit-content"}
              fontSize={"xs"}
              rightIcon={<RiExternalLinkLine />}
              variant={"link"}
              onClick={() =>
                window.open(school?.website, "_blank", "noopener,noreferrer")
              }
            >
              Visit School Website
            </Button>
          )}
        </Flex>
      </Flex>

      {/* <Box bg="blackAlpha.300" backdropFilter="blur(7px) ">
        
      </Box> */}
    </Box>
  );
}
